import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as storePosShiftActions from '../../../../../../actions/storePosShiftActions';
import { 
        Typography,
        Box,
        Grid,
        Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../../Helper/Loader';
// import Confirm from './Confirm';
import Information from './Information';
import Detail from './Detail';
import Order from './Order';

export default function Show() {    
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {store_pos_shift, errors} = useSelector(state => ({
        ...state.store_pos_shift
    }), shallowEqual); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(storePosShiftActions.onLoadStorePosShift(id));
        return () => {
            dispatch(storePosShiftActions.onUnloadStorePosShift())
        };
    },[]);

    function onSubmit() {
        const { status } = store_pos_shift;
        // if (status === 'on_delivery') {
        //     dispatch(storePosShiftActions.onShowConfirm("delivered"))
        // }
    }

    function error(key) {
        if (errors != null && errors[`store_pos_shift.${key}`] != null) {
            return errors[`store_pos_shift.${key}`].msg
        }
        return null;
    }

   
    if (!store_pos_shift) {
        return (
            <Loader />
        )
    }
    const { status, 
    } = store_pos_shift;
    
    function error(key) {
        if (errors != null && errors[`order.${key}`] != null) {
            return errors[`order.${key}`].msg
        }
        return null;
    }

    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Informasi Pengiriman
                </Typography>
            </HeaderBox>
            <Information error={error} />
            <Detail error={error} />
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                         <WhiteButton 
                            variant="contained"
                            size="large"
                            disableElevation
                            onClick={ () => {dispatch(storePosShiftActions.onShowStorePosShiftOrders())}}
                            >
                                Pesanan
                        </WhiteButton>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <WhiteButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    onClick={ () => {navigate(`/store-pos-shifts`)}}>
                                    Kembali
                                </WhiteButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={status === 'delivered' || status === 'completed'}
                                    disableElevation>
                                    Simpan
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
            {/* <Confirm /> */}
            <Order />
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
    padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
    padding: '15px 15px 0 15px',
    },
    height: '100%'
}));


const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));


const WhiteButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));