import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderActions from '../../../../../../actions/orderActions';
import { 
        Typography,
        Box,
        Grid,
        Paper,
        Divider,
        List,
        ListSubheader,
        ListItem,
        Tooltip,
        Button,
        Table,
        TableHead,
        TableBody,
        TableRow,
        TableCell,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../../Helper/Loader';
import { ReceiptTwoTone, MoveDownTwoTone, HelpTwoTone, LocalShippingTwoTone } from '@mui/icons-material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { keyframes } from '@emotion/react'
import NumberFormat from 'react-number-format';
import 'moment/locale/id';
import Payment from './Payment';
import Canceled from './Canceled';
import Delivery from './Delivery';
import Information from './Information';
import Product from './Product';
import Confirm from './Confirm';
export default function Show() {    
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {order, errors} = useSelector(state => ({
        ...state.order
    }), shallowEqual); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(orderActions.onLoadOrder(id));
        return () => {
            dispatch(orderActions.onUnloadOrder())
        };
    },[]);

    function onSubmit() {
        const { status } = order;
        if (status === 'completed') {
            dispatch(orderActions.onShowConfirm("update"))
        }
        // if (status === 'approved') {
        //     dispatch(onShowConfirm("received"))
        // }
        // if (status === 'received') {
        //     dispatch(onShowConfirm("transfered"))
        // }
    }
    


    function error(key) {
        if (errors != null && errors[`order.${key}`] != null) {
            return errors[`order.${key}`].msg
        }
        return null;
    }


   
    if (!order) {
        return (
            <Loader />
        )
    }

    
    
 
    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Informasi Pesanan
                </Typography>
            </HeaderBox>
            <Information error={error} />
            <Product error={error} />
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                        {/* {Cancel()} */}
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    onClick={ () => {navigate(`/orders`)}}>
                                    Kembali
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    // disabled={status === 'canceled' || status === 'paid' || status === 'on_due'}
                                    disableElevation>
                                    Simpan
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
            <Payment/>
            <Canceled />
            <Delivery />
            <Confirm />
        </ContentBox>
    )
}
const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));

const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));
