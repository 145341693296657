import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeAccessActions from '../../../../../actions/employeeAccessActions';
import { 
    Typography,
    Paper,
    Box,
    Grid,
    TextField,
    MenuItem,
    Alert,
    OutlinedInput,
    Tabs,
    Tab,
    Checkbox,
    Collapse,
    FormGroup,
    FormControlLabel,
    Button,
    CircularProgress,
    Switch
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {  
    PointOfSaleTwoTone,
    BadgeTwoTone,
    Inventory2TwoTone,
    TollTwoTone,
    GridViewTwoTone, CategoryTwoTone, FolderSharedTwoTone, CorporateFareTwoTone, LocalConvenienceStoreTwoTone, BusinessTwoTone, DiscountTwoTone } from '@mui/icons-material';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../Helper/Loader';
import { green, red, grey } from '@mui/material/colors';
  
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        sx={{ width: '100%' }}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3, width: '100%' }}>
                {children}
            </Box>
        )}
        </Box>
    );
}
  
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Toggle = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&::before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&::after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  

export default function Form() {
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const { employee_access, inProgress, errors} = useSelector(state => ({
        ...state.employee_access
    }), shallowEqual);

    const [backofficeValue, setBackofficeValue] = useState(0);
    const [appValue, setAppValue] = useState(0);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        if (id) {
			dispatch(employeeAccessActions.onEmployeeAccessEdit(id));
		} else {
			dispatch(employeeAccessActions.onEmployeeAccessNew());
		}
        return () => {
            dispatch(employeeAccessActions.onUnloadForm())
        };
    },[dispatch, id]);



    const onChangeBackoffice = (event, newValue) => {
        setBackofficeValue(newValue);
    };

    const onChangeApp = (event, newValue) => {
        setAppValue(newValue);
    };

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(employeeAccessActions.onUpdateField(key, value));
    }

    function selectAccesses(value) {
        var newAccesses = [...accesses];
        if (!accesses.includes(value)) {
            newAccesses.push(value);
        } else if (accesses.includes(value)) {
            const itemIndex = newAccesses.findIndex(o => o === value)
            newAccesses.splice(itemIndex, 1);
        }    
        const newEmployeeAccess = {...employee_access};
        newEmployeeAccess.accesses = newAccesses;
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function selectPermission(value) {
        var newPermissions = [...permissions];
        if (!permissions.includes(value)) {
            newPermissions.push(value);
        } else if (permissions.includes(value)) {
            const itemIndex = newPermissions.findIndex(o => o === value)
            newPermissions.splice(itemIndex, 1);
        }    
        const newEmployeeAccess = {...employee_access};
        newEmployeeAccess.permissions = newPermissions;
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledDashboard() {
        var newPermissions = [...permissions];
        if (!permissions.includes('view_daily_chart')) {
            newPermissions.push('view_daily_chart');
        } else if (permissions.includes('view_daily_chart')) {
            const itemIndex = newPermissions.findIndex(o => o === 'view_daily_chart')
            newPermissions.splice(itemIndex, 1);
        }    
        const newEmployeeAccess = {...employee_access};
        newEmployeeAccess.permissions = newPermissions;
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledProduct() {
        let newPermissions = [...permissions];
        if (!productToggled) {
            newPermissions.push('create_product', 'edit_product');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'create_product' && p !== 'edit_product');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledCategoryProduct() {
        let newPermissions = [...permissions];
        if (!categoryToggled) {
            newPermissions.push('create_category_product', 'edit_category_product');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'create_category_product' && p !== 'edit_category_product');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }


    function onToggledCustomer() {
        let newPermissions = [...permissions];
        if (!customerToggled) {
            newPermissions.push('view_customer', 'edit_customer');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'view_customer' && p !== 'edit_customer');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledCustomerGroup() {
        let newPermissions = [...permissions];
        if (!customerGroupToggled) {
            newPermissions.push('create_customer_group', 'edit_customer_group');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'create_customer_group' && p !== 'edit_customer_group');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }


    function onToggledEmployee() {
        let newPermissions = [...permissions];
        if (!employeeToggled) {
            newPermissions.push('view_employee', 'create_employee', 'edit_employee');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'view_employee' && p !== 'create_employee' && p !== 'edit_employee');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledOrganization() {
        let newPermissions = [...permissions];
        if (!organizationToggled) {
            newPermissions.push('view_organization', 'create_organization', 'edit_organization');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'view_organization' && p !== 'create_organization' && p !== 'edit_organization');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledJobPosition() {
        let newPermissions = [...permissions];
        if (!jobPositionToggled) {
            newPermissions.push('view_job_position', 'create_job_position', 'edit_job_position');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'view_job_position' && p !== 'create_job_position' && p !== 'edit_job_position');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledRole() {
        let newPermissions = [...permissions];
        if (!roleToggled) {
            newPermissions.push('view_role', 'create_role', 'edit_role');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'view_role' && p !== 'create_role' && p !== 'edit_role');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledCustomerDisplay() {
        let newPermissions = [...permissions];
        if (!customerDisplayToggled) {
            newPermissions.push('create_customer_display', 'edit_customer_display');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'create_customer_display' && p !== 'edit_customer_display');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledInventory() {
        let newPermissions = [...permissions];
        if (!inventoryToggled) {
            newPermissions.push('view_inventory');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'view_inventory');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledPurchaseOrder() {
        let newPermissions = [...permissions];
        if (!purchaseOrderToggled) {
            newPermissions.push('requested_purchase_order', 'ordered_purchase_order', 'received_purchase_order', 'canceled_purchase_order');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'requested_purchase_order' && p !== 'ordered_purchase_order' && p !== 'received_purchase_order' && p !== 'canceled_purchase_order');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledPurchaseOrderReturn() {
        let newPermissions = [...permissions];
        if (!purchaseOrderReturnToggled) {
            newPermissions.push('requested_purchase_order_return', 'approved_purchase_order_return', 'returned_purchase_order_return', 'canceled_purchase_order_return');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'requested_purchase_order_return' && p !== 'approved_purchase_order_return' && p !== 'returned_purchase_order_return' && p !== 'canceled_purchase_order_return');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledStoreTransfer() {
        let newPermissions = [...permissions];
        if (!storeTransferToggled) {
            newPermissions.push('requested_store_transfer', 'approved_store_transfer', 'transfered_store_transfer', 'canceled_store_transfer');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'requested_store_transfer' && p !== 'approved_store_transfer' && p !== 'transfered_store_transfer' && p !== 'canceled_store_transfer');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }


    function onToggledStoreAdjustment() {
        let newPermissions = [...permissions];
        if (!storeAdjustmentToggled) {
            newPermissions.push('requested_store_adjustment','checked_store_adjustment', 'canceled_store_adjustment');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'requested_store_adjustment' && p !== 'checked_store_adjustment' && p !== 'canceled_store_adjustment');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledSupplier() {
        let newPermissions = [...permissions];
        if (!supplierToggled) {
            newPermissions.push('view_supplier', 'create_supplier', 'edit_supplier', 'create_supplier_payment');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'view_supplier' && p !== 'create_supplier' && p !== 'edit_supplier' && p !== 'create_supplier_payment');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledOrder() {
        let newPermissions = [...permissions];
        if (!orderToggled) {
            newPermissions.push('edit_order');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'edit_order');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }


    function onToggledOrderDelivery() {
        let newPermissions = [...permissions];
        if (!orderDeliveryToggled) {
            newPermissions.push('completed_order_delivery', 'canceled_order_delivery');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'completed_order_delivery' && p !== 'canceled_order_delivery');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledOrderDeliveryReturn() {
        let newPermissions = [...permissions];
        if (!orderDeliveryReturnToggled) {
            newPermissions.push('completed_order_delivery_return', 'canceled_order_delivery_return');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'completed_order_delivery_return' && p !== 'canceled_order_delivery_return');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledOrderCanceled() {
        let newPermissions = [...permissions];
        if (!orderCanceledToggled) {
            newPermissions.push('completed_order_canceled', 'canceled_order_canceled');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'completed_order_canceled' && p !== 'canceled_order_canceled');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledStore() {
        let newPermissions = [...permissions];
        if (!storeToggled) {
            newPermissions.push('view_store', 'create_store', 'edit_store');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'view_store' && p !== 'create_store' && p !== 'edit_store');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledSetting() {
        let newPermissions = [...permissions];
        if (!settingToggled) {
            newPermissions.push('edit_cashier_configuration', 'edit_invoice_configuration', 'edit_job_position_configuration', 'edit_inventory_configuration');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'edit_cashier_configuration' && p !== 'edit_invoice_configuration' && p !== 'edit_job_position_configuration' && p !== 'edit_inventory_configuration');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }


    function onToggledOrderApp() {
        let newPermissions = [...permissions];
        if (!appOrderToggled) {
            newPermissions.push('view_order_app', 'create_order_app', 'create_order_canceled_app', 'create_order_delivery_app');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'view_order_app' && p !== 'create_order_app' && p !== 'create_order_canceled_app' && p !== 'create_order_delivery_app');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }


    function onToggledInventoryApp() {
        let newPermissions = [...permissions];
        if (!appInventoryToggled) {
            newPermissions.push('view_inventory_app', 'print_label_price_app', 'print_label_barcode_app');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'view_inventory_app' && p !== 'print_label_price_app' && p !== 'print_label_barcode_app');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }


    function onToggledCustomerApp() {
        let newPermissions = [...permissions];
        if (!appCustomerToggled) {
            newPermissions.push('view_customer_app', 'create_customer_app', 'edit_customer_app');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'view_customer_app' && p !== 'create_customer_app' && p !== 'edit_customer_app');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onToggledPaymentApp() {
        let newPermissions = [...permissions];
        if (!appPaymentToggled) {
            newPermissions.push('received_cash_payment_app','received_non_cash_payment_app', 'received_digital_payment_app', 'received_gift_card_payment_app', 'create_invoice_app', 'receive_invoice_payment_app');
        } else {
            newPermissions = newPermissions.filter(p => p !== 'received_cash_payment_app' && p !== 'received_non_cash_payment_app' && p !== 'received_digital_payment_app' && p !== 'received_gift_card_payment_app' && p !== 'create_invoice_app' && p !== 'receive_invoice_payment_app');
        }
        const newEmployeeAccess = { ...employee_access, permissions: newPermissions };
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onSubmit() {
        if (id) {
            dispatch(employeeAccessActions.onEdit(id, employee_access));
        } else {
            dispatch(employeeAccessActions.onCreate(employee_access));
        }
    }

    
    
    if (!employee_access) {
        return (
            <Loader />
        )
    }

    function error(key) {
        if (errors != null && errors[`employee_access.${key}`] != null) {
            return errors[`employee_access.${key}`].msg
        }
        return null;
    }


    const {name, accesses, permissions} = employee_access;


    const accessApp = accesses.includes('App');
    const accessBackoffice = accesses.includes('Backoffice');

    const dashboardToggled = permissions.includes('view_daily_chart')
    const productToggled = permissions.includes('create_product') || permissions.includes('edit_product');
    const categoryToggled = permissions.includes('create_category_product') || permissions.includes('edit_category_product');
    const customerToggled = permissions.includes('view_customer') || permissions.includes('edit_customer');
    const customerGroupToggled = permissions.includes('create_customer_group') || permissions.includes('edit_customer_group');
    const employeeToggled = permissions.includes('view_employee') || permissions.includes('create_employee') || permissions.includes('edit_employee');
    const organizationToggled = permissions.includes('view_organization') || permissions.includes('create_organization') || permissions.includes('edit_organization');
    const jobPositionToggled = permissions.includes('view_job_position') || permissions.includes('create_job_position') || permissions.includes('edit_job_position');
    const roleToggled = permissions.includes('view_role') || permissions.includes('create_role') || permissions.includes('edit_role');
    const customerDisplayToggled = permissions.includes('create_customer_display') || permissions.includes('edit_customer_display');
    const inventoryToggled = permissions.includes('view_inventory');
    const purchaseOrderToggled = permissions.includes('requested_purchase_order') || permissions.includes('ordered_purchase_order') || permissions.includes('received_purchase_order') || permissions.includes('canceled_purchase_order');
    const purchaseOrderReturnToggled = permissions.includes('requested_purchase_order_return') || permissions.includes('approved_purchase_order_return') || permissions.includes('returned_purchase_order_return') || permissions.includes('canceled_purchase_order_return');
    const storeTransferToggled = permissions.includes('requested_store_transfer') || permissions.includes('approved_store_transfer') || permissions.includes('transfered_store_transfer') || permissions.includes('canceled_store_transfer');
    const storeAdjustmentToggled = permissions.includes('requested_store_adjustment') || permissions.includes('checked_store_adjustment') || permissions.includes('canceled_store_adjustment');
    const supplierToggled = permissions.includes('view_supplier') || permissions.includes('create_supplier') || permissions.includes('edit_supplier') || permissions.includes('create_supplier_payment');
    const orderToggled = permissions.includes('edit_order');
    const orderDeliveryToggled = permissions.includes('completed_order_delivery') || permissions.includes('canceled_order_delivery');
    const orderDeliveryReturnToggled = permissions.includes('completed_order_delivery_return') || permissions.includes('canceled_order_delivery_return');
    const orderCanceledToggled = permissions.includes('completed_order_canceled') || permissions.includes('canceled_order_canceled');
    const storeToggled = permissions.includes('view_store') || permissions.includes('create_store') || permissions.includes('edit_store');
    const settingToggled = permissions.includes('edit_cashier_configuration') || permissions.includes('edit_invoice_configuration') || permissions.includes('edit_job_position_configuration') || permissions.includes('edit_inventory_configuration');

    const dashboardActive = dashboardToggled
    const productActive = productToggled || categoryToggled
    const customerActive = customerToggled || customerGroupToggled
    const organizationActive = employeeToggled || organizationToggled || jobPositionToggled || roleToggled
    const customerDisplayActive = customerDisplayToggled
    const storeActive = inventoryToggled || purchaseOrderToggled || purchaseOrderReturnToggled || storeTransferToggled || storeAdjustmentToggled || supplierToggled || orderToggled || orderDeliveryToggled || orderDeliveryReturnToggled || orderCanceledToggled || storeToggled
    const settingActive = settingToggled


    const appOrderToggled = permissions.includes('view_order_app') || permissions.includes('create_order_app') || permissions.includes('create_order_canceled_app') || permissions.includes('create_order_delivery_app');
    const appInventoryToggled = permissions.includes('view_inventory_app') || permissions.includes('print_label_price_app') || permissions.includes('print_label_barcode_app');
    const appCustomerToggled = permissions.includes('view_customer_app') || permissions.includes('create_customer_app') || permissions.includes('edit_customer_app');
    const appPaymentToggled = permissions.includes('received_cash_payment_app') || permissions.includes('received_non_cash_payment_app') || permissions.includes('received_digital_payment_app') || permissions.includes('received_gift_card_payment_app') || permissions.includes('create_invoice_app') || permissions.includes('receive_invoice_payment_app');
  
    const appOrderActive = appOrderToggled;
    const appInventoryActive = appInventoryToggled;
    const appCustomerActive = appCustomerToggled
    const appPaymentActive = appPaymentToggled
    return (
        <Box>
            <ContentPaper elevation={3}>
                <Grid container spacing={6} direction="column">
                    <Grid item container>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                Role Information
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Alert severity="error">
                                Required
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={5}>
                        <Grid item container spacing={8}>

                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Role Name
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Enter employee access Role Name.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    error={!!error('name')}
                                    name="name"
                                    placeholder="Role Name"
                                    value={name}
                                    helperText={error('name')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </ContentPaper>
            <ContentPaper elevation={3}>
                <Grid container spacing={2} direction="column">
                    <Grid item container alignItems="center">
                        <Grid item>
                            <Checkbox
                                color="primary"
                                onChange={selectAccesses.bind(this, "Backoffice")}
                                checked={accessBackoffice}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                Backoffice Permissions
                            </Typography>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                    <Grid sx={{marginBottom: '35px'}} item>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Allow employees to access and manage your backoffice.
                        </Typography>
                    </Grid>
                </Grid>
                <Collapse in={accessBackoffice} timeout="auto" unmountOnExit>
                    <Box
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', width: '100%', minHeight: 750 }}
                        >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={backofficeValue}
                            onChange={onChangeBackoffice}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, minWidth: '255px', borderColor: 'divider' }}
                        >
                            <Tab icon={<GridViewTwoTone />} sx={{justifyContent: 'flex-start', minHeight: '50px', paddingRight: '25px'}} iconPosition="start" label={
                                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography fontWeight={500} lineheight="normal">
                                        Dashboard
                                    </Typography>
                                    <Typography color={grey[600]} fontSize={14} lineheight="normal">
                                        {dashboardActive ? "Aktif" : "Off"}
                                    </Typography>
                                </Box>
                            } {...a11yProps(0)} />
                            <Tab icon={<CategoryTwoTone />} sx={{justifyContent: 'flex-start', minHeight: '50px', paddingRight: '25px'}} iconPosition="start" label={
                                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography fontWeight={500} lineheight="normal">
                                        Produk
                                    </Typography>
                                    <Typography color={grey[600]} fontSize={14} lineheight="normal">
                                        {productActive ? "Aktif" : "Off"}
                                    </Typography>
                                </Box>
                            } {...a11yProps(1)} />
                            <Tab icon={<FolderSharedTwoTone />} sx={{justifyContent: 'flex-start', minHeight: '50px', paddingRight: '25px'}} iconPosition="start" label={
                                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography fontWeight={500} lineheight="normal">
                                        Pelanggan
                                    </Typography>
                                    <Typography color={grey[600]} fontSize={14} lineheight="normal">
                                        {customerActive ? "Aktif" : "Off"}
                                    </Typography>
                                </Box>
                            } {...a11yProps(2)} />
                            <Tab icon={<CorporateFareTwoTone />} sx={{justifyContent: 'flex-start', minHeight: '50px', paddingRight: '25px'}} iconPosition="start" label={
                                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography fontWeight={500} lineheight="normal">
                                        Organisasi
                                    </Typography>
                                    <Typography color={grey[600]} fontSize={14} lineheight="normal">
                                        {organizationActive ? "Aktif" : "Off"}
                                    </Typography>
                                </Box>
                            } {...a11yProps(3)} />
                            <Tab icon={<DiscountTwoTone />} sx={{justifyContent: 'flex-start', minHeight: '50px', paddingRight: '25px'}} iconPosition="start" label={
                                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography fontWeight={500} lineheight="normal">
                                        Pemasaran
                                    </Typography>
                                    <Typography color={grey[600]} fontSize={14} lineheight="normal">
                                        {customerDisplayActive ? "Aktif" : "Off"}
                                    </Typography>
                                </Box>
                            } {...a11yProps(4)} />
                            <Tab icon={<BusinessTwoTone />} sx={{justifyContent: 'flex-start', minHeight: '50px', paddingRight: '25px'}} iconPosition="start" label={
                                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography fontWeight={500} lineheight="normal">
                                        Cabang
                                    </Typography>
                                    <Typography color={grey[600]} fontSize={14} lineheight="normal">
                                        {storeActive ? "Aktif" : "Off"}
                                    </Typography>
                                </Box>
                            } {...a11yProps(5)} />
                            <Tab icon={<LocalConvenienceStoreTwoTone />} sx={{justifyContent: 'flex-start', minHeight: '50px', paddingRight: '25px'}} iconPosition="start" label={
                                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography fontWeight={500} lineheight="normal">
                                        Pengaturan
                                    </Typography>
                                    <Typography color={grey[600]} fontSize={14} lineheight="normal">
                                        {settingActive ? "Aktif" : "Off"}
                                    </Typography>
                                </Box>
                            } {...a11yProps(6)} />
                           
                        </Tabs>
                        <TabPanel value={backofficeValue} index={0}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Dashboard
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan untuk melihat data dari dashboard.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Toggle checked={dashboardToggled} onChange={onToggledDashboard} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={dashboardToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('view_daily_chart')} onChange={selectPermission.bind(this, 'view_daily_chart')} />} 
                                                    label="Berikan akses melihat grafik harian." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </TabPanel>
                        <TabPanel value={backofficeValue} index={1}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Produk
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan untuk melihat, membuat, dan mengubah produk.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Toggle checked={productToggled} onChange={onToggledProduct} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={productToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_product')} onChange={selectPermission.bind(this, 'create_product')} />} 
                                                    label="Berikan akses menambahkan produk." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_product')} onChange={selectPermission.bind(this, 'edit_product')} />} 
                                                    label="Berikan akses mengubah produk." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container spacing={3} direction="column">
                                <Grid item container>
                                    <Grid item xs>
                                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                                            Kategori Produk
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Atur akses karyawan untuk melihat, membuat, dan mengubah kategori produk.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Toggle checked={categoryToggled} onChange={onToggledCategoryProduct} />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={categoryToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_category_product')} onChange={selectPermission.bind(this, 'create_category_product')} />} 
                                                    label="Berikan akses menambahkan kategori produk." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_category_product')} onChange={selectPermission.bind(this, 'edit_category_product')} />} 
                                                    label="Berikan akses mengubah kategori produk." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </TabPanel>
                        <TabPanel value={backofficeValue} index={2}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Pelanggan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan untuk melihat data dan mengubah data pelanggan.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Toggle checked={customerToggled} onChange={onToggledCustomer} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={customerToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox disabled checked={permissions.includes('view_customer')} onChange={selectPermission.bind(this, 'view_customer')} />} 
                                                    label="Berikan akses melihat detail pelanggan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_customer')} onChange={selectPermission.bind(this, 'edit_customer')} />} 
                                                    label="Berikan akses mengubah sebagian data pelanggan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container spacing={3} direction="column">
                                <Grid item container>
                                    <Grid item xs>
                                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                                            Grup Pelanggan
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Atur akses karyawan untuk melihat data dan mengubah grup pelanggan.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Toggle checked={customerGroupToggled} onChange={onToggledCustomerGroup} />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={customerGroupToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_customer_group')} onChange={selectPermission.bind(this, 'create_customer_group')} />} 
                                                    label="BBerikan akses menambahkan grup pelanggan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_customer_group')} onChange={selectPermission.bind(this, 'edit_customer_group')} />} 
                                                    label="Berikan akses mengubah grup pelanggan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </TabPanel>
                        <TabPanel value={backofficeValue} index={3}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Karyawan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan untuk membuat dan mengubah data karyawan.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Toggle checked={employeeToggled} onChange={onToggledEmployee} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={employeeToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox disabled checked={permissions.includes('view_employee')} onChange={selectPermission.bind(this, 'view_employee')} />} 
                                                    label="Berikan akses melihat detail karyawan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_employee')} onChange={selectPermission.bind(this, 'create_employee')} />} 
                                                    label="Berikan akses menambahkan data karyawan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_employee')} onChange={selectPermission.bind(this, 'edit_employee')} />} 
                                                    label="Berikan akses mengubah sebagian data karyawan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container spacing={3} direction="column">
                                <Grid item container>
                                    <Grid item xs>
                                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                                            Organisasi
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Atur akses karyawan untuk membuat dan mengubah organisasi.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Toggle checked={organizationToggled} onChange={onToggledOrganization} />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={organizationToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox disabled checked={permissions.includes('view_organization')} onChange={selectPermission.bind(this, 'view_organization')} />} 
                                                    label="Berikan akses melihat organisasi." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_organization')} onChange={selectPermission.bind(this, 'create_organization')} />} 
                                                    label="Berikan akses menambahkan organisasi." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_organization')} onChange={selectPermission.bind(this, 'edit_organization')} />} 
                                                    label="Berikan akses mengubah organisasi." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container spacing={3} direction="column">
                                <Grid item container>
                                    <Grid item xs>
                                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                                            Jabatan
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Atur akses karyawan untuk membuat dan mengubah jabatan.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Toggle checked={jobPositionToggled} onChange={onToggledJobPosition} />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={jobPositionToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox disabled checked={permissions.includes('view_job_position')} onChange={selectPermission.bind(this, 'view_job_position')} />} 
                                                    label="Berikan akses melihat jabatan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_job_position')} onChange={selectPermission.bind(this, 'create_job_position')} />} 
                                                    label="Berikan akses menambahkan jabatan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_job_position')} onChange={selectPermission.bind(this, 'edit_job_position')} />} 
                                                    label="Berikan akses mengubah jabatan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container spacing={3} direction="column">
                                <Grid item container>
                                    <Grid item xs>
                                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                                            Akses Karyawan
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Atur akses karyawan untuk membuat dan mengubah akses karyawan.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Toggle checked={roleToggled} onChange={onToggledRole} />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={roleToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox disabled checked={permissions.includes('view_role')} onChange={selectPermission.bind(this, 'view_role')} />} 
                                                    label="Berikan akses melihat detail akses karyawan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_role')} onChange={selectPermission.bind(this, 'create_role')} />} 
                                                    label="Berikan akses menambahkan akses karyawan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_role')} onChange={selectPermission.bind(this, 'edit_role')} />} 
                                                    label="Berikan akses mengubah akses karyawan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </TabPanel>
                        <TabPanel value={backofficeValue} index={4}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Tampilan Pelanggan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan untuk membuat dan mengubah tampilan pelanggan.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Toggle checked={customerDisplayToggled} onChange={onToggledCustomerDisplay} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={customerDisplayToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_customer_display')} onChange={selectPermission.bind(this, 'create_customer_display')} />} 
                                                    label="Berikan akses menambahkan tampilan pelanggan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_customer_display')} onChange={selectPermission.bind(this, 'edit_customer_display')} />} 
                                                    label="Berikan akses mengubah sebagian tampilan pelanggan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </TabPanel>
                        <TabPanel value={backofficeValue} index={5}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Persediaan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan untuk meilihat persediaan.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                       control={<Toggle checked={inventoryToggled} onChange={onToggledInventory} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={inventoryToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('view_inventory')} onChange={selectPermission.bind(this, 'view_inventory')} />} 
                                                    label="Berikan akses melihat persediaan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Pembelian
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan membuat dan memproses pembelian.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                       control={<Toggle checked={purchaseOrderToggled} onChange={onToggledPurchaseOrder} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={purchaseOrderToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('requested_purchase_order')} onChange={selectPermission.bind(this, 'requested_purchase_order')} />} 
                                                    label="Berikan akses mengajukan pesanan pembelian." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('ordered_purchase_order')} onChange={selectPermission.bind(this, 'ordered_purchase_order')} />} 
                                                    label="Berikan akses menambahkan pesanan pembelian."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('received_purchase_order')} onChange={selectPermission.bind(this, 'received_purchase_order')} />} 
                                                    label="Berikan akses menerima pesanan pembelian."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('canceled_purchase_order')} onChange={selectPermission.bind(this, 'canceled_purchase_order')} />} 
                                                    label="Berikan akses membatalkan pesanan pembelian."
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Retur Pembelian
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan membuat dan memproses retur pembelian.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                       control={<Toggle checked={purchaseOrderReturnToggled} onChange={onToggledPurchaseOrderReturn} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={purchaseOrderReturnToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('requested_purchase_order_return')} onChange={selectPermission.bind(this, 'requested_purchase_order_return')} />} 
                                                    label="Berikan akses mengajukan retur pembelian." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('approved_purchase_order_return')} onChange={selectPermission.bind(this, 'approved_purchase_order_return')} />} 
                                                    label="Berikan akses menyetujui retur pembelian."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('returned_purchase_order_return')} onChange={selectPermission.bind(this, 'returned_purchase_order_return')} />} 
                                                    label="Berikan akses konfirmasi retur pembelian."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('canceled_purchase_order_return')} onChange={selectPermission.bind(this, 'canceled_purchase_order_return')} />} 
                                                    label="Berikan akses membatalkan retur pembelian."
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Transfer Persediaan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan membuat dan memproses transfer persediaan.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                       control={<Toggle checked={storeTransferToggled} onChange={onToggledStoreTransfer} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={storeTransferToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('requested_store_transfer')} onChange={selectPermission.bind(this, 'requested_store_transfer')} />} 
                                                    label="Berikan akses mengajukan transfer persediaan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('approved_store_transfer')} onChange={selectPermission.bind(this, 'approved_store_transfer')} />} 
                                                    label="Berikan akses menyetujui transfer persediaan."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('transfered_store_transfer')} onChange={selectPermission.bind(this, 'transfered_store_transfer')} />} 
                                                    label="Berikan akses konfirmasi transfer persediaan."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('canceled_store_transfer')} onChange={selectPermission.bind(this, 'canceled_store_transfer')} />} 
                                                    label="Berikan akses membatalkan transfer persediaan."
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Penyesuaian
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan membuat dan memproses penyesuaian.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                       control={<Toggle checked={storeAdjustmentToggled} onChange={onToggledStoreAdjustment} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={storeAdjustmentToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('requested_store_adjustment')} onChange={selectPermission.bind(this, 'requested_store_adjustment')} />} 
                                                    label="Berikan akses mengajukan penyesuaian persediaan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('checked_store_adjustment')} onChange={selectPermission.bind(this, 'checked_store_adjustment')} />} 
                                                    label="Berikan akses menyetujui penyesuaian persediaan."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('canceled_store_adjustment')} onChange={selectPermission.bind(this, 'canceled_store_adjustment')} />} 
                                                    label="Berikan akses membatalkan penyesuaian persediaan."
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Supplier
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan membuat dan mengubah cabang.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                       control={<Toggle checked={supplierToggled} onChange={onToggledSupplier} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={supplierToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('view_supplier')} onChange={selectPermission.bind(this, 'view_supplier')} />} 
                                                    label="Berikan akses melihat detail supplier."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_supplier')} onChange={selectPermission.bind(this, 'create_supplier')} />} 
                                                    label="Berikan akses menambahkan supplier."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_supplier')} onChange={selectPermission.bind(this, 'edit_supplier')} />} 
                                                    label="Berikan akses mengubah supplier."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_supplier_payment')} onChange={selectPermission.bind(this, 'create_supplier_payment')} />} 
                                                    label="Berikan akses membuat pembayaran kepada supplier."
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Pesanan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan melihat dan mengubah pesanan.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                       control={<Toggle checked={orderToggled} onChange={onToggledOrder} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={orderToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_order')} onChange={selectPermission.bind(this, 'edit_order')} />} 
                                                    label="Berikan akses memperbaharui pesanan."
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Pengiriman
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan mengubah dan membatalkan pengiriman.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                       control={<Toggle checked={orderDeliveryToggled} onChange={onToggledOrderDelivery} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={orderDeliveryToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('completed_order_delivery')} onChange={selectPermission.bind(this, 'completed_order_delivery')} />} 
                                                    label="Berikan akses memperbaharui pengiriman."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('canceled_order_delivery')} onChange={selectPermission.bind(this, 'canceled_order_delivery')} />} 
                                                    label="Berikan akses membatalkan pengiriman."
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Retur Pengiriman
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan mengubah dan membatalkan return pengiriman.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                       control={<Toggle checked={orderDeliveryReturnToggled} onChange={onToggledOrderDeliveryReturn} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={orderDeliveryReturnToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('completed_order_delivery_return')} onChange={selectPermission.bind(this, 'completed_order_delivery_return')} />} 
                                                    label="Berikan akses memperbaharui retur pengiriman."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('canceled_order_delivery_return')} onChange={selectPermission.bind(this, 'canceled_order_delivery_return')} />} 
                                                    label="Berikan akses membatalkan retur pengiriman."
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Pembatalan Pesanan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan mengubah dan membatalkan pesanan.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                       control={<Toggle checked={orderCanceledToggled} onChange={onToggledOrderCanceled} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={orderCanceledToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('completed_order_canceled')} onChange={selectPermission.bind(this, 'completed_order_canceled')} />} 
                                                    label="Berikan akses memperbaharui pembatalan pesanan."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('canceled_order_canceled')} onChange={selectPermission.bind(this, 'canceled_order_canceled')} />} 
                                                    label="Berikan akses membatalkan pembatalan pesanan."
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Cabang
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan membuat dan mengubah cabang.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                       control={<Toggle checked={storeToggled} onChange={onToggledStore} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={storeToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('view_store')} onChange={selectPermission.bind(this, 'view_store')} />} 
                                                    label="Berikan akses melihat detail cabang."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_store')} onChange={selectPermission.bind(this, 'create_store')} />} 
                                                    label="Berikan akses menambahkan cabang."
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_store')} onChange={selectPermission.bind(this, 'edit_store')} />} 
                                                    label="Berikan akses mengubah cabang."
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </TabPanel>
                        <TabPanel value={backofficeValue} index={6}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Pengaturan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan untuk mengubah pengaturan umum.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Toggle checked={settingToggled} onChange={onToggledSetting} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={settingToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_cashier_configuration')} onChange={selectPermission.bind(this, 'edit_cashier_configuration')} />} 
                                                    label="Berikan akses mengubah informasi kasir." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_invoice_configuration')} onChange={selectPermission.bind(this, 'edit_invoice_configuration')} />} 
                                                    label="Berikan akses mengubah informasi faktur." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_job_position_configuration')} onChange={selectPermission.bind(this, 'edit_job_position_configuration')} />} 
                                                    label="Berikan akses mengubah informasi jabatan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_inventory_configuration')} onChange={selectPermission.bind(this, 'edit_inventory_configuration')} />} 
                                                    label="Berikan akses mengubah pengaturan persediaan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </TabPanel>
                    </Box>
                </Collapse>
            </ContentPaper>
            <ContentPaper elevation={3}>
                <Grid container spacing={2} direction="column">
                    <Grid item container alignItems="center">
                        <Grid item>
                            <Checkbox
                                color="primary"
                                onChange={selectAccesses.bind(this, "App")}
                                checked={accesses.includes('App')}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                App Permissions
                            </Typography>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                    <Grid sx={{marginBottom: '35px'}} item>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Allow employees to access and manage your app.
                        </Typography>
                    </Grid>
                </Grid>
                <Collapse in={accessApp} timeout="auto" unmountOnExit>
                    <Box
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', width: '100%', minHeight: 750 }}
                        >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={appValue}
                            onChange={onChangeApp}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, minWidth: '255px', borderColor: 'divider' }}
                        >
                            <Tab icon={<PointOfSaleTwoTone />} sx={{justifyContent: 'flex-start', minHeight: '50px', paddingRight: '25px'}} iconPosition="start" label={
                                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography fontWeight={500} lineheight="normal">
                                        Pesanan
                                    </Typography>
                                    <Typography color={grey[600]} fontSize={14} lineheight="normal">
                                        {appOrderActive ? "Aktif" : "Off"}
                                    </Typography>
                                </Box>
                            } {...a11yProps(0)} />
                            <Tab icon={<Inventory2TwoTone />} sx={{justifyContent: 'flex-start', minHeight: '50px', paddingRight: '25px'}} iconPosition="start" label={
                                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography fontWeight={500} lineheight="normal">
                                        Persediaan
                                    </Typography>
                                    <Typography color={grey[600]} fontSize={14} lineheight="normal">
                                        {appInventoryActive ? "Aktif" : "Off"}
                                    </Typography>
                                </Box>
                            } {...a11yProps(1)} />
                            <Tab icon={<BadgeTwoTone />} sx={{justifyContent: 'flex-start', minHeight: '50px', paddingRight: '25px'}} iconPosition="start" label={
                                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography fontWeight={500} lineheight="normal">
                                        Pelanggan
                                    </Typography>
                                    <Typography color={grey[600]} fontSize={14} lineheight="normal">
                                        {appCustomerActive ? "Aktif" : "Off"}
                                    </Typography>
                                </Box>
                            } {...a11yProps(2)} />
                            <Tab icon={<TollTwoTone />} sx={{justifyContent: 'flex-start', minHeight: '50px', paddingRight: '25px'}} iconPosition="start" label={
                                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography fontWeight={500} lineheight="normal">
                                        Pembayaran
                                    </Typography>
                                    <Typography color={grey[600]} fontSize={14} lineheight="normal">
                                        {appPaymentActive ? "Aktif" : "Off"}
                                    </Typography>
                                </Box>
                            } {...a11yProps(3)} />
                        </Tabs>
                        <TabPanel value={appValue} index={0}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Pesanan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan untuk membuat dan memperbaharui pesanan.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Toggle checked={appOrderToggled} onChange={onToggledOrderApp} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={appOrderToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('view_order_app')} onChange={selectPermission.bind(this, 'view_order_app')} />} 
                                                    label="Berikan akses melihat pesanan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_order_app')} onChange={selectPermission.bind(this, 'create_order_app')} />} 
                                                    label="Berikan akses menambahkan pesanan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_order_canceled_app')} onChange={selectPermission.bind(this, 'create_order_canceled_app')} />} 
                                                    label="Berikan akses membatalkan pesanan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_order_delivery_app')} onChange={selectPermission.bind(this, 'create_order_delivery_app')} />} 
                                                    label="Berikan akses manambahkan pengiriman pesanan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </TabPanel>
                        <TabPanel value={appValue} index={1}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Persediaan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan untuk menangani persediaan.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Toggle checked={appInventoryToggled} onChange={onToggledInventoryApp} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={appInventoryToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('view_inventory_app')} onChange={selectPermission.bind(this, 'view_inventory_app')} />} 
                                                    label="Berikan akses melihat persediaan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('print_label_price_app')} onChange={selectPermission.bind(this, 'print_label_price_app')} />} 
                                                    label="Berikan akses mencetak label harga." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('print_label_barcode_app')} onChange={selectPermission.bind(this, 'print_label_barcode_app')} />} 
                                                    label="Berikan akses mencetak label barcode." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </TabPanel>     
                        <TabPanel value={appValue} index={2}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Pelanggan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan untuk membuat dan mengubah data pelanggan.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Toggle checked={appCustomerToggled} onChange={onToggledCustomerApp} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={appCustomerToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('view_customer_app')} onChange={selectPermission.bind(this, 'view_customer_app')} />} 
                                                    label="Berikan akses melihat pelanggan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_customer_app')} onChange={selectPermission.bind(this, 'create_customer_app')} />} 
                                                    label="Berikan akses manambahkan pelanggan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('edit_customer_app')} onChange={selectPermission.bind(this, 'edit_customer_app')} />} 
                                                    label="Berikan akses memperbaharui data pelanggan." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </TabPanel>         
                        <TabPanel value={appValue} index={3}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                                        Pembayaran
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Atur akses karyawan untuk menerima pembayaran.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Toggle checked={appPaymentToggled} onChange={onToggledPaymentApp} />}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{p: 2}}> 
                                <Collapse in={appPaymentToggled} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('received_cash_payment_app')} onChange={selectPermission.bind(this, 'received_cash_payment_app')} />} 
                                                    label="Berikan akses menerima pembayaran cash." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('received_non_cash_payment_app')} onChange={selectPermission.bind(this, 'received_non_cash_payment_app')} />} 
                                                    label="Berikan akses menerima pembayaran non cash seperti edc." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('received_digital_payment_app')} onChange={selectPermission.bind(this, 'received_digital_payment_app')} />} 
                                                    label="Berikan akses menerima pembayaran digital." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('received_gift_card_payment_app')} onChange={selectPermission.bind(this, 'received_gift_card_payment_app')} />} 
                                                    label="Berikan akses menerima pembayaran kupon / voucher." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('create_invoice_app')} onChange={selectPermission.bind(this, 'create_invoice_app')} />} 
                                                    label="Berikan akses menambahkan pesanan dengan tempo pembayaran." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item container>
                                            <FormGroup>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={permissions.includes('receive_invoice_payment_app')} onChange={selectPermission.bind(this, 'receive_invoice_payment_app')} />} 
                                                    label="Berikan akses menerima pembayaran faktur." 
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </TabPanel>                        
                    </Box>
                </Collapse>
            </ContentPaper>
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    disabled={inProgress}
                                    onClick={ () => {navigate(`/roles`)}}>
                                    Cancel
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
        </Box>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));


const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));
