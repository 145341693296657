import React, { useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { onCustomerGroupNew, onCustomerGroupEdit, onUnloadForm, onUpdateCustomerGroup, onEdit, onCreate } from '../../../../actions/customerGroupActions';
import { 
        CircularProgress,
        Button,
        Typography,
        Paper,
        Box,
        Grid,
        TextField,
        FormGroup,
        FormControlLabel,
        Switch,
        Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../Helper/Loader';

export default function Form() {
    let navigate = useNavigate();
    let { id, parent_id } = useParams();
    const dispatch = useDispatch();
    const {customer_group, errors, inProgress} = useSelector(state => ({
        ...state.customer_group
    }), shallowEqual); 



    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        if (id) {
			dispatch(onCustomerGroupEdit(id));
		} else {
			dispatch(onCustomerGroupNew());
		}
        return () => {
            dispatch(onUnloadForm());
        };
    },[dispatch, id, parent_id]);
    
    
    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newCustomerGroup = {...customer_group};
        newCustomerGroup[key] = value;
        dispatch(onUpdateCustomerGroup(newCustomerGroup));
    }

    function onToggleStatus(value, event) {
        const key = event.target.name;
        const newCustomerGroup = {...customer_group};
        newCustomerGroup[key] = value;
        dispatch(onUpdateCustomerGroup(newCustomerGroup));
    }

    function onSubmit() {
		if (id) {
            dispatch(onEdit(id, customer_group));
		} else {
            dispatch(onCreate(customer_group));
		}
    }
    
    function error(key) {
        if (errors != null && errors[`customer_group.${key}`] != null) {
            return errors[`customer_group.${key}`].msg
        }
        return null;
    }

    if (!customer_group) {
        return (
            <Loader />
        )
    }


    const { name, status } = customer_group;

    return (
        <ContentBox>
            <ContentPaper elevation={3}>
                <Grid container spacing={6} direction="column">
                    <Grid item container>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                Informatis Group
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Alert severity="error">
                                Wajib
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={5}>
                        <Grid item container spacing={8}>
                            <Grid item xs={4} >
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Nama Group
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Silahkan masukan nama group pelanggan.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    error={!!error('name')}
                                    name="name"
                                    placeholder="Group Name"
                                    value={name}
                                    helperText={error('name')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4} >
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Status Group
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Status group pelanggan.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={status}
                                            onChange={onToggleStatus.bind(this, !status)}
                                            name="status"
                                            color="primary"
                                        />
                                        }
                                        label={status ? 'Tersedia' : 'Disimpan'}
                                    />
                                </FormGroup>    
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </ContentPaper>
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    disabled={inProgress}
                                    onClick={ () => {navigate(`/customer-groups`)}}>
                                    Batal
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
        </ContentBox>
    )
}

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));

const ContentBox = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));


