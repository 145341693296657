import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../actions/employeeActions';
import {Stepper, 
        Step,
        StepLabel,
        Button,
        StepConnector,
        CircularProgress,
        Box,
        Grid,
        Paper
} from '@mui/material';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import Loader from '../../../../../Helper/Loader';
import clsx from 'clsx';
import { Check } from '@mui/icons-material';
import { useNavigate, useParams } from "react-router-dom";
import Personal from './Personal';
import Detail from './Detail';
import Payroll from './Payroll';


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

function getSteps() {
    return ['Informasi Personal', 'Informasi Karyawan', 'Informasi Pendapatan'];
}
  
function getStepContent(step) {
    switch (step) {
        case 0:
            return <Personal />;
        case 1:
            return <Detail />;
        case 2:
            return <Payroll/>;
        default:
            return 'Unknown step';
    }
}

export default function Form() {
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {employee, employee_organizations, employee_job_positions, employee_statuses, errors, inProgress,} = useSelector(state => ({
        ...state.employee
    }), shallowEqual); 

    useEffect(() => {
      // if (slug) {
      //   // dispatch(productActions.onProductEdit(slug));
      // } else {
        dispatch(employeeActions.onEmployeeNew());
      // }
      return () => {
          dispatch(employeeActions.onUnloadForm())
      };
    },[dispatch]);

    useEffect(() => {
      window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
      });
    },[errors]);

    useEffect(() => {
      if (errors != null) {
        if (errors['email'] != null || 
            errors['employee_profiles.image'] != null ||
            errors['first_name'] != null || 
            errors['last_name'] != null || 
            errors['phone'] != null || 
            errors['employee_identities.image'] != null || 
            errors['identity_number'] != null || 
            errors['date_of_birth'] != null || 
            errors['gender_option_id'] != null || 
            errors['marital_status_id'] != null || 
            errors['blood_type_id'] != null || 
            errors['religion_option_id'] != null || 
            errors['state'] != null || 
            errors['code_area'] != null || 
            errors['address'] != null ) {
            return setActiveStep(0);
        }
        if (errors['store_id'] != null || 
            errors['employee_organization_id'] != null || 
            errors['employee_job_position_id'] != null || 
            errors['employee_status_id'] != null || 
            errors['parent_id'] != null  ) {
            return setActiveStep(1);
        }
      }  
  },[errors]);
    
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const onNext = () => {
      let newErrors = {};

      const { email, 
              employee_profiles,
              first_name,
              last_name,
              phone,
              employee_identities,
              identity_number,
              date_of_birth,
              gender_option_id,
              marital_status_id,
              blood_type_id,
              religion_option_id,
              state,
              city,
              district,
              code_area,
              address,
              store_id,
              employee_organization_id,
              employee_job_position_id,
              employee_status_id,
              employee_payroll,
              parent_id } = employee;

      
      const { basic_salary,
              salary_type_id,
              employee_tax_identities,
              tax_account_number,
              employee_tax_status_id,
              bank_option_id,
              bank_account_number,
              bank_account_holder } = employee_payroll;


      if (activeStep === 0) {


        let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test(email)){
          newErrors.email = ['Email tidak valid']
        } else {
          if (errors && errors.email) {
            delete errors.email;
          }
        }

        if (email === '') {
          newErrors.email = ['Email dibutuhkan']
        } else {
          if (errors && errors.email) {
            delete errors.email;
          }
        }

        if (employee_profiles[0].image === '') {
          newErrors[`employee_profiles.image`] = ['Foto pengguna dibutuhkan']
        } else {
          if (errors && errors[`employee_profiles.image`]) {
            delete errors[`employee_profiles.image`];
          }
        }

        if (first_name === '') {
          newErrors.first_name = ['Nama depan dibutuhkan']
        } else {
          if (errors && errors.first_name) {
            delete errors.first_name;
          }
        }

        if (last_name === '') {
          newErrors.last_name = ['Nama belakang dibutuhkan']
        } else {
          if (errors && errors.last_name) {
            delete errors.last_name;
          }
        }
       
        if (phone === '') {
          newErrors.phone = ['No.hp dibutuhkan']
        } else if (!/^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/.test(phone)) {
          newErrors.phone = ['No.hp tidak valid']
        } else {
          if (errors && errors.phone) {
            delete errors.phone;
          }
        }

        if (employee_identities[0].image === '') {
          newErrors[`employee_identities.image`] = ['Foto identitas dibutuhkan']
        } else {
          if (errors && errors[`employee_identities.image`]) {
            delete errors[`employee_identities.image`];
          }
        }

        if (identity_number === '') {
          newErrors.identity_number = ['No identitas dibutuhkan']
        } else {
          if (errors && errors.identity_number) {
            delete errors.identity_number;
          }
        }

        if (date_of_birth === '') {
          newErrors.date_of_birth = ['Tanggal lahir dibutuhkan']
        } else {
          if (errors && errors.date_of_birth) {
            delete errors.date_of_birth;
          }
        }


        if (gender_option_id === '') {
          newErrors.gender_option_id = ['Jenis kelamin dibutuhkan']
        } else {
          if (errors && errors.gender_option_id) {
            delete errors.gender_option_id;
          }
        }

        if (marital_status_id === '') {
          newErrors.marital_status_id = ['Status perkawinan dibutuhkan']
        } else {
          if (errors && errors.marital_status_id) {
            delete errors.marital_status_id;
          }
        }

        if (blood_type_id === '') {
          newErrors.blood_type_id = ['Golongan darah dibutuhkan']
        } else {
          if (errors && errors.blood_type_id) {
            delete errors.blood_type_id;
          }
        }

        if (religion_option_id === '') {
          newErrors.religion_option_id = ['Kepercayaan dibutuhkan']
        } else {
          if (errors && errors.religion_option_id) {
            delete errors.religion_option_id;
          }
        }

        if (state === '' || city === '' || district === '') {
          newErrors.state = ['Provinsi, kota, kecamatan dibutuhkan']
        } else {
          if (errors && errors.state) {
            delete errors.state;
          }
        }
        
        if (code_area === '') {
          newErrors.code_area = ['Kode pos dibutuhkan']
        } else {
          if (errors && errors.code_area) {
            delete errors.code_area;
          }
        }
        
        if (address === '') {
          newErrors.address = ['Alamat dibutuhkan']
        } else {
          if (errors && errors.address) {
            delete errors.address;
          }
        }
      }

      if (activeStep === 1) {
        if (store_id === '') {
          newErrors.store_id = ['Cabang dibutuhkan']
        } else {
          if (errors && errors.store_id) {
            delete errors.store_id;
          }
        }

        if (employee_organization_id === '') {
          newErrors.employee_organization_id = ['Organisasi karyawan dibutuhkan']
        } else {
          if (errors && errors.employee_organization_id) {
            delete errors.employee_organization_id;
          }
        }

        if (employee_job_position_id === '') {
          newErrors.employee_job_position_id = ['Jabatan karyawan dibutuhkan']
        } else {
          if (errors && errors.employee_job_position_id) {
            delete errors.employee_job_position_id;
          }
        }

        if (employee_status_id === '') {
          newErrors.employee_status_id = ['Status karyawan dibutuhkan']
        } else {
          if (errors && errors.employee_status_id) {
            delete errors.employee_status_id;
          }
        }

        if (employee_status_id === '') {
          newErrors.employee_status_id = ['Status karyawan dibutuhkan']
        } else {
          if (errors && errors.employee_status_id) {
            delete errors.employee_status_id;
          }
        }


        if (employee.employee_organization_id !== '' && employee.employee_job_position_id !== '') {
            const employeeJobPosition = employee_job_positions.find(ep => ep._id === employee.employee_job_position_id);
            if (employeeJobPosition.name.toLowerCase() !== 'ceo' && parent_id === '') {
              newErrors.parent_id = ['Atasan dibutuhkan']
            } else {
              if (errors && errors.parent_id) {
                delete errors.parent_id;
              }
            }
        } else if (employee.parent_id === '') {
          newErrors.parent_id = ['Atasan dibutuhkan']
        } else {
          if (errors && errors.parent_id) {
            delete errors.parent_id;
          }
        }
      }

      if (activeStep === 2) {
        if (basic_salary === '') {
          newErrors['employee_payroll.basic_salary'] = ['Gaji pokok dibutuhkan']
        } else {
          if (errors && errors['employee_payroll.basic_salary'] ) {
            delete errors['employee_payroll.basic_salary'];
          }
        }

        if (salary_type_id === '') {
          newErrors['employee_payroll.salary_type_id'] = ['Jenis gaji dibutuhkan']
        } else {
          if (errors && errors['employee_payroll.salary_type_id'] ) {
            delete errors['employee_payroll.salary_type_id'];
          }
        }

        // if (employee_tax_identities[0].image === '') {
        //   newErrors[`employee_payroll.employee_tax_identities.image`] = ['Foto identitas pajak dibutuhkan']
        // } else {
        //   if (errors && errors[`employee_payroll.employee_tax_identities.image`] ) {
        //     delete errors[`employee_payroll.employee_tax_identities.image`];
        //   }
        // }

        // if (tax_account_number === '') {
        //   newErrors['employee_payroll.tax_account_number'] = ['No.pajak dibutuhkan']
        // } else {
        //   if (errors && errors['employee_payroll.tax_account_number'] ) {
        //     delete errors['employee_payroll.tax_account_number'];
        //   }
        // }

        // if (employee_tax_status_id === '') {
        //   newErrors['employee_payroll.employee_tax_status_id'] = ['Status pajak dibutuhkan']
        // } else {
        //   if (errors && errors['employee_payroll.employee_tax_status_id'] ) {
        //     delete errors['employee_payroll.employee_tax_status_id'];
        //   }
        // }

        // if (bank_option_id === '') {
        //   newErrors['employee_payroll.bank_option_id'] = ['Bank dibutuhkan']
        // } else {
        //   if (errors && errors['employee_payroll.bank_option_id'] ) {
        //     delete errors['employee_payroll.bank_option_id'];
        //   }
        // }

        // if (bank_account_number === '') {
        //   newErrors['employee_payroll.bank_account_number'] = ['No.rekening dibutuhkan']
        // } else {
        //   if (errors && errors['employee_payroll.bank_account_number'] ) {
        //     delete errors['employee_payroll.bank_account_number'];
        //   }
        // }

        // if (bank_account_holder === '') {
        //   newErrors['employee_payroll.bank_account_holder'] = ['Nama pemilik rekening dibutuhkan']
        // } else {
        //   if (errors && errors['employee_payroll.bank_account_holder']) {
        //     delete errors['employee_payroll.bank_account_holder'];
        //   }
        // }

      }

      if (Object.keys(newErrors).length !== 0) {
        dispatch(employeeActions.onValidation(newErrors));
      } else if (activeStep === steps.length - 1) {
        dispatch(employeeActions.onCreate(employee));
      } else {
        dispatch(employeeActions.onValidation(newErrors));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
     
    };

    const onBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    if (!employee) {
      return (
        <Loader />
      )
    }
    return (
      <ContentBox>
        <Grid container spacing={4} direction="column" display="block">
            <Grid item>
                <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
            </Grid>
            <Grid item xs>
              <ContentPaper elevation={3}>
                {getStepContent(activeStep)}
              </ContentPaper>
            </Grid>
        </Grid>
        <Box>
            <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                <Grid item>
                </Grid>
                <Grid item>
                    <Grid container spacing={3}>
                        <Grid item>
                            <BackButton 
                                variant="contained"
                                size="large"
                                disableElevation
                                disabled={inProgress}
                                onClick={activeStep === 0 ? () => {navigate(`/employees`)} : onBack} >
                                {activeStep === 0 ? 'Batal' : 'Kembali'}
                            </BackButton>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained"
                                size="large"
                                color="primary"
                                sx={{minWidth: 195}}
                                onClick={onNext}
                                disabled={inProgress}
                                disableElevation>
                                {inProgress ? <CircularProgress size={26} /> : activeStep === steps.length - 1 ? 'Selesai' : 'Lanjutkan'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>  
        </Box>
    </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
  paddingBottom: 15
}));

const ContentPaper = styled(Paper)(({theme}) => ({
  padding: 35,
  paddingTop: 32,
  marginBottom: 25
}));

const BackButton = styled(Button)(({theme}) => ({
  color: theme.palette.text.primary,
  backgroundColor: "#FFFFFF",
  '&:hover': {
  backgroundColor: "#FFFFFF",
  },
  fontWeight: 500,
  border: '1px solid #ddd',
  minWidth: 195
}));
