import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as inventoryActions from '../../../../../../actions/inventoryActions';
import { TableContainer,
        Grid,
         Table,
         TableHead,
         TableRow,
         TableCell,
         TableBody,
         Typography,
         Box,
         IconButton,
         AppBar,
         Toolbar,
         TableSortLabel,
         TablePagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { Close, FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Loader from '../../../../../Helper/Loader';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import 'moment/locale/id' 
import DataEmpty from '../../../../../Helper/DataEmpty';

const headCells = [
    { id: 'increment_id', numeric: false, sortable: true, disablePadding: false, label: 'ID PO' },
    { id: 'store', numeric: false, sortable: true, disablePadding: false, label: 'Nama Cabang' },
    { id: 'supplier', numeric: false, sortable: true, disablePadding: false, label: 'Nama Supplier' },
    { id: 'sku', numeric: false, sortable: true, disablePadding: false, label: 'SKU' },
    { id: 'unit_price', numeric: true, sortable: true, disablePadding: false, label: 'Harga Pokok' },
    { id: 'discount', numeric: true, sortable: false, disablePadding: false, label: 'Diskon' },
    { id: 'net_price', numeric: true, sortable: true, disablePadding: false, label: 'Harga Netto' },
    { id: 'created_at', numeric: false, sortable: true, disablePadding: false, label: 'Tgl. Buat' },
  ];
 
  
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.sortable) {
                        return (
                            <TableCell
                                key={headCell.id}
                                sx={{fontWeight: 700}}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                    return (
                        <TableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
        <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
        <LastPage />
        </IconButton>
      </Box>
    );
}

export default function Content() {
    const dispatch = useDispatch();
    const { inventory, inventory_histories, purchase_order_histories, purchase_order_histories_count } = useSelector(state => ({
        ...state.inventory
    }), shallowEqual);
    const [chartData, setChartData] = useState(null);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
   
    const [query, setQuery] = useState({ page: 0, limit: 15, search: '', store_id: ''});

    useEffect(() => {
        // dispatch(inventoryActions.onLoadInventoryHistories(inventory.sku, query))
        dispatch(inventoryActions.onLoadPurchaseOrderHistories(inventory.relation_id, query))
    },[]);

   

    const onRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (isAsc) {
          const newQuery = {...query};
          delete newQuery.sort_asc;
          newQuery.sort_desc = property;
          setQuery(newQuery)
        } else {
          const newQuery = {...query};
          delete newQuery.sort_desc;
          newQuery.sort_asc = property;
          setQuery(newQuery)
        }
    };


    const onChangePage = (event, newPage) => {
        const newQuery = {...query};
        newQuery.page = newPage;
        setQuery(newQuery)
    };

    const onChangeRowsPerPage = (event) => {
        const newQuery = {...query};
        newQuery.limit = parseInt(event.target.value, 10);
        newQuery.page = 0;
        setQuery(newQuery)
    };

    function onHide() {
        dispatch(inventoryActions.onHidePurchaseOrderHistories());
    }

    if (!purchase_order_histories || !inventory_histories) {
        return (
            <Loader />
        )
    }


    function Content() {
        const {page, limit} = query;

        if (purchase_order_histories.length === 0) {
            return (
                <DataEmpty />
            )
        }
        return (
            <Box>
                <TableContainer sx={{ overflowY: 'hidden' }}>
                    <Table>
                        <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={onRequestSort}
                        />
                        <TableBody>
                        {
                                purchase_order_histories.map((history, index) => {
                                    const { purchase_order_id, increment_id, supplier, store, sku, created_at, discount_amount, discount_type, discount_percent, net_price, unit_price } = history;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => {
                                                window.open(`/purchase-orders/${purchase_order_id}/edit`);
                                            }}
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <ItemTableCell component="th" scope="row">{increment_id}</ItemTableCell>
                                            <ItemTableCell>{store}</ItemTableCell>
                                            <ItemTableCell>{supplier}</ItemTableCell>
                                            <ItemTableCell>{sku}</ItemTableCell>
                                            <ItemTableCell align="right">
                                                <NumberFormat
                                                    value={unit_price}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix="Rp "
                                                    isNumericString
                                                    decimalScale={2}
                                                    renderText={(value) => <Typography fontSize={"0.875rem"}>
                                                    {value}
                                                    </Typography>}
                                                />
                                            </ItemTableCell>
                                            <ItemTableCell>
                                            <NumberFormat
                                                value={discount_type === 'percent' ? discount_percent : discount_amount}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix={discount_type === 'percent' ? "" : "Rp "}
                                                suffix={discount_type === 'percent' ? "%" : ""}
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography textAlign="right" fontSize={"0.875rem"}>
                                                {value}
                                                </Typography>}
                                            />
                                            </ItemTableCell>
                                            <ItemTableCell align="right">
                                                <NumberFormat
                                                    value={net_price}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix="Rp "
                                                    isNumericString
                                                    decimalScale={2}
                                                    renderText={(value) => <Typography fontSize={"0.875rem"}>
                                                    {value}
                                                    </Typography>}
                                                />
                                            </ItemTableCell>
                                            <ItemTableCell>{moment(created_at).format('LL')}</ItemTableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 25, 35]}
                    component="div"
                    count={purchase_order_histories_count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Box>
        )
    }
    return(
        <Box sx={{height: '100%'}}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onHide} 
                    aria-label="close"
                    >
                    <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {inventory ? inventory.name : "Riwayat Persediaan"}
                    </Typography>
                </Toolbar>
            </AppBar>
            {Content()}
        </Box>
    )  
}


const ItemTableCell = styled(TableCell)(({theme}) => ({
        padding: '15px 16px'
}));
