import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as storePosShiftActions from '../../../../../../../actions/storePosShiftActions';
import { TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    TableSortLabel,
    TablePagination,
    Box,
    Typography,
    Grid,
    Stack,
    Chip,
    IconButton,
    AppBar,
    Toolbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { useParams } from "react-router-dom";
import DataEmpty from '../../../../../../Helper/DataEmpty';
import Loader from '../../../../../../Helper/Loader';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Close, FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import 'moment/locale/id'; 


const headCells = [
    { id: 'increment_id', numeric: false, sortable: true, disablePadding: false, label: 'ID Pesanan' },
    { id: 'store', numeric: false, sortable: false, disablePadding: false, label: 'Cabang' },
    { id: 'order_type', numeric: false, sortable: true, disablePadding: false, label: 'Jenis Transaksi' },
    { id: 'created_at', numeric: false, sortable: true, disablePadding: false, label: 'Tgl. Pesanan' },
    { id: 'grand_total', numeric: true, sortable: true, disablePadding: false, label: 'Jumlah Pesanan' },
    { id: 'status', numeric: true, sortable: true, disablePadding: false, label: 'Status' },
];
 
  
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.sortable) {
                        return (
                            <TableCell
                                key={headCell.id}
                                sx={{fontWeight: 700}}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                    return (
                        <TableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
        <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
        <LastPage />
        </IconButton>
      </Box>
    );
}

export default function Adjuestment() {
    const dispatch = useDispatch();
    let { id } = useParams();
    const { store_pos_shift_orders, store_pos_shift_orders_count } = useSelector(state => ({
        ...state.store_pos_shift
    }), shallowEqual);



    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [query, setQuery] = useState({ page: 0, limit: 25, search: '', store_id: '', status: '', payment_method: '', start_date: moment().startOf('month').format('YYYY[-]MM[-]DD'), end_date: moment().format('YYYY[-]MM[-]DD') });

    useEffect(() => {
        dispatch(storePosShiftActions.onLoadOrders(id, query))
    },[dispatch, query]);


    function onHide() {
        dispatch(storePosShiftActions.onHideStorePosShiftOrders());
    }

    const onRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (isAsc) {
          const newQuery = {...query};
          delete newQuery.sort_asc;
          newQuery.sort_desc = property;
          setQuery(newQuery)
        } else {
          const newQuery = {...query};
          delete newQuery.sort_desc;
          newQuery.sort_asc = property;
          setQuery(newQuery)
        }
    };

    const onSelectStatus = (status, event) => {
        const newQuery = {...query};
        newQuery.status = status;
        setQuery(newQuery)
    };

    const onSelectPayment = (payment, event) => {
        const newQuery = {...query};
        newQuery.payment_method = payment;
        setQuery(newQuery)
    };

    const onChangePage = (event, newPage) => {
        const newQuery = {...query};
        newQuery.page = newPage;
        setQuery(newQuery)
    };



    if (!store_pos_shift_orders) {
        return (
            <Loader />
        )
    }


    function Content() {
        if (store_pos_shift_orders.length === 0) {
            return (
                <DataEmpty />
            )
        }

        const {page, limit} = query;
        return (
            <Box>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}>
                        <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={onRequestSort}
                        />
                        <TableBody>
                        {
                            store_pos_shift_orders.map((order) => {
                                const { _id, increment_id, store, order_type, created_at, grand_total, status } = order;
                                return (
                                    <TableRow
                                        hover
                                        onClick={() => {
                                            window.open(`/orders/${_id}`);
                                        }}
                                        tabIndex={-1}
                                        key={_id}
                                    >
                                        <ItemTableCell component="th" scope="row">{increment_id}</ItemTableCell>
                                        <ItemTableCell >{store}</ItemTableCell>
                                        <ItemTableCell >{order_type}</ItemTableCell>
                                        <ItemTableCell>{moment(created_at).format('LL')}, {moment(created_at).format('LT')} WIB</ItemTableCell>
                                        <ItemTableCell align="right">
                                            <NumberFormat
                                                value={grand_total}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix="Rp "
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography fontSize={"0.875rem"}>
                                                {value}
                                                </Typography>}
                                            />
                                        </ItemTableCell>
                                        <ItemTableCell align="right">{status}</ItemTableCell>
                                    </TableRow>
                                );
                            })
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={store_pos_shift_orders_count}
                    rowsPerPage={limit}
                    page={page}
                    ActionsComponent={TablePaginationActions}
                    onPageChange={onChangePage}
                />
            </Box>
        )
    }
    
    return(
        <ContentBox>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onHide} 
                    aria-label="close"
                    >
                    <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Riwayat Pesanan
                    </Typography>
                </Toolbar>
            </AppBar>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
                <Grid container direction="column">
                    <Grid item>
                        <Box sx={{padding: '25px 15px 15px 15px'}}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item>
                                    <Typography fontWeight={700} variant="body1">
                                        Status
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Stack direction="row" spacing={1}>
                                        <Chip label="Semua" variant="outlined" color={query.status === "" ? "primary" : "default"} onClick={onSelectStatus.bind(this, '')} />
                                        <Chip label="Diproses" variant="outlined" color={query.status === "processing" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'processing')}/>
                                        <Chip label="Jatuh Tempo" variant="outlined" color={query.status === "on_due" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'on_due')}/>
                                        <Chip label="Dibayar Sebagian" variant="outlined" color={query.status === "partially_paid" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'partially_paid')}/>
                                        <Chip label="Dibayar" variant="outlined" color={query.status === "paid" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'paid')}/>
                                        <Chip label="Selesai" variant="outlined" color={query.status === "completed" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'completed')}/>
                                        <Chip label="Dibatalkan" variant="outlined" color={query.status === "canceled" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'canceled')}/>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{padding: '0px 15px 15px 15px'}}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item>
                                    <Typography fontWeight={700} variant="body1">
                                        Pembayaran
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Stack direction="row" spacing={1}>
                                        <Chip label="Semua" variant="outlined" color={query.payment_method === "" ? "primary" : "default"} onClick={onSelectPayment.bind(this, '')} />
                                        <Chip label="Tagihan" variant="outlined" color={query.payment_method === "invoice" ? "primary" : "default"} onClick={onSelectPayment.bind(this, 'invoice')}/>
                                        <Chip label="Tunai" variant="outlined" color={query.payment_method === "cash" ? "primary" : "default"} onClick={onSelectPayment.bind(this, 'cash')}/>
                                        <Chip label="EDC" variant="outlined" color={query.payment_method === "edc" ? "primary" : "default"} onClick={onSelectPayment.bind(this, 'edc')}/>
                                        <Chip label="Kupon" variant="outlined" color={query.payment_method === "gift_card" ? "primary" : "default"} onClick={onSelectPayment.bind(this, 'gift_card')}/>
                                        <Chip label="Lainnya" variant="outlined" color={query.payment_method === "other" ? "primary" : "default"} onClick={onSelectPayment.bind(this, 'other')}/>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        {Content()}
                    </Grid>
                </Grid>
                
            </Paper>
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '0px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));


const ItemTableCell = styled(TableCell)(({theme}) => ({
    padding: '15px 16px'
}));
