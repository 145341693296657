import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderDeliveryReturnActions from '../../../../../../actions/orderDeliveryReturnActions';
import { 
        Typography,
        Box,
        Grid,
        Paper,
        Tooltip,
        TextField,
        Button,
        InputBase
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../../Helper/Loader';
import { tooltipClasses } from '@mui/material/Tooltip';
import { keyframes } from '@emotion/react'
import NumberFormat from 'react-number-format';
import 'moment/locale/id';
import Confirm from './Confirm';
import Information from './Information';
import Product from './Product';

const QuantityFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        disabled={props.disabled}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
        InputProps={props.InputProps}
      />
));

export default function Show() {    
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {order_delivery_return, errors} = useSelector(state => ({
        ...state.order_delivery_return
    }), shallowEqual); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(orderDeliveryReturnActions.onLoadOrderDeliveryReturn(id));
        return () => {
            dispatch(orderDeliveryReturnActions.onUnloadOrderDeliveryReturn())
        };
    },[]);

    function onSubmit() {
        const { status } = order_delivery_return;
        if (status === 'processing') {
            dispatch(orderDeliveryReturnActions.onShowConfirm("returned"))
        }
    }

    function error(key) {
        if (errors != null && errors[`order_delivery_return.${key}`] != null) {
            return errors[`order_delivery_return.${key}`].msg
        }
        return null;
    }

   
    if (!order_delivery_return) {
        return (
            <Loader />
        )
    }
    const { status
    } = order_delivery_return;

    function Cancel() {
        if (status === 'processing') {
            return (
                <Button 
                    variant="contained"
                    size="large"
                    disableElevation
                    onClick={ () => {dispatch(orderDeliveryReturnActions.onShowConfirm("canceled"))}}
                    >
                    Batalkan
                </Button>
            )
        }

        return null;
    }

    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Informasi Pengembalian
                </Typography>
            </HeaderBox>
            <Information error={error} />
            <Product error={error} />
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                        {Cancel()}
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    onClick={ () => {navigate(`/order-delivery-returns`)}}>
                                    Kembali
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={status === 'delivered' || status === 'completed'}
                                    disableElevation>
                                    Simpan
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
            <Confirm />
        </ContentBox>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    marginBottom: 25
}));

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
    padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
    padding: '15px 15px 0 15px',
    },
    height: '100%'
}));


const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const Content = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));

const Slash = styled(Typography)(({theme}) => ({
    margin: '0 6px',
    color: 'var(--N75, #E5E7E9)'
}));


const TimelineBox = styled(Box)(({theme}) => ({
    display: 'flex',
    minHeight: 60
 }));

const DateBox = styled(Box)(({theme}) => ({
    width: '20%',
    textAlign: 'right'
}));

const LineWrapperBox = styled(Box)(({theme}) => ({
   margin: '5px 16px',
   alignItems: 'center',
   flexDirection: 'column',
   display: 'flex'
}));

const grow = keyframes`
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(2);
    }
`;

const CurrentCircleBox = styled(Box)(({theme}) => ({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: 'auto',
    '&:before': {
        width: 15,
        position: 'absolute',
        height: 15,
        borderRadius: '50%',
        backgroundColor: `var(--G300,#4B00D1)`,
        animationName:  `${grow}`,
        animationDuration: '1400ms',
        animationTimingFunction: 'ease-out',
        animationIterationCount: 'infinite',
        animationFillMode: 'none',
        animationPlayState: 'running',
        content: '""'
    }, 
    '&:after': {
        borderRadius: '50%',
        backgroundColor: 'var(--G500,#6200EE)',
        content: '""',
        position: 'absolute',
        width: 11,
        height: 11,
        left: 2,
        top: 2
    },
 }));


const CircleBox = styled(Box)(({theme}) => ({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: 'auto',
    backgroundColor: 'var(--N100,#DBDEE2)',
    '&:after': {
        borderRadius: '50%',
        backgroundColor: 'var(--N200,#9FA6B0)',
        content: '""',
        position: 'absolute',
        width: '9px',
        height: '9px',
        left: '3px',
        top: '3px'
    },
 }));

const LineBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "last",
  })(({ theme, last }) => ({
    display: last ? 'none' : 'block',
    width: 1,
    height: 'calc(100% - 9px)',
    marginBottom: -6,
    backgroundImage: 'linear-gradient(to bottom,var(--N100,#DBDEE2) 55%,rgba(255,255,255,0) 55%)',
    backgroundPosition: 'left center',
    backgroundSize: '1px 12px',
    backgroundRepeat: 'repeat-y'
 }));

 const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} sx={{marginLeft: '5px'}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      width: 325,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    },
  }));


const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));


const CommentField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));

const IconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));