import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderReturnActions from '../../../../../../actions/purchaseOrderReturnActions';
import { 
        Typography,
        Box,
        Grid,
        Paper,
        Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Information from './Information';
import Product from './Product';
import Confirm from './Confirm';
import Loader from '../../../../../Helper/Loader';


export default function Edit() { 
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {purchase_order_return, errors} = useSelector(state => ({
        ...state.purchase_order_return
    }), shallowEqual); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(purchaseOrderReturnActions.onEdit(id));
        return () => {
            dispatch(purchaseOrderReturnActions.onUnloadPurchaseOrderReturn())
        };
    },[dispatch, id]);

    function onSubmit() {
        const { status } = purchase_order_return;
        if (status === 'requested') {
            dispatch(purchaseOrderReturnActions.onShowConfirm("approved"))
        }

        if (status === 'approved') {
            dispatch(purchaseOrderReturnActions.onShowConfirm("returned"))
        }
    }

   
    if (!purchase_order_return) {
        return (
            <Loader />
        )
    }

    const { status } = purchase_order_return;

    function Cancel() {
        if (status === 'requested') {
            return (
                <Button 
                    variant="contained"
                    size="large"
                    disableElevation
                    onClick={ () => {dispatch(purchaseOrderReturnActions.onShowConfirm("canceled"))}}>
                    Batalkan
                </Button>
            )
        }

        return null;
    }

    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Detail Pengembalian
                </Typography>
            </HeaderBox>
            <Information />
            <Product />
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                        {Cancel()}
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    onClick={ () => {navigate(`/purchase-order-returns`)}}>
                                    Kembali
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={status === 'canceled' || status === 'returned'}
                                    disableElevation>
                                    Simpan
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
            <Confirm />
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));

const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));