import React, { useRef, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderReturnActions from '../../../../../../../actions/purchaseOrderReturnActions';
import { 
        Button,
        Typography,
        Paper,
        Box,
        Grid,
        TextField,
        Autocomplete,
        Alert,
        Tooltip,
        CircularProgress,
        InputAdornment,
        Table,
        TableHead,
        TableBody,
        TableRow,
        TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {HelpCenterTwoTone} from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import Variant from './Variant';


const QuantityFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        error={props.error}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        decimalScale={2}
        isNumericString
        InputProps={{
            ...props.InputProps, // Merge existing InputProps from props
            // startAdornment: (
            //   <InputAdornment position="start">
            //     <Box fontWeight={700}>Rp</Box>
            //   </InputAdornment>
            // ),
            inputProps: {
              ...props.InputProps?.inputProps, // Merge existing inputProps if present
              style: {
                ...(props.InputProps?.inputProps?.style || {}), // Merge existing styles
                textAlign: 'right', // Add or override the text alignment
              },
            },
        }}
      />
));

function ProductItem({options, item, onChange, onRemove, index, error, titleAlign}) {
    const [open, setOpen] = React.useState(false);

    const productOption = options.find(product => product.sku === item.sku);
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };

    function itemOptions(options) {
        return (
            <Box>
                <Typography fontWeight={700}>
                    Produk Opsi
                </Typography>
                {options.map((option, index) => {
                    return (
                        <Box key={index} sx={{display: 'flex'}}>
                            <Typography fontWeight={500}>
                                {option.name} 
                            </Typography>
                            <Typography sx={{marginLeft: '5px'}}>
                                {option.unit ? `(${option.unit})` : null}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    function hasOptions() {

        if (productOption && productOption.product_options) {
            var optionsKeys = [];
            productOption.product_options.sort((a, b) => {
                const nameA = a.sort_order;
                const nameB = b.sort_order;
                if (nameA < nameB) {
                return -1;
                }
                if (nameA > nameB) {
                return 1;
                }
            
                // names must be equal
                return 0;
            }).map((option, index) => {
                if (!optionsKeys.includes(option.uom.toLowerCase())) {
                    optionsKeys.push(option.uom.toLowerCase());
                }
            })

            var options = [];
            optionsKeys.map((key, index)  => {
                const option = productOption.product_options.find(opt => opt.sort_order === index);
                if (index === 0) {
                    options.push({name: option.uom, unit: ""});
                } else {
                    const firstOption = productOption.product_options.find(opt => opt.sort_order === 0);
                    options.push({name: option.uom, unit: option.unit + " " + firstOption.uom});
                }
            })

            if (options.length >= 2) {
                return (
                    <Tooltip sx={{position: 'absolute', right: 10, top: 32}} open={open} onClose={handleClose} onOpen={handleOpen} title={itemOptions(options)}>
                        <HelpCenterTwoTone color="primary" />
                    </Tooltip>
                )
            }
            
            return null;
        }

        return null;
    }
    return (
        <TableRow sx={{display: 'contents'}}>
            <TableCell sx={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
                zIndex: 1,
                border: 0,
                left: 0,
                background: '#fff',
                }}>
                <Autocomplete
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    blurOnSelect
                    freeSolo
                    disabled
                    fullWidth
                    inputValue={item.name}
                    disableClearable
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
                {hasOptions()}
            </TableCell>
            <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                <TextField
                    fullWidth
                    value={item.in_stock}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                            style: {
                                textAlign: 'right', // Align text to the right
                            },
                        },
                    }}
                />
            </TableCell>
            <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                <QuantityFormat
                    fullWidth
                    error={!!error(`purchase_order_return_items[${index}].requested_qty`)}
                    helperText={error(`purchase_order_return_items[${index}].requested_qty`)}
                    name="requested_qty"
                    value={item.requested_qty}
                    variant="outlined"
                    onChange={onChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                            style: {
                                textAlign: 'right', // Align text to the right
                            },
                        },
                    }}
                    />
            </TableCell>
            <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                <TextField
                    fullWidth
                    value={Number(item.in_stock) - Number(item.requested_qty)}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                            style: {
                                textAlign: 'right', // Align text to the right
                            },
                        },
                    }}
                />
            </TableCell>
            <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                <ClearButton 
                    variant="contained"
                    size="large"
                    disableElevation
                    fullWidth
                    onClick={onRemove}
                    >
                    Hapus
                </ClearButton>
            </TableCell>
        </TableRow>
    )
}

export default function Product() {
    const selectRef = useRef();
    const dispatch = useDispatch();
    const {purchase_order_return, products, initial_products, inventories, errors, selectedProduct, productsFetching} = useSelector(state => ({
        ...state.purchase_order_return
    }), shallowEqual); 

    const { purchase_order_id, purchase_order_return_items } = purchase_order_return;

    useEffect(() => {
        if (selectedProduct) {
            if (selectedProduct.product_variants) {
                dispatch(purchaseOrderReturnActions.onHandleVariants(selectedProduct._id));
            } else if (selectedProduct.product_options) {
                const inventory_product = inventories.find(i => i.sku.toString() === selectedProduct.sku.toString());
                let newOrderReturnItems = [...purchase_order_return_items];
                const primaryOption = selectedProduct.product_options.find(opt => Number(opt.sort_order) === 0);
                if (inventory_product) {
                    const in_stock = inventory_product.in_stock;
                    if (purchase_order_return_items[0].product_id && purchase_order_return_items.length >= 1) {
                        newOrderReturnItems = [...newOrderReturnItems, {product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: primaryOption.uom, in_stock, requested_qty: 0}]
                    } else {
                        newOrderReturnItems[0] = { ...newOrderReturnItems[0], product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: primaryOption.uom, in_stock, requested_qty: 0 }
                    }
                } else {
                    if (purchase_order_return_items[0].product_id && purchase_order_return_items.length >= 1) {
                        newOrderReturnItems = [...newOrderReturnItems, {product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: primaryOption.uom, in_stock: 0, requested_qty: 0 }]
                    } else {
                        newOrderReturnItems[0] = { ...newOrderReturnItems[0], product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: primaryOption.uom, in_stock: 0, requested_qty: 0 }
                    }
                }
                dispatch(purchaseOrderReturnActions.onSelectedProductOptions(newOrderReturnItems, selectedProduct._id));
            } else {
                const inventory_product = inventories.find(i => i.sku.toString() === selectedProduct.sku.toString());
                let newOrderReturnItems = [...purchase_order_return_items];
                if (inventory_product) {
                    const in_stock = inventory_product.in_stock;
                    if (purchase_order_return_items[0].product_id && purchase_order_return_items.length >= 1) {
                        newOrderReturnItems = [...newOrderReturnItems, {product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: selectedProduct.uom, in_stock, requested_qty: 0}]
                    } else {
                        newOrderReturnItems[0] = { ...newOrderReturnItems[0], product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: selectedProduct.uom, in_stock, requested_qty: 0 }
                    }
                } else {
                    if (purchase_order_return_items[0].product_id && purchase_order_return_items.length >= 1) {
                        newOrderReturnItems = [...newOrderReturnItems, {product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: selectedProduct.uom, in_stock: 0, requested_qty: 0 }]
                    } else {
                        newOrderReturnItems[0] = { ...newOrderReturnItems[0], product_id: selectedProduct._id, name: selectedProduct.name, sku: selectedProduct.sku, uom: selectedProduct.uom, in_stock: 0, requested_qty: 0 }
                    }
                }
                dispatch(purchaseOrderReturnActions.onSelectedProductOptions(newOrderReturnItems, selectedProduct._id));
            }
        }
    },[selectedProduct, inventories, purchase_order_return_items]);

    function onSelectProduct(value) {
        const product = products.find(item => item._id === value);
        if (product.loaded) {
            if (product.product_variants) {
                dispatch(purchaseOrderReturnActions.onHandleVariants(value));
            } else if (product.product_options) {
                const inventory_product = inventories.find(i => i.sku.toString() === product.sku.toString());
                let newOrderReturnItems = [...purchase_order_return_items];
                const primaryOption = product.product_options.find(opt => Number(opt.sort_order) === 0);
                if (inventory_product) {
                    const in_stock = inventory_product.in_stock;
                    if (purchase_order_return_items[0].product_id && purchase_order_return_items.length >= 1) {
                        newOrderReturnItems = [...newOrderReturnItems, {product_id: value, name: product.name, sku: product.sku, uom: primaryOption.uom, in_stock, requested_qty: 0}]
                    } else {
                        newOrderReturnItems[0] = { ...newOrderReturnItems[0], product_id: value, name: product.name, sku: product.sku, uom: primaryOption.uom, in_stock, requested_qty: 0 }
                    }
                } else {
                    if (purchase_order_return_items[0].product_id && purchase_order_return_items.length >= 1) {
                        newOrderReturnItems = [...newOrderReturnItems, {product_id: value, name: product.name, sku: product.sku, uom: primaryOption.uom, in_stock: 0, requested_qty: 0 }]
                    } else {
                        newOrderReturnItems[0] = { ...newOrderReturnItems[0], product_id: value, name: product.name, sku: product.sku, uom: primaryOption.uom, in_stock: 0, requested_qty: 0 }
                    }
                }
                dispatch(purchaseOrderReturnActions.onSelectedProductOptions(newOrderReturnItems, value));
            } else {
                const inventory_product = inventories.find(i => i.sku.toString() === product.sku.toString());
                let newOrderReturnItems = [...purchase_order_return_items];
                if (inventory_product) {
                    const in_stock = inventory_product.in_stock;
                    if (purchase_order_return_items[0].product_id && purchase_order_return_items.length >= 1) {
                        newOrderReturnItems = [...newOrderReturnItems, {product_id: value, name: product.name, sku: product.sku, uom: product.uom, in_stock, requested_qty: 0}]
                    } else {
                        newOrderReturnItems[0] = { ...newOrderReturnItems[0], product_id: value, name: product.name, sku: product.sku, uom: product.uom, in_stock, requested_qty: 0 }
                    }
                } else {
                    if (purchase_order_return_items[0].product_id && purchase_order_return_items.length >= 1) {
                        newOrderReturnItems = [...newOrderReturnItems, {product_id: value, name: product.name, sku: product.sku, uom: product.uom, in_stock: 0, requested_qty: 0 }]
                    } else {
                        newOrderReturnItems[0] = { ...newOrderReturnItems[0], product_id: value, name: product.name, sku: product.sku, uom: product.uom, in_stock: 0, requested_qty: 0 }
                    }
                }
                dispatch(purchaseOrderReturnActions.onSelectedProductOptions(newOrderReturnItems, value));
            }
        } else {
            dispatch(purchaseOrderReturnActions.onSelectProduct(value, purchase_order_id));
        }
		
        
    }

    function onUpdateField(index, event) {
		const key = event.target.name;
        const value = event.target.value;
        const newPurchaseOrderReturn = {...purchase_order_return};
        newPurchaseOrderReturn.purchase_order_return_items[index][key] = value;
        dispatch(purchaseOrderReturnActions.onUpdatePurchaseOrderReturn(newPurchaseOrderReturn));
    }

    function onRemoveProduct(item, index) {
        const newPurchaseOrderReturn = {...purchase_order_return};
        newPurchaseOrderReturn.purchase_order_return_items.splice(index, 1)
    	if (item && item.product_id) {
            dispatch(purchaseOrderReturnActions.onRestoreProductOptions(products, item.product_id, item.sku));
    	}
    	if (purchase_order_return_items.length === 0) {
    		newPurchaseOrderReturn.purchase_order_return_items = [...newPurchaseOrderReturn.purchase_order_return_items, { product_id: '', name: '', sku: '', in_stock: 0, requested_qty: 0 }]
    		dispatch(purchaseOrderReturnActions.onUpdatePurchaseOrderReturn(newPurchaseOrderReturn));
    	}
    }

    function error(key) {
        if (errors != null && errors[`purchase_order_return.${key}`] != null) {
            return errors[`purchase_order_return.${key}`].msg
        }
        return null;
    }


    const options = products.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });

    const initialOptions = initial_products.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });
   

    let titleAlign = 'right'
    let tableGrid = '45% 15% 15% 15% 10%'
    return (
         <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Produk
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Alert severity="error">
                            Dibutuhkan
                        </Alert>
                    </Grid>
                </Grid>
            </Grid>
            <Table sx={{
                marginTop: '35px',
                display: 'grid',
                overflow: 'auto hidden',
                gridTemplateColumns: tableGrid,
                }}>
                <TableHead sx={{display: 'contents'}}>
                    <TableRow sx={{display: 'contents'}}>
                        <TableCell sx={{
                            paddingLeft: 0,
                            zIndex: 1,
                            border: 0,
                            left: 0,
                            background: '#fff',
                            }}>
                            Nama Barang
                        </TableCell>
                        <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                            Stok Awal
                        </TableCell>
                        <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                            Qty Pembelian
                        </TableCell>
                        <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                            Stok Akhir (Est.)
                        </TableCell>
                        <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{display: 'contents'}}>
                    {
                        purchase_order_return_items.map((item, index) => {
                            if (item.product_id) {
                                return (
                                    <ProductItem
                                        key={index}
                                        options={initialOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                        item={item}
                                        onChange={onUpdateField.bind(this, index)}
                                        onRemove={onRemoveProduct.bind(this, item, index)}
                                        index={index}
                                        error={error}
                                        titleAlign={titleAlign}
                                        />
                                )
                            }
                            return null;
                        })
                    }
                    <TableRow sx={{display: 'contents'}}>
                        <TableCell sx={{border: 0, paddingLeft: 0,paddingRight: 0, paddingBottom: 0}}>
                            <Autocomplete
                                options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                groupBy={(option) => option.firstLetter}
                                getOptionLabel={(option) => option.name}
                                blurOnSelect
                                disabled={productsFetching}
                                fullWidth
                                value={null}
                                disableClearable
                                getOptionDisabled={(option) =>
                                    !option.status
                                }
                                onChange={(event, option) => {
                                    onSelectProduct(option._id)
                                }}
                                renderInput={(params) => <TextField {...params} 
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {productsFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                error={!purchase_order_return_items[0].product_id && !!error(`purchase_order_return_items[0].product_id`)} helperText={!purchase_order_return_items[0].product_id && error(`purchase_order_return_items[0].product_id`)} ref={selectRef} placeholder="Pilih Produk" variant="outlined" />}
                            />
                        </TableCell>
                        <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                            <TextField
                                fullWidth
                                name="in_stock"
                                value={0}
                                disabled
                                variant="outlined"
                                InputProps={{
                                    inputProps: {
                                    style: {
                                        textAlign: 'right', // Align text to the right
                                    },
                                    },
                                }}
                            />
                        </TableCell>
                        <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                            <TextField
                                fullWidth
                                name="in_stock"
                                value={0}
                                disabled
                                error={!purchase_order_return_items[0].product_id && !!error(`purchase_order_return_items[0].requested_qty`)}
                                helperText={!purchase_order_return_items[0].product_id && error(`purchase_order_return_items[0].requested_qty`)}
                                variant="outlined"
                                InputProps={{
                                    inputProps: {
                                    style: {
                                        textAlign: 'right', // Align text to the right
                                    },
                                    },
                                }}
                            />
                        </TableCell>
                        <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                            <TextField
                                fullWidth
                                name="in_stock"
                                value={0}
                                disabled
                                variant="outlined"
                                InputProps={{
                                    inputProps: {
                                    style: {
                                        textAlign: 'right', // Align text to the right
                                    },
                                    },
                                }}
                            />
                        </TableCell>
                        <TableCell sx={{border: 0, paddingRight: 0, paddingBottom: 0}} align={titleAlign}>
                            <ClearButton 
                                variant="contained"
                                size="large"
                                disableElevation
                                disabled
                                fullWidth
                                >
                                Hapus
                            </ClearButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Variant/>
        </ContentPaper>
    );
}


const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const ClearButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`,
    height: 56
}));
