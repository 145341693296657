import React, {useState} from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText, 
    Typography, 
    Box, 
    ListItemAvatar, 
    Avatar, 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import { DashboardRounded } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import logo from '../../../../assets/images/logo.png';
import { isAuth } from '../Authorization';

const current_access = {
    roles: ['can_view_catgories']
  };
  

export default function MenuNav({setOpen, setClose, setMobileClose}) {
    let navigate = useNavigate();
    const { currentMenu, current_user } = useSelector(state => ({
        ...state.common
    }), shallowEqual);

 
    const { permissions, type } = current_user;
    const [menuList] = useState([
        // {
        //     "navName":"Sales",
        //     "navType":"menu",
        //     "navIcon":"assignment_rounded",
        //     "navItems": [
        //         {
        //             "name":"Orders",
        //             "menuIcon":"",
        //             "submenu": [{
        //                 "name":"Orders",
        //                 "pathUrl":"/orders",
        //             }]
        //         }
        //     ]
        // },
        {
            "navName":"Produk",
            "navType":"menu",
            "navIcon":"developer_board_rounded",
            "navItems": [
                {
                    "name":"Listing",
                    "menuIcon":"",
                    "submenu": [{
                        "name":"Katalog Produk",
                        "pathUrl":"/products",
                        "auth": (type === "user") || (type === "employee" && isAuth(permissions, ['create_product', 'edit_product']))
                    },
                    {
                        "name":"Ketegori Produk",
                        "pathUrl":"/categories",
                        // "auth": isAuth(current_access, ['can_view_categories'])
                        "auth": true

                    }]
                },
            ]
        },
        {
            "navName":"Pelanggan",
            "navType":"menu",
            "navIcon":"supervisor_account_rounded",
            "navItems": [
                {
                    "name":"Pelanggan",
                    "menuIcon":"",
                    "submenu": [{
                        "name":"Semua Pelanggan",
                        "pathUrl":"/customers",
                        "auth": true
                    },{
                        "name":"Grup Pelanggan",
                        "pathUrl":"/customer-groups",
                        "auth": true
                    }]
                },
            ]
        },
        {
            "navName":"Organisasi",
            "navType":"menu",
            "navIcon":"contacts_rounded",
            "navItems": [
                {
                    "name":"Karyawan",
                    "menuIcon":"",
                    "submenu": [{
                        "name":"Semua Karyawan",
                        "pathUrl":"/employees",
                        "auth": true
                    }]
                },
                {
                    "name":"Lainnya",
                    "menuIcon":"",
                    "submenu": [{
                        "name":"Organisasi",
                        "pathUrl":"/organizations",
                        "auth": true
                    },{
                        "name":"Jabatan",
                        "pathUrl":"/job-positions",
                        "auth": true
                    }]
                },
                {
                    "name":"Akses Karyawan",
                    "menuIcon":"",
                    "submenu": [{
                        "name":"Akses",
                        "pathUrl":"/roles",
                        "auth": true
                    }]
                }
            ]
        },
        // {
            // "navName":"Organizations",
            // "navType":"menu",
            // "navIcon":"contacts_rounded",
        //     "navItems": [
        //         {
        //             "name":"Employees",
        //             "menuIcon":"",
                    // "submenu": [{
                    //     "name":"All Employees",
                    //     "pathUrl":"/employees",
                    // }]
        //         },
        //         {
        //             "name":"Others",
        //             "menuIcon":"",
                    // "submenu": [{
                    //     "name":"Organizations",
                    //     "pathUrl":"/organizations",
                    // },{
                    //     "name":"Job Positions",
                    //     "pathUrl":"/job-positions",
                    // }]
        //         },
        //     ]
        // },
        {
            "navName":"Pemasaran",
            "navType":"menu",
            "navIcon":"loyalty_rounded",
            "navItems": [
                {
                    "name":"Konten",
                    "menuIcon":"",
                    "submenu": [{
                        "name":"Tampilan Pelanggan",
                        "pathUrl":"/customer-displays",
                        "auth": true
                    }]
                },
            ]
        },
        {
            "navName":"Cabang",
            "navType":"menu",
            "navIcon":"account_tree_rounded",
            "navItems": [
                {
                    "name":"Persediaan",
                    "menuIcon":"",
                    "submenu": [{
                        "name":"Persediaan",
                        "pathUrl":"/inventories",
                        "auth": true
                    },{
                        "name":"Pembelian",
                        "pathUrl":"/purchase-orders",
                        "auth": true
                    },{
                        "name":"Retur Pembelian",
                        "pathUrl":"/purchase-order-returns",
                        "auth": true
                    },{
                        "name":"Transfer Stok",
                        "pathUrl":"/store-transfers",
                        "auth": true
                    },{
                        "name":"Penyesuaian",
                        "pathUrl":"/store-adjustments",
                        "auth": true
                    }]
                },
                {
                    "name":"Riwayat",
                    "menuIcon":"",
                    "submenu": [{
                        "name":"Pesanan",
                        "pathUrl":"/orders",
                        "auth": true
                    },
                    {
                        "name":"Pengiriman",
                        "pathUrl":"/order-deliveries",
                        "auth": true
                    },
                    {
                        "name":"Retur Pengiriman",
                        "pathUrl":"/order-delivery-returns",
                        "auth": true
                    },
                    {
                        "name":"Pembatalan",
                        "pathUrl":"/order-canceleds",
                        "auth": true
                    },
                    {
                        "name":"Activity",
                        "pathUrl":"/store-activities",
                    },
                    {
                        "name":"Shift Kasir",
                        "pathUrl":"/store-pos-shifts",
                        "auth": true
                    }]
                },
                {
                    "name":"Supplier",
                    "menuIcon":"",
                    "submenu": [{
                        "name":"Supplier",
                        "pathUrl":"/suppliers",
                        "auth": true
                    }]
                },
                {
                    "name":"Cabang",
                    "menuIcon":"",
                    "submenu": [{
                        "name":"Cabang",
                        "pathUrl":"/stores",
                        "auth": true
                    },
                    // {
                    //     "name":"Store Users",
                    //     "pathUrl":"/store-users",
                    // }
                ]
                },
                
            ]
        },
        
    ])

    function onSetClose() {
        setClose();
        if (setMobileClose) {
            setMobileClose();
        }
        
    }

    return (
        <NavList>
            {   console.log(current_user)}
            <NavListItem button onClick={(e) => {
                    onSetClose();
                    navigate('');
                }} >
                <HeaderListItemAvatar >
                    <LogoAvatar variant="square" src={logo} />
                </HeaderListItemAvatar>
            </NavListItem>
            <NavListItem button onClick={(e) => {
                    onSetClose();
                    navigate('');
                }} >
                <NavListItemIcon>
                    <DashboardRounded />
                </NavListItemIcon>
                <ListItemText 
                    primary={
                    <NavTypography component="div">
                        <Box fontWeight={500} fontSize={12} >
                            Dashboard
                        </Box>
                    </NavTypography>}
                />
            </NavListItem>
            {
                menuList.map((menu, index) => {
                    return (
                        <NavListItem key={index} button onClick={(e) => {
                            currentMenu.navName === menu.navName ? 
                                setClose() :
                                window.setTimeout(() => {
                                    setOpen(menu)
                                }, 300);
                                setClose();
                            }} >
                            <NavListItemIcon sx={currentMenu.navName === menu.navName ? {color: "#bb8bff"} : {color: "#FFF"}}>
                                <Icon>
                                    {menu.navIcon}
                                </Icon>
                            </NavListItemIcon>
                            <ListItemText 
                                primary={
                                <NavTypography fontSize={12} fontWeight={500} component="div" sx={currentMenu.navName === menu.navName ? {color: "#bb8bff"} : {color: "#FFF"}}>
                                    {menu.navName}
                                </NavTypography>}
                            />
                        </NavListItem>
                    )
                })
            }
        </NavList>
    )
};

const NavList = styled(List)(({theme}) => ({
    position: 'relative',
    zIndex: '9999',
    color: '#ffffff'
}));

const NavListItem = styled(ListItem)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}));

const HeaderListItemAvatar = styled(ListItemAvatar)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    color: '#ffffff',
    marginTop: 10,
    marginBottom: '25px'
}));

const LogoAvatar = styled(Avatar)(({theme}) => ({
    height: '100% !important',
    width: '100% !important',
}));

const NavListItemIcon = styled(ListItemIcon)(({theme}) => ({
    justifyContent: 'center',
    color: '#ffffff'
}));

const NavTypography = styled(Typography)(({theme}) => ({
    color: '#ffffff'
}));
