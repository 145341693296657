import React, { useEffect } from 'react';
import * as userConfigurationActions from '../../../../../actions/userConfigurationActions';
import { 
    Autocomplete,
    Grid,
    FormControlLabel,
    CircularProgress,
    Button,
    Typography,
    Box,
    TextField,
    InputAdornment,
    Switch,
    FormGroup,
    MenuItem
} from '@mui/material';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import Loader from '../../../../Helper/Loader';


  
export default function Driver() {
    const dispatch = useDispatch();
    const { current_user, user_configuration, employee_organizations, employee_job_positions, inProgress, errors } = useSelector(state => ({
        ...state.common,
        ...state.user_configuration
      }), shallowEqual); 

      useEffect(() => {
        dispatch(userConfigurationActions.onLoadUserDelivery(current_user._id));
        return () => {
            dispatch(userConfigurationActions.onUnloadUserConfiguration());
        };
    },[]);


    function onSelectEmployeeOrganization(option) {
        const newUserConfiguration = {...user_configuration};
        newUserConfiguration.user_configuration_delivery.employee_organization_id = option._id;
        dispatch(userConfigurationActions.onSelectEmployeeOrganization(newUserConfiguration));
    }

    function onSelectDriverPosition(option) {
        const newUserConfiguration = {...user_configuration};
        newUserConfiguration.user_configuration_delivery.driver_position_id = option._id;
        dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    }

    function onSelectAssistantPosition(option) {
        const newUserConfiguration = {...user_configuration};
        newUserConfiguration.user_configuration_delivery.assistant_position_id = option._id;
        dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    }

    function onEdit() {
        dispatch(userConfigurationActions.onEditUserConfigurationDelivery(user_configuration._id, user_configuration));
    }

    function error(key) {
        if (errors != null && errors[`user_configuration.user_configuration_delivery.${key}`] != null) {
            return errors[`user_configuration.user_configuration_delivery.${key}`].msg
        }
        return null;
    }

    if (!user_configuration || !user_configuration.user_configuration_delivery || !employee_organizations || !employee_job_positions) {
        return (
            <Loader />
        )
    }

    const { user_configuration_delivery } = user_configuration;
    const { employee_organization_id, driver_position_id, assistant_position_id } = user_configuration_delivery;
    const employeeOrganizationValue = employee_organizations.find(opt => opt._id === employee_organization_id)
    const driverPositionValue = employee_job_positions.find(opt => opt._id === driver_position_id)
    const assistantPositionValue = employee_job_positions.find(opt => opt._id === assistant_position_id)
    return (
        <Grid container spacing={4} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} component="h6" lineheight="normal">
                        Pengaturan Pengiriman
                    </Typography>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Organisasi
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih organisasi yang menangani pengiriman.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={employee_organizations.sort((a, b) => -b.name.localeCompare(a.name))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectEmployeeOrganization(option)
                            }}
                            value={employeeOrganizationValue ? employeeOrganizationValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('employee_organization_id')} helperText={error('employee_organization_id')} placeholder="Pilih Organisasi" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Pengemudi
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih Jabatan Pengemudi
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                    <Autocomplete
                            options={employee_job_positions.sort((a, b) => -b.name.localeCompare(a.name))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            disabled={employee_job_positions.length === 0}
                            onChange={(event, option) => {
                                onSelectDriverPosition(option)
                            }}
                            value={driverPositionValue ? driverPositionValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('driver_position_id')} helperText={error('driver_position_id')} placeholder="Pilih Jabatan Pengemudi" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Kondektur / Kenek
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih Jabatan Kondektur / Kenek
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={employee_job_positions.sort((a, b) => -b.name.localeCompare(a.name))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            disabled={employee_job_positions.length === 0}
                            onChange={(event, option) => {
                                onSelectAssistantPosition(option)
                            }}
                            value={assistantPositionValue ? assistantPositionValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('assistant_position_id')} helperText={error('assistant_position_id')} placeholder="Pilih Jabatan Kondektur" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
            </Grid>
            
            <Grid item container sx={{marginBottom: '15px'}} justifyContent="space-between" spacing={3}>
                <Grid item>
                </Grid>
                <Grid item>
                    <Grid container spacing={3}>
                        <Grid item>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained"
                                size="large"
                                color="primary"
                                sx={{minWidth: 195}}
                                onClick={onEdit}
                                disabled={inProgress}
                                disableElevation>
                                {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>  
        </Grid>
    )
}

