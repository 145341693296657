import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { 
        Typography,
        Box,
        Paper,
        Grid,
        Divider,
        Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PublishedWithChangesTwoTone, AccessTimeTwoTone, AssignmentIndTwoTone } from '@mui/icons-material';
import { grey, green } from '@mui/material/colors';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import 'moment/locale/id' 
import { tooltipClasses } from '@mui/material/Tooltip';

export default function Information({error}) {    
    const {store_pos_shift,} = useSelector(state => ({
        ...state.store_pos_shift
    }), shallowEqual); 


    const { 
        status,
        increment_id, 
        store, 
        employee,
        active,
        open_at,
        close_at,
        open_balance,
        close_balance,
        total_cash_in,
        total_cash_out,
        total_cash_payment,
        total_others_payment
    } = store_pos_shift;

   
    return (
        <ContentPaper elevation={3}>
            <Box sx={{display: 'flex', padding: '35px', paddingTop: '25px', paddingBottom: '25px'}}>
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                    <PublishedWithChangesTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {active ? "Berjalan" : status}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography fontWeight={700} color="primary">
                    {increment_id}
                </Typography>
                <Slash key={1}>
                    /
                </Slash>
                <Typography key={2} fontWeight={700} sx={{display: 'flex'}}>
                    <AssignmentIndTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px', color: green[600] }} /> {employee}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTimeTwoTone
                        style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }}
                        color="primary"
                    />
                    {moment(open_at).format('LL')}, {moment(open_at).format('LT')} 
                    {!close_at ? (
                        <> WIB</>
                    ) : (
                        <> - {moment(close_at).format('LL')}, {moment(close_at).format('LT')} WIB</>
                    )}
                </Typography>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Box sx={{padding: '35px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Saldo Awal
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={open_balance}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700} fontSize={16} variant="h6">
                                {value}
                                </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Saldo Akhir
                            </Typography>
                        </Grid>
                        <Grid item>
                        {active ? "-" :  <NumberFormat
                                value={close_balance}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700} fontSize={16} variant="h6">
                                {value}
                                </Typography>}
                            />}
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Kas Masuk
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={total_cash_in}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700} fontSize={16} variant="h6">
                                {value}
                                </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Kas Keluar
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={total_cash_out}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700} fontSize={16} variant="h6">
                                {value}
                                </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Penjualan Kas
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={total_cash_payment}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700} fontSize={16} variant="h6">
                                {value}
                                </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Penjualan Lainnya
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={total_others_payment}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700} fontSize={16} variant="h6">
                                {value}
                                </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Cabang
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} sx={{display: 'flex'}}>
                                {store}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Aktivitas
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h6" color="primary" fontSize={14} style={{textTransform: 'capitalize', cursor: 'pointer'}}>
                                Lihat Aktivitas
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    marginBottom: 25
}));


const Slash = styled(Typography)(({theme}) => ({
    margin: '0 6px',
    color: 'var(--N75, #E5E7E9)'
}));
