import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../actions/employeeActions';
import { 
    Grid,
    Typography,
    TextField,
    Alert,
    Autocomplete,
    CircularProgress,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export default function Detail() {
    const dispatch = useDispatch();
    const {
        employee, 
        stores, 
        employee_statuses, 
        employee_organizations, 
        employee_job_positions, 
        approvals, 
        approvalsFetching,
        positionsFetching, 
        errors
    } = useSelector(state => ({
        ...state.employee
    }), shallowEqual); 

    function onSelectStore(option) {
        const newEmployee = {...employee};
        newEmployee.store_id = option._id;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    }
    
    function onSelectEmployeeStatus(option) {
        const newEmployee = {...employee};
        const employeeStatus = employee_statuses.find(opt => opt._id === option._id);
        if (employeeStatus.name.toLowerCase() === "permanent") {
            delete newEmployee.ended_date;
        } else  {
            newEmployee.started_date = new Date();
            newEmployee.ended_date = new Date();
        }
        newEmployee.employee_status_id = option._id;
        
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    }

    function onSelectEmployeeOrganization(option) {
        const newEmployee = {...employee};
        newEmployee.employee_organization_id = option._id;
        newEmployee.employee_job_position_id = '';
        newEmployee.parent_id = '';
        dispatch(employeeActions.onSelectEmployeeOrganization(newEmployee));
    }

    function onSelectEmployeeJobPosition(option) {
        if (option.name.toLowerCase() === 'ceo') {
            if (errors && errors.parent_id) {
                delete errors.parent_id;
            }
        }
        const newEmployee = {...employee};
        newEmployee.employee_job_position_id = option._id;
        newEmployee.parent_id = '';
        dispatch(employeeActions.onSelectEmployeeJobPosition(newEmployee));
    }

    function onSelectParent(option) {
        const newEmployee = {...employee};
        newEmployee.parent_id = option._id;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    }


    const onSelectStartDate = (date) => {
        const newEmployee = {...employee};
        newEmployee.started_date = date;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    };

    const onSelectEndDate = (date) => {
        const newEmployee = {...employee};
        newEmployee.ended_date = date;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    };

    const onKeyDown = (e) => {
        e.preventDefault();
    };
    
    function error(key) {
        if (errors != null && errors[key] != null) {
            return errors[key][0]
        }

        return null;
    }

    const storeOptions = stores.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });


    const { parent_id, employee_organization_id, store_id, employee_job_position_id, employee_status_id, started_date, ended_date } = employee;


    const storeValue = storeOptions.find(opt => opt._id === store_id);
    const employeeOrganizationValue = employee_organizations.find(opt => opt._id === employee_organization_id);
    const employeeJobPositionValue = employee_job_positions.find(opt => opt._id === employee_job_position_id);
    const employeeParentValue = approvals.find(opt => opt._id === parent_id);
    const employeeStatusValue = employee_statuses.find(opt => opt._id === employee_status_id);
   
    return (
        <Grid container spacing={6} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                        Informasi Karyawan
                    </Typography>
                </Grid>
                <Grid item>
                    <Alert severity="error">
                        Wajib
                    </Alert>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={5}>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Cabang
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih cabang yang dapat diakses oleh pengguna.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={storeOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectStore(option)
                            }}
                            value={storeValue ? storeValue : null}
                            isOptionEqualToValue={(
                                option,
                                value,
                            ) => value.value === option.value}
                            renderInput={(params) => <TextField {...params} error={!!error('store_id')} helperText={error('store_id')} placeholder="Pilih Cabang" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Organisasi
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih organisasi karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={employee_organizations.sort((a, b) => -b.name.localeCompare(a.name))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectEmployeeOrganization(option)
                            }}
                            value={employeeOrganizationValue ? employeeOrganizationValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('employee_organization_id')} helperText={error('employee_organization_id')} placeholder="Pilih Organisasi" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Jabatan
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih jabatan karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={employee_job_positions.sort((a, b) => -b.name.localeCompare(a.name))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            disabled={employee_job_positions.length === 0}
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectEmployeeJobPosition(option)
                            }}
                            value={employeeJobPositionValue ? employeeJobPositionValue : null}
                            loading={positionsFetching}
                            renderInput={(params) => <TextField {...params} 
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {positionsFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                error={!!error('employee_job_position_id')} helperText={error('employee_job_position_id')} placeholder="Pilih Jabatan" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Atasan Langsung
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih atasan / supervisor karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={approvals.sort((a, b) => -b.name.localeCompare(a.name))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.job_position + " " + option.name}
                            blurOnSelect
                            disabled={!employeeJobPositionValue || !employeeOrganizationValue}
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectParent(option)
                            }}
                            value={employeeParentValue ? employeeParentValue : null}
                            loading={approvalsFetching}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <span style={{ fontWeight: 'bold', marginRight: 8 }}>{option.job_position}</span>
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params) => <TextField {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {approvalsFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                error={!!error('parent_id')} helperText={error('parent_id')} placeholder="Pilih Atasan" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Status Karyawan
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih status karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={employee_statuses.sort((a, b) => -b.name.localeCompare(a.name))}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectEmployeeStatus(option)
                            }}
                            value={employeeStatusValue ? employeeStatusValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('employee_status_id')} helperText={error('employee_status_id')} placeholder="Pilih Status Karyawan" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Tanggal Mulai - Tanggal Berakhir
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan tanggal mulai dan berakhir.
                        </Typography>
                    </Grid>
                    <Grid item xs={8} container spacing={5}>
                        <Grid item xs>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    openTo="year"
                                    disablePast
                                    value={started_date}
                                    format="dd/MM/yyyy"
                                    onChange={onSelectStartDate}
                                    renderInput={(params) => <TextField fullWidth onKeyDown={onKeyDown} {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    disabled={employeeStatusValue && employeeStatusValue.name.toLowerCase() === "permanent"}
                                    openTo="year"
                                    disablePast
                                    value={ended_date}
                                    format="dd/MM/yyyy"
                                    onChange={onSelectEndDate}
                                    renderInput={(params) => <TextField fullWidth onKeyDown={onKeyDown} {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid> 
                </Grid>     
            </Grid>
           
        </Grid>
    )
}