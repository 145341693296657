import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as storeActions from '../../../../../../actions/storeActions';
import * as commonActions from '../../../../../../actions/commonActions';
import { 
        CircularProgress,
        Button,
        Typography,
        Paper,
        Box,
        Grid,
        TextField,
        FormGroup,
        FormControlLabel,
        Switch,
        Alert,
        Autocomplete
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import NumberFormat from 'react-number-format';


const PhoneFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        fullWidth={props.fullWidth}
        allowLeadingZeros={true}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));


export default function Form() {
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {store, errors, inProgress, store_types, areas, areasFetching} = useSelector(state => ({
        ...state.outlet
    }), shallowEqual); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        if (id) {
            console.log('load')
			dispatch(storeActions.onStoreEdit(id));
		} else {
			dispatch(storeActions.onStoreNew());
		}
        return () => {
            dispatch(storeActions.onUnloadForm());
        };
    },[dispatch, id ]);

    const [loaded, setLoaded] = useState(false);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [areaOptions, setAreaOptions] = useState([]);

    
    useEffect(() => {
        function initialize() {
            const { state, city, district, code_area } = store;
            if (!!state && !!city && !!district && !!code_area) {
                const newOptions = [];
                const selectedOption = {value: `${state}, ${city}, ${district}, ${code_area}`, name: `${state}, ${city}, ${district}, ${code_area}`};
                newOptions.push(selectedOption);
                setAreaOptions(newOptions)
                setValue(selectedOption);
            }
        }
        if (!loaded && store) {
            initialize();
            setLoaded(true);
        }
    },[store, loaded]);

    useEffect(() => {
        setAreaOptions(areas)
    }, [areas]);

  
    function onSelectType(type) {
        const newStore = {...store};
        newStore.store_type_id = type._id;
        dispatch(storeActions.onUpdateStore(newStore));
    }

    
    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newStore = {...store};
        newStore[key] = value;
        dispatch(storeActions.onUpdateStore(newStore));
    }

    function onToggleStatus(value, event) {
        const key = event.target.name;
        
        const newStore = {...store};
        newStore[key] = value;
        dispatch(storeActions.onUpdateStore(newStore));
    }

    function onSelectArea(option) {
        if (!option) {
            return;
        }
        setValue(option);
        const newStore = {...store};
        const selectedArea = option.value.split(', ');
        newStore.state = selectedArea[0];
        newStore.city = selectedArea[1];
        newStore.district = selectedArea[2];
        newStore.code_area = selectedArea[3];
        dispatch(storeActions.onUpdateStore(newStore));
    }

    function onSubmit() {
		if (id) {
            dispatch(storeActions.onEdit(id, store));
		} else {
            dispatch(storeActions.onCreate(store));
		}
    }

   
    function error(key) {
        if (errors != null && errors[`store.${key}`] != null) {
            return errors[`store.${key}`].msg
        }
        return null;
    }

    if (!store || !store_types || !areaOptions) {
        return (
          <div className="loaderPage">
            <CircularProgress />
          </div>
        )
    }


    const { name, address, telephone, fax, phone, status, store_type_id } = store;


    const storeType = store_types.find(opt => opt._id === store_type_id);
   
    return (
        <ContentBox>
            <ContentPaper elevation={3}>
                <Grid container spacing={6} direction="column">
                    <Grid item container>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                Informasi Cabang
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Alert severity="error">
                                Dibutuhkan
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={5}>
                        <Grid item container spacing={8}>
                            <Grid item xs={4} >
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Nama Cabang
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Masukan nama cabang.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    error={!!error('name')}
                                    name="name"
                                    placeholder="Nama Cabang"
                                    value={name}
                                    helperText={error('name')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4} >
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Tipe Cabang
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Pilih tipe cabang.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Autocomplete
                                    options={store_types.sort((a, b) => -b.name.localeCompare(a.name))}
                                    getOptionLabel={(option) => option.name}
                                    selectOnFocus
                                    clearOnBlur
                                    filterSelectedOptions
                                    disableClearable
                                    onChange={(event, option) => {
                                        onSelectType(option)
                                    }}
                                    value={storeType ? storeType : null}
                                    renderInput={(params) => <TextField {...params} error={!!error('store_type_id')} helperText={error('store_type_id')} placeholder="Pilih Tipe" variant="outlined" />}
                                />
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4} >
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    No. HP
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Masukan no. hp cabang.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <PhoneFormat
                                    fullWidth
                                    error={!!error('phone')}
                                    name="phone"
                                    placeholder="No. HP"
                                    value={phone}
                                    helperText={error('phone')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4} >
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    No. Telepon
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Masukan no. telepon cabang.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <PhoneFormat
                                    fullWidth
                                    error={!!error('telephone')}
                                    name="telephone"
                                    placeholder="No. Telepon"
                                    value={telephone}
                                    helperText={error('telephone')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4} >
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    No. Fax
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Please input your store fax number.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <PhoneFormat
                                    fullWidth
                                    error={!!error('fax')}
                                    name="fax"
                                    placeholder="No. Fax"
                                    value={fax}
                                    helperText={error('fax')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid>  
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Provinsi, Kota, Kecamatan
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Masukan wilayah cabang beserta kodepos.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Autocomplete
                                    fullWidth
                                    onClose={() => {
                                        dispatch(commonActions.onUnloadAreas());
                                    }}
                                    isOptionEqualToValue={(option, value) => true}
                                    getOptionLabel={(option) => option.name}
                                    options={areaOptions}
                                    noOptionsText={!!inputValue && areas.length === 0 && !value ? 'Data tidak ditemukan.' : 'Silahkan masukan kode pos.'}
                                    loading={areasFetching}
                                    value={value}
                                    onChange={(event, newValue) => {
                                        onSelectArea(newValue)
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                        if (newInputValue.length < 5 || newInputValue.length > 5) {
                                            return;
                                        }
                                        dispatch(commonActions.onLoadAreas(newInputValue));
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props} style={{backgroundColor: "#fff"}}>
                                            {option.name}
                                        </li>
                                    )}
                                    inputValue={inputValue}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        error={!!error('state')}
                                        helperText={error('state')}
                                        placeholder="Provinsi, Kota, Kecamatan, Kode Pos"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                            <React.Fragment>
                                                {areasFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                            ),
                                        }}
                                        />
                                    )}/>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                    Alamat
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Masukan alamat cabang.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    error={!!error('address')}
                                    rows={6}
                                    multiline
                                    name="address"
                                    placeholder="Alamat"
                                    value={address}
                                    helperText={error('address')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4} >
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Status Cabang
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Aktif / tidak aktif cabang.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={status}
                                            onChange={onToggleStatus.bind(this, !store.status)}
                                            name="status"
                                            color="primary"
                                        />
                                        }
                                        label={status ? 'Aktif' : 'Tidak Aktif'}
                                    />
                                </FormGroup>    
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </ContentPaper>
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    disabled={inProgress}
                                    onClick={ () => {navigate(`/stores`)}}>
                                    Batal
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
        </ContentBox>
    )
}


const ContentBox = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));
const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));
      