import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderDeliveryActions from '../../../../../../actions/orderDeliveryActions';
import { 
        InputBase,
        Typography,
        Box,
        Paper,
        Grid,
        TextField,
        InputAdornment,
        Divider,
        Tooltip,
        Switch,
        Table,
        TableHead,
        TableBody,
        TableRow,
        TableCell
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { StickyNote2TwoTone } from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
const QuantityFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        disabled={props.disabled}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
        InputProps={props.InputProps}
      />
));



export default function Product({error}) {    
    const theme = useTheme();
    const dispatch = useDispatch();
    const {order_delivery} = useSelector(state => ({
        ...state.order_delivery
    }), shallowEqual); 
    const [currentFocus, setCurrentFocus] = useState("");

    const {
        status,
        order_delivery_items,
        note
    } = order_delivery;


    function onUpdateField(index, event) {
        if (currentFocus === "delivered") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrderDelivery = {...order_delivery};
            const currentItem = newOrderDelivery.order_delivery_items[index];
            if (value <= currentItem.quantity) {
                currentItem[key] = value;
            }
            dispatch(orderDeliveryActions.onUpdateOrderDelivery(newOrderDelivery));
        }
    }

    function onUpdateNoteField(event) {
        if (currentFocus === "note") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrderDelivery = {...order_delivery};
            newOrderDelivery[key] = value;
            dispatch(orderDeliveryActions.onUpdateOrderDelivery(newOrderDelivery));
        }
    }
    

    function onFocus(key) {
        setCurrentFocus(key)
    }

    function onBlur(key) {
        setCurrentFocus(null)
    }


    function Requested(item) {
        if (status === 'on_delivery') {
            return (
                <QuantityFormat
                    fullWidth
                    name="quantity"
                    value={item.quantity}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                          style: {
                            textAlign: 'right', // Align text to the right
                          },
                        },
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.quantity}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }


    function Delivered(item, index) {
        if (status === 'on_delivery') {
            return (
                <QuantityFormat
                    fullWidth
                    error={!!error(`order_delivery_items[${index}]`)}
                    // helperText={error(`order_delivery_items[${index}]`)}
                    name="delivered_qty"
                    value={item.delivered_qty}
                    onFocus={onFocus.bind(this, "delivered")}
                    onBlur={onBlur.bind(this)}
                    variant="outlined"
                    onChange={onUpdateField.bind(this, index)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                          style: {
                            textAlign: 'right', // Align text to the right
                          },
                        },
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.delivered_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                    {value ? value : 0} {item.uom}
                </Typography>}
            />
        )
    }

    function Footer() {
        return (
            <Grid container spacing={3}>
                <Grid item>
                    <CommentField
                        value={note}
                        onFocus={onFocus.bind(this, "note")}
                        onBlur={onBlur.bind(this)}
                        onChange={onUpdateNoteField}
                        >
                        <IconWrapper>
                            <StickyNote2TwoTone />
                        </IconWrapper>
                        <StyledInputBase
                            name="note"
                            disabled={status === 'canceled' || status === 'completed'}
                            placeholder="Catatan…"/>
                    </CommentField>
                </Grid>
            </Grid>
        )
    }

    let titleAlign = 'right'
    let tableGrid = '56% 22% 22%'
    let maxName = 35
    return (
        <ContentPaper elevation={3}> 
            <Grid container spacing={3} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Produk
                        </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
            </Grid>
            <Table sx={{
                display: 'grid',
                overflow: 'auto hidden',
                gridTemplateColumns: tableGrid,
                }}>
                <TableHead sx={{display: 'contents'}}>
                    <TableRow sx={{display: 'contents'}}>
                        <TableCell sx={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            position: 'sticky',
                            zIndex: 1,
                            left: 0,
                            background: '#fff',
                            }}>
                            Nama Barang
                        </TableCell>
                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                            Diajukan
                        </TableCell>
                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                            Terkirim
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{display: 'contents'}}>
                {
                    order_delivery_items.map((item, index) => {
                        return (
                            <TableRow key={index} sx={{display: 'contents'}}>
                                <TableCell sx={{
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    position: 'sticky',
                                    zIndex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    left: 0,
                                    background: '#fff'
                                    }}
                                    tabIndex={-1}
                                    >
                                    <Tooltip
                                        title={item.name}
                                        arrow
                                    >
                                        <Box>
                                            <Typography sx={{ fontWeight: 700 }} fontSize={16} lineHeight="normal">
                                                {item.name.length > maxName
                                                    ? `${item.name.substring(0, maxName)}...`
                                                    : item.name}
                                            </Typography>
                                            <Typography fontSize={11} lineHeight="normal" sx={{ color: '#999' }}>
                                                SKU #{item.sku}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    {Requested(item)}
                                </TableCell>
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    {Delivered(item, index)}
                                </TableCell>
                            </TableRow>
                        );
                    })
                }
                </TableBody>
            </Table>
            <Divider sx={{marginTop: '25px', marginBottom: '30px'}} />
            {Footer()}
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const CommentField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));

const IconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));