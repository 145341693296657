import React, { useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { onNew, onUnload, onCreate } from '../../../../../../actions/storeTransferActions';
import {CircularProgress,
        Button,
        Grid,
        Box,
        Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Information from './Information';
import Product from './Product';
import Loader from '../../../../../Helper/Loader';

export default function Form() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const {store_transfer, errors, inProgress} = useSelector(state => ({
        ...state.store_transfer
    }), shallowEqual); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(onNew());
        return () => {
            dispatch(onUnload())
        };
    },[dispatch]);

    function onSubmit() {
        dispatch(onCreate(store_transfer));
    }

    if (!store_transfer) {
        return (
            <Loader />
        )
    }

    return (
        <ContentBox>
            <Information />
            <Product />
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    disabled={inProgress}
                                    onClick={ () => {navigate(`/store-transfers`)}}>
                                    Cancel
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
        </ContentBox>
    )
}


const ContentBox = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));
