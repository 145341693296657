import React, {useState} from "react";
import {
    InputBase,
    Paper,
    Typography,
    Grid,
    Box,
    IconButton,
    FormControl, 
    OutlinedInput,
    Button,
    Select,
    MenuItem
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { AddBoxTwoTone, Search, AddRounded } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

export default function Header({query, selectStore, stores}) {
    let navigate = useNavigate();
    const [search, setSearch] = useState(query.search);

    // function onSelectStore(event) {
        // const value = event.target.value;
        // const newQuery = {...query};
        // newQuery.store_id = value;
        // setQuery(newQuery)
    // }

    // function onSearch(e) {
    //     var enterKey = 13;
    //     if (e.which === enterKey) {
    //         e.target.blur(); 
    //         const newQuery = {...query};
    //         newQuery.page = 0;
    //         newQuery.search = search;
    //         setQuery(newQuery)
    //     }
    // }
    return (
        <Box>
            <MobileBox>
                <HeaderBox>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Box>
                                <Typography fontWeight={500} variant="h6">
                                   Persediaan
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <IconButton color="primary">
                                <AddBoxTwoTone />
                            </IconButton>
                        </Grid>
                    </Grid>
                </HeaderBox>
                <Box>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Search"
                        />
                    </FormControl>
                </Box>
            </MobileBox>
            <DesktopBox>
                <HeaderBox>
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item>
                            <Box>
                                <Typography fontWeight={500} variant="h6">
                                    Persediaan
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                        <FormControl variant="outlined" size="small" disabled={!stores || stores.length === 0}>
                            <Select
                                value={query.store_id}
                                onChange={selectStore}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={''}>Semua Cabang</MenuItem>
                                    {stores && stores.length > 0 ? (
                                        stores.map((option, index) => (
                                            <MenuItem key={index} value={option._id}>
                                                {option.name}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled value="">
                                            Semua Cabang
                                        </MenuItem>
                                    )}
                            </Select>
                        </FormControl>

                        </Grid>
                    </Grid>
                </HeaderBox>
                {/* <HeaderPaper elevation={3}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <SearchField
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={onSearch}>
                                <SearchIconWrapper>
                                    <Search />
                                </SearchIconWrapper>
                                <StyledInputBase
                                placeholder="Search…"/>
                            </SearchField>
                        </Grid>
                        <Grid item>
                            {/* <Button 
                                variant="contained" 
                                color="primary"
                                disableElevation
                                startIcon={<AddRounded />}
                                onClick={(e) => navigate('/suppliers/new')} >
                                Add Supplier
                            </Button> 
                        </Grid>
                    </Grid>
                </HeaderPaper> */}
            </DesktopBox>
        </Box>
    )
}

const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const MobileBox = styled(Box)(({theme}) => ({
    display: 'none',
    [theme.breakpoints.down('sm')]: {
        display: 'block'
    },
}));

const DesktopBox = styled(Box)(({theme}) => ({
    display: 'block',
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
}));

const HeaderPaper = styled(Paper)(({theme}) => ({
    padding: 15
}));

const SearchField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));
  
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
  
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));