import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../../actions/employeeActions';
import { 
    Typography,
    Grid,
    Button,
    Dialog,
    DialogContent,
    Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import NumberFormat from 'react-number-format';
import Edit from './Edit';
import Loader from '../../../../../../Helper/Loader';

  
export default function Payroll() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { employee, editPayroll, onEditing } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);
    const [open, setOpen] = React.useState(false);

    const {basic_salary, salary_type, tax_identity, tax_account_number, tax_status, bank, bank_account_holder, bank_account_number} = employee.employee_payroll;

    const handleView = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
    };
    
    
    function onEdit() {
        dispatch(employeeActions.onEditPayroll(id))
    };

    if (onEditing) {
        return (
            <Box sx={{ minHeight: '395px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loader />
            </Box>
        )
    }

    if (editPayroll) {
        return <Edit />
    }
    return (
        <Grid container spacing={4} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} component="h6" lineheight="normal">
                        Gaji Karyawan
                    </Typography>
                </Grid>
                <Grid item>
                    <Button onClick={onEdit}>
                        Ubah
                    </Button>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Gaji Pokok
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            <NumberFormat
                                value={basic_salary}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700} fontSize={16}>
                                {value}
                                </Typography>}
                            />
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Tipe Gaji
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {salary_type}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container alignItems="center" spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            No. Pajak
                        </Typography>
                    </Grid>
                    <Grid item xs={8} alignItems="center" container spacing={3}>
                        {tax_account_number ? 
                            <>
                            <Grid item>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                {tax_account_number}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleView}>
                                Lihat
                            </Button>
                        </Grid>
                            </>
                            : 
                            <Grid item>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    -
                                </Typography>
                            </Grid>
                        }
                        
                    </Grid> 
                    <Dialog
                        fullWidth
                        maxWidth="xs"
                        open={open}
                        onClose={handleClose}>
                        <DialogContent sx={{padding: 0}}>
                            <ImageBox component="img" src={tax_identity} />
                        </DialogContent>
                    </Dialog>
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Status Pajak
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {tax_status ? tax_status : "-"}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Bank
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {bank ? bank : "-"}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            No. Rekening
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {bank_account_number ? bank_account_number : "-"}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                           Nama Pemilik
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {bank_account_holder ? bank_account_holder : "-"}
                        </Typography>
                    </Grid> 
                </Grid>
            </Grid>
        </Grid>
    )
}

const ImageBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '400px',
    width: '100%'
}));