import React, { useState, useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as customerDisplayActions from '../../../../../actions/customerDisplayActions';
import { 
        CircularProgress,
        Button,
        Typography,
        Paper,
        Box,
        Grid,
        TextField,
        FormGroup,
        FormControlLabel,
        Switch,
        IconButton,
        Alert,
        LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import {ImageRounded, DeleteForeverRounded} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
  
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    
    left: 0,
    margin: 0,
    padding: 0,
    // styles we need to apply on draggables
    ...draggableStyle
});


export default function Form() {
    let navigate = useNavigate();
    let { id, parent_id } = useParams();
    const dispatch = useDispatch();
    const {customer_display, errors, emptyImages, inProgress} = useSelector(state => ({
        ...state.customer_display
    }), shallowEqual); 
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        if (id) {
			dispatch(customerDisplayActions.onCustomerDisplayEdit(id));
		} else {
			dispatch(customerDisplayActions.onCustomerDisplayNew());
		}
        return () => {
            dispatch(customerDisplayActions.onUnloadForm())
        };
    },[dispatch, id, parent_id])
    
    useEffect(() => {
        if (customer_display && customer_display.customer_display_images) {
            
            customer_display.customer_display_images.map((image, index) => {
                
                if (image.onUpload) {
                    image.onUpload = false;
                    dispatch(customerDisplayActions.onUpload(image, index, (event) => {
                        image.progress = Math.round((event.loaded * 100) / event.total);
                        image.onUploading = true;
                        dispatch(customerDisplayActions.onUploading(image, index))
                    }));
                }

                if (image.onRemove) {
                    dispatch(customerDisplayActions.onRemove(image, index));
                }
            })
        }
     },[customer_display]);
    
    
    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newCustomerDisplay = {...customer_display};
        newCustomerDisplay[key] = value;
        dispatch(customerDisplayActions.onUpdateCustomerDisplay(newCustomerDisplay));
    }

    function onToggleStatus(value, event) {
        const key = event.target.name;
        const newCustomerDisplay = {...customer_display};
        newCustomerDisplay[key] = value;
        dispatch(customerDisplayActions.onUpdateCustomerDisplay(newCustomerDisplay));
    }

    function onAttachImages(e) {
        e.preventDefault();
        const files = Array.from(e.target.files);
        

        const uploadedLength = getNumberImages() + files.length;
        const uploadFiles = 5 - getNumberImages();
        if (uploadedLength > 5) {
            setHasError(true);
            files.splice(uploadFiles, files.length)
        }
        
        for (let i = 0; i < files.length; i++) {
            let file = files[i]
            const reader = new FileReader();
            
            const emptyData = customer_display_images.find(el => !el.image);
            if (emptyData) {
                customer_display_images.pop();
            }
            reader.onloadend = () => {
                emptyImages.shift();
                dispatch(customerDisplayActions.onUploadRequest(reader.result));
            }
            reader.readAsDataURL(file);
        
        }
    }

    function onSelectImage(event) {
        event.preventDefault();
        const newCustomerDisplay = {...customer_display};
    
        const files = event.target.files;
        
        let file = files[0]
        const reader = new FileReader();
        reader.onloadend = () => {
            newCustomerDisplay.customer_display_images[newCustomerDisplay.customer_display_images.length - 1].image = reader.result
            dispatch(customerDisplayActions.onUpdateCustomerDisplay(newCustomerDisplay));
        }
        reader.readAsDataURL(file);
        
    }

    const onSelectStartDate = (date) => {
        const newCustomerDisplay = {...customer_display};
        newCustomerDisplay.start_date = date;
        dispatch(customerDisplayActions.onUpdateCustomerDisplay(newCustomerDisplay));
    };

    const onSelectEndDate = (date) => {
        const newCustomerDisplay = {...customer_display};
        newCustomerDisplay.end_date = date;
        dispatch(customerDisplayActions.onUpdateCustomerDisplay(newCustomerDisplay));
    };

    function onRemoveImage(data, index) {      
        dispatch(customerDisplayActions.onRemoveRequest(data, index));
    }


    function onSubmit() {
		if (id) {
            dispatch(customerDisplayActions.onEdit(id, customer_display));
		} else {
            dispatch(customerDisplayActions.onCreate(customer_display));
		}
    }


    function onDragEnd(result) {
		
		// dropped outside the list
		if (!result.destination) {
		  return;
		}
	
		// const imagesReorder = reorder(
        //     customer_display_images,
		//     result.source.index,
		//     result.destination.index
		// );
		
		// const newImages = [];
        // imagesReorder.map((image, index)=> {
            
		// 	const newImage = {...image, sort_order: index};
		// 	return newImages.push(newImage)
		// })

        // dispatch(customerDisplayActions.onUpdateImages(newImages));
    }

    function getNumberImages() {
        return customer_display.customer_display_images.filter(el => {
          return el.image && !el._destroy;
        }).length;
    }


    function getDestroyedImages() {
		return customer_display.customer_display_images.filter(el => {
	    	return el._destroy === true;
	  	}).length;
	}

    const onKeyDown = (e) => {
        e.preventDefault();
    };
    
    function error(key) {
        if (errors != null && errors[`customer_display.${key}`] != null) {
            return errors[`customer_display.${key}`].msg
        }
        return null;
    }

    if (!customer_display) {
        return (
          <div className="loaderPage">
            <CircularProgress />
          </div>
        )
    }


    const { name, active, start_date, end_date, customer_display_images } = customer_display;

    let imgClass = 'imageEmpty';
    let error_class = 'hasError';
    if (!!error('customer_display_images.image')) {
        imgClass = imgClass.concat(' ', error_class)
    }
    return (
        <ContentBox>
            <ContentPaper elevation={3}>
                <Grid container spacing={6} direction="column">
                    <Grid item container>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6">
                                 Media Tampilan
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px (Untuk gambar optimal gunakan ukuran minimum 700 x 700 px)
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Alert severity="error">
                                Wajib
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={5} direction="column">
                        <Grid item>
                            <Box display="flex" justifyContent="center">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable" direction="horizontal">
                                {(provided, snapshot) => (
                                    <Box display="flex"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        >
                                    {
                                        customer_display_images.sort((a, b) => a.sort_order - b.sort_order).map((data, index) => {
                                            if (data._destroy) {
                                                return null;
                                            }
                                            if (data.onRemove) {
                                                return (
                                                    <Draggable key={index} draggableId={index.toString()} isDragDisabled={true}  index={index}>
                                                        {(provided, snapshot) => (
                                                            <ImageBox 
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}>
                                                                <ImageAttachedPaper>
                                                                    <ImagePreviewBox>
                                                                        <Box component="img" alt={data.image} src={data.image} />
                                                                        <Box sx={{
                                                                            position: 'absolute', 
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            width: '100%',
                                                                            height: '100%'}}>
                                                                            <Box sx={{
                                                                                position: 'absolute',
                                                                                background: '#fff',
                                                                                opacity: 0.45,
                                                                                width: '100%',
                                                                                height: '100%'
                                                                            }} />
                                                                            <Box sx={{ display: 'flex' }}>
                                                                                <CircularProgress />
                                                                            </Box>
                                                                        </Box>
                                                                    </ImagePreviewBox>
                                                                </ImageAttachedPaper>

                                                            </ImageBox>
                                                        )}
                                                    </Draggable>
                                                )
                                            }
                                            if (data.onUploading) {
                                                return (
                                                    <Draggable key={index} draggableId={index.toString()} isDragDisabled={true}  index={index}>
                                                        {(provided, snapshot) => (
                                                            <ImageBox 
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}>
                                                                <ImageAttachedPaper>
                                                                    <ImagePreviewBox>
                                                                        <Box component="img" alt={data.image} src={data.image} />
                                                                        <Box sx={{ width: '80%', position: 'absolute', bottom: '15px' }}>
                                                                            <LinearProgress sx={{height: '8px', borderRadius: '35px'}} variant="determinate" value={data.progress || 0} />
                                                                        </Box>
                                                                    </ImagePreviewBox>
                                                                </ImageAttachedPaper>

                                                            </ImageBox>
                                                        )}
                                                    </Draggable>
                                                )
                                            }
                                            if (data.image) {
                                                return (
                                                    
                                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                                        {(provided, snapshot) => (
                                                            <ImageBox 
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <ImageAttachedPaper>
                                                                    <ImagePreviewBox>
                                                                        <DeleteIconButton size="small" 
                                                                            color="inherit" aria-label="menu" onClick={() => {
                                                                            onRemoveImage(data, index);
                                                                        }} >
                                                                            <DeleteForeverRounded style={{ color: '#999' }} />
                                                                        </DeleteIconButton>
                                                                        <Box component="img" alt={data.image} src={data.image} />
                                                                        {index === getDestroyedImages() && <PrimaryTypography fontWeight={500} fontSize={12} variant="h6" >
                                                                                Utama
                                                                        </PrimaryTypography>}
                                                                    </ImagePreviewBox>
                                                                </ImageAttachedPaper>
                                                            </ImageBox>
                                                        )}
                                                    </Draggable>
                                                    
                                                )
                                            }
                                            return null;
                                        })
                                    }
                                    {provided.placeholder}
                                    </Box>
                                )}
                                </Droppable>
                            </DragDropContext>                                       
                            {
                                emptyImages.map((data, index) => {
                                    return (
                                        <ImageBox key={index}>
                                            <ImagePaper >
                                                <ImageEmptyBox error={!!error('product_images')}>
                                                    <ImageRounded
                                                        style={{ color: '#999', fontSize: 50 }} />
                                                        <input type="file" multiple={true} accept="image/*" onChange={onAttachImages}/>
                                                </ImageEmptyBox>
                                            </ImagePaper>
                                        </ImageBox>
                                    )
                                })
                            }
                            </Box>
                        </Grid>
                        <Grid item>
                            { hasError && <Alert 
                            severity="warning">Maksimal foto produk adalah 5.</Alert> }
                        </Grid>
                        <Grid item>
                            { !!error('product_images') && <Alert
                            severity="error">Gambar dibutuhkan.</Alert>}
                        </Grid>
                    </Grid>
                </Grid>
            </ContentPaper>
            <ContentPaper elevation={3}>
                <Grid container spacing={6} direction="column">
                    <Grid item container>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                Informasi Tampilan
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Alert severity="error">
                                Dibutuhkan
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={5}>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                    Nama Tampilan
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Silahkan masukan nama tampilan.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    error={!!error('name')}
                                    id="name"
                                    name="name"
                                    placeholder="Nama Tampilan"
                                    value={name}
                                    helperText={error('name')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                    Jangka Waktu
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Silahkan masukan periode waktu tampilan pelanggan.
                                </Typography>
                            </Grid>
                            <Grid item xs container spacing={5}>
                                <Grid item xs>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            openTo="year"
                                            disablePast
                                            value={start_date}
                                            format="dd/MM/yyyy"
                                            onChange={onSelectStartDate}
                                            renderInput={(params) => <TextField fullWidth onKeyDown={onKeyDown} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            openTo="year"
                                            disablePast
                                            value={end_date}
                                            format="dd/MM/yyyy"
                                            onChange={onSelectEndDate}
                                            renderInput={(params) => <TextField fullWidth onKeyDown={onKeyDown} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                    Status 
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Pastikan active aktif untuk dapat dilihat oleh pelanggan.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={active}
                                            onChange={onToggleStatus.bind(this, !customer_display.active)}
                                            name="active"
                                            color="primary"
                                        />
                                        }
                                        label={active ? 'Aktif' : 'Tidak Aktif'}
                                    />
                                </FormGroup>
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </ContentPaper>
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    disabled={inProgress}
                                    onClick={ () => {navigate(`/customer-displays`)}}>
                                    Batal
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));
const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));

const ImageBox = styled(Box)(({theme}) => ({
    marginLeft: '10px !important',
    marginRight: '10px !important'
}));

const ImageAttachedPaper = styled(Paper)(({theme}) => ({
    width: 141,
    height: 141,
    padding: 3,
    position: 'relative',
    '&:hover': {
        "& button": {
            visibility: 'visible'
        }
    }
}));

const PrimaryTypography = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    background: '#d0b0ff',
    borderRadius: 4,
    padding: '2px 10px 2px 10px',
    position: 'absolute',
    bottom: 10,
    left: 10
}));

const ImagePaper = styled(Paper)(({theme}) => ({
    padding: 10,
    width: 127,
    height: 127,
    position: 'relative'
}));

const DeleteIconButton = styled(IconButton)(({theme}) => ({
    background: '#ddd',
    position: 'absolute',
    right: 10,
    top: 10,
    width: 30,
    height: 30,
    visibility: 'hidden'
}));



const ImagePreviewBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    '& img': {
        width: '100%'
    }
}));

const ImageEmptyBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "error",
  })(({ theme, error }) => ({
    border: error ? '1px dashed #f44336' : '1px dashed #999',
    height: 125,
    width: 125,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    position: 'relative',
    "& input[type='file']": {
        bottom: 0,
        cursor: 'pointer',
        fontSize: '10em',
        height: '100%',
        left: 0,
        opacity: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 3
    },

}));
