import React, {useState, useEffect} from 'react';
import { Tabs,
         Tab,
         Paper,
         Typography,
         Box,
        } from '@mui/material';
import { styled } from '@mui/material/styles';
// import User from './User';
// import BusinessInformation from './BusinessInformation';
// import Invoice from './Invoice';
import PointOfSale from './PointOfSale';
import Invoice from './Invoice';
import JobPosition from './JobPosition';
import Inventory from './Inventory';



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Settings() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


    useEffect(() => {
      return () => {
        setValue(0) 
      };
    },[]);
    

    return (
        <React.Fragment>
            <Typography variant="h5" className="pageTitle">
                <Box fontWeight={700}>
                  Akun Pengguna
                </Box>
            </Typography>
            <Paper elevation={3} 
                sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{ minWidth: '195px', borderRight: 1, borderColor: 'divider' }}>
                    <Tab label="Kasir" key="kasir" />
                    <Tab label="Faktur" key="faktur" />
                    <Tab label="Jabatan" key="jabatan" />
                    <Tab label="Persediaan" key="persediaan" />
                </Tabs>
                <Panel value={value} index={0}>
                    <PointOfSale />
                </Panel>
                <Panel value={value} index={1}>
                    <Invoice />
                </Panel>
                <Panel value={value} index={2}>
                    <JobPosition />
                </Panel>
                <Panel value={value} index={3}>
                    <Inventory />
                </Panel>
            </Paper>
        </React.Fragment>
    )
}

const Panel = styled(TabPanel)(({theme}) => ({
    position: 'relative',
    width: '100%'
}));

