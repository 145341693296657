import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as storeActions from '../../../../../../actions/storeActions';
import { 
        CircularProgress,
        Button,
        Typography,
        Paper,
        Box,
        Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../../Helper/Loader';
import Inventory from './Inventory';

export default function Show() {
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {store} = useSelector(state => ({
        ...state.outlet
    }), shallowEqual); 


    useEffect(() => {
        dispatch(storeActions.onLoadStore(id));
        return () => {
            dispatch(storeActions.onUnloadStore());
        };
    },[dispatch, id]);


    if (!store) {
        return (
            <Loader/>
        )
    }

    const { name, store_type, telephone, phone, address, status, fax, has_inventories } = store;

    return (
        <ContentBox>
            <ContentPaper elevation={3}>
                <Grid container spacing={6} direction="column">
                    <Grid item container>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                Informasi Cabang
                            </Typography>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                   <Grid item container direction="column" spacing={3}>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Nama Cabang
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {name}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Tipe Cabang
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {store_type}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    No. HP
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {phone}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    No. Telepon
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {telephone}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    No. Fax
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {fax ? fax : "-"}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Alamat
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    <span dangerouslySetInnerHTML={{__html: address}} />
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {status ? "Aktif" : "Tidak Aktif"}
                                </Typography>
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </ContentPaper>
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                        <WhiteButton 
                            variant="contained"
                            size="large"
                            disableElevation
                            disabled={!has_inventories}
                            onClick={ () => {dispatch(storeActions.onShowInventories())}}
                            >
                                Persediaan
                        </WhiteButton>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <WhiteButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    onClick={ () => {navigate(`/stores`)}}>
                                    Batal
                                </WhiteButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={ () => {navigate(`/stores/${id}/edit`);}}
                                    disableElevation>
                                    Ubah Cabang
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
            <Inventory />
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    paddingBottom: 15,
    marginTop: 25
}));
const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const WhiteButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));