
import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onLoadStorePosShifts = (query) => {
	const fetch = axiosClient.get(`/store-pos-shifts`,
	{
		params: query
	});
	return {
		type: types.STORE_POS_SHIFTS_LOADED,
		payload: fetch
	}
}

export const onLoadStorePosShiftsReport = (query) => {
	const fetch = axiosClient.get(`/store-pos-shifts-report`,
	{
		params: query
	});
	return {
		type: types.STORE_POS_SHIFTS_REPORT_LOADED,
		payload: fetch
	}
}

export const onLoadStorePosShift = (id) => {
	const fetch = axiosClient.get(`/store-pos-shifts/${id}`);
	return {
		type: types.STORE_POS_SHIFT_LOADED,
		payload: fetch
	}
}


export const onUnloadStorePosShift = (id) => {
	return {
		type: types.STORE_POS_SHIFT_UNLOADED
	}
}

export const onShowStorePosShiftOrders = () => {
	return {
		type: types.STORE_POS_SHIFT_ORDERS_SHOWED
	}
}

export const onHideStorePosShiftOrders = () => {
	return {
		type: types.STORE_POS_SHIFT_ORDERS_HIDED
	}
}

export const onLoadOrders = (id, query) => {
	const fetch = axiosClient.get(`/store-pos-shifts/${id}/orders`, 
	{
		params: query
	});
	return {
		type: types.STORE_POS_SHIFT_ORDERS_LOADED,
		payload: fetch
	}
}