
import { useSelector, shallowEqual } from 'react-redux';
import { 
        Typography,
        Box,
        Paper,
        Grid,
        Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NumberFormat from 'react-number-format';
 
  
export default function Detail({error}) {    
     const {store_pos_shift,} = useSelector(state => ({
         ...state.store_pos_shift
     }), shallowEqual); 

    const { 
       open_balance,
       close_balance,
       total_cash_payment,
       total_others_payment,
       total_edc_payment,
       total_gift_card_payment,
       total_other_payment,
       total_cash_in_cash_out,
       total_refunded,
       expecting_cash_balance,
       active,
       total_canceled_qty,
       total_ordered_qty
    } = store_pos_shift;


    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={3} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700}>
                            Detail Shift
                        </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <Divider sx={{marginTop: '15px', marginBottom: '20px'}} />
            </Grid>
            <Grid item container spacing={1} direction="column">
                <Grid item xs>
                    <Box sx={{display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Barang Terjual
                        </Typography>
                        <NumberFormat
                            value={total_ordered_qty}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                        </Typography>}/>
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box sx={{display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Barang Retur / Batal
                        </Typography>
                        <NumberFormat
                            value={total_canceled_qty}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                        </Typography>}/>
                    </Box>
                </Grid>
            </Grid>
            <Grid>
                <Divider sx={{marginTop: '15px', marginBottom: '15px'}} />
            </Grid>
            <Grid item container spacing={1} direction="column">
                <Grid item xs>
                    <Box sx={{display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Total Kas
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box sx={{ paddingLeft: '15px', display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Saldo Awal
                        </Typography>
                        <NumberFormat
                            value={open_balance}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                            </Typography>}
                        />
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box sx={{ paddingLeft: '15px', display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Pembayaran Tunai
                        </Typography>
                        <NumberFormat
                            value={total_cash_payment}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                            </Typography>}
                        />
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box sx={{ paddingLeft: '15px', display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Pembatalan
                        </Typography>
                        <NumberFormat
                            value={total_refunded}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                            </Typography>}
                        />
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box sx={{ paddingLeft: '15px', display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Kas Masuk / Keluar
                        </Typography>
                        <NumberFormat
                            value={total_cash_in_cash_out}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                            </Typography>}
                        />
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box sx={{ paddingLeft: '15px', display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Saldo Akhir (System)
                        </Typography>
                        <NumberFormat
                            value={expecting_cash_balance}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                            </Typography>}
                        />
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box sx={{ paddingLeft: '15px', display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Saldo Akhir
                        </Typography>
                        {active ? "-" : <NumberFormat
                            value={close_balance}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                            </Typography>}
                        />}
                    </Box>
                </Grid>
            </Grid>
            <Grid>
                <Divider sx={{marginTop: '15px', marginBottom: '15px'}} />
            </Grid>
            <Grid item container spacing={1} direction="column">
                <Grid item xs>
                    <Box sx={{display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Lainnya
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box sx={{ paddingLeft: '15px', display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            EDC
                        </Typography>
                        <NumberFormat
                            value={total_edc_payment}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                            </Typography>}
                        />
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box sx={{ paddingLeft: '15px', display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Voucher
                        </Typography>
                        <NumberFormat
                            value={total_gift_card_payment}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                            </Typography>}
                        />
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box sx={{ paddingLeft: '15px', display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Pembayaran Lainnya
                        </Typography>
                        <NumberFormat
                            value={total_other_payment}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                            </Typography>}
                        />
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box sx={{ paddingLeft: '15px', display: 'flex', justifyContent: "space-between"}}>
                        <Typography fontSize={14}>
                            Saldo Akhir (System)
                        </Typography>
                        <NumberFormat
                            value={total_others_payment}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography fontSize={14}>
                            {value}
                            </Typography>}
                        />
                    </Box>
                </Grid>
            </Grid>
        </ContentPaper>
        
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));
