import React, { useEffect } from 'react';
import { 
    Grid,
    FormControlLabel,
    CircularProgress,
    Button,
    Typography,
    Box,
    TextField,
    InputAdornment,
    Switch,
    FormGroup,
    MenuItem,
    Paper,
    List,
    ListSubheader,
    ListItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as userConfigurationActions from '../../../../actions/userConfigurationActions';
import NumberFormat from 'react-number-format';
import { AddRounded } from '@mui/icons-material';
import Loader from '../../../Helper/Loader';


  
export default function Inventory() {
    const dispatch = useDispatch();
    const { current_user, user_configuration, inProgress, errors } = useSelector(state => ({
        ...state.common,
        ...state.user_configuration
      }), shallowEqual); 


    useEffect(() => {
        dispatch(userConfigurationActions.onLoadUserInventory(current_user._id));
        return () => {
            dispatch(userConfigurationActions.onUnloadUserConfiguration());
        };
    },[]);

    function onToggle(value, event) {
		const key = event.target.name;
        const newUserConfiguration = {...user_configuration};
        newUserConfiguration[key] = value;
        dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    }

    // function onUpdateBanks(index, event) {
    //     const key = event.target.name;
    //     const value = event.target.value;

    //     const newUserConfiguration = {...user_configuration};
    //     const { user_configuration_banks } = newUserConfiguration;
    //     const newBanks = { ...user_configuration_banks[index], [key]: value};
	//     user_configuration_banks.splice(index, 1, newBanks);

    //     dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
  	// }

    //   function onAddFamily() {
    //     const addBanks = [...user_configuration.user_configuration_banks, {bank_name: "", bank_account_holder: "", bank_account_number: ""}]
    //     const newBanks = addBanks.map((bank, index)=> {
    //         return {...bank, sort_order: index}
    //     })
    //     const newUserConfiguration = {...user_configuration};
    //     newUserConfiguration.user_configuration_banks = newBanks;
    //     dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    // }

    // function onRemoveBank(index) {
    //     const newUserConfiguration = {...user_configuration};
    //     const { user_configuration_banks } = newUserConfiguration;
	// 	if (user_configuration_banks[index]._id) {
	// 		user_configuration_banks[index]._destroy = true
	// 	} else {
	// 		user_configuration_banks.splice(index, 1)
    //     }

    //     const newBanks = user_configuration_banks.map((bank, index)=> {
    //         return {...bank, sort_order: index}
    //     })
    //     dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    // }



    function onEdit() {
        dispatch(userConfigurationActions.onEditUserConfigurationInventory(user_configuration._id, user_configuration));
    }

    function error(key) {
        if (errors != null && errors[`user_configuration.${key}`] != null) {
            return errors[`user_configuration.${key}`].msg
        }
        return null;
    }

    if (!user_configuration) {
        return (
            <Loader />
        )
    }

    const { order_delivery } = user_configuration;

    return (
        <React.Fragment>
             <Grid container spacing={4} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} component="h6" lineheight="normal">
                            Pengaturan Persediaan
                        </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={3}>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Gunakan Surat Jalan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Persediaan hanya berkurang ketika surat jalan dibuat, pengaturan default persediaan berkurang ketika pesanan dibuat.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={order_delivery}
                                        onChange={onToggle.bind(this, !order_delivery)}
                                        name="order_delivery"
                                        color="primary"
                                    />
                                    }
                                />
                            </FormGroup>
                        </Grid> 
                    </Grid>
                    {/* <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Cetak Alamat Cabang
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Cetak alamat cabang yang berbeda-beda pada faktur.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={print_store_address}
                                        onChange={onToggle.bind(this, !print_store_address)}
                                        name="print_store_address"
                                        color="primary"
                                    />
                                    }
                                />
                            </FormGroup>
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Informasi Bank
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Masukan informasi transfer bank.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{ overflowX: 'scroll', border: "1px solid #ddd", position: 'relative', zIndex: 9, padding: '0 25px'}}>
                                <List component="div" sx={{marginBottom: '15px'}}>
                                    <ListSubheader sx={{display: 'flex', padding: 0}} component="div">
                                        <Grid container spacing={2}>
                                            <Grid item xs>
                                                Nama Bank
                                            </Grid>
                                            <Grid item xs>
                                                Nama Pemilik
                                            </Grid>
                                            <Grid item xs>
                                                No. Rekening
                                            </Grid>
                                            <Grid item xs={1}>
                                            </Grid>
                                        </Grid>
                                    </ListSubheader>
                                    {
                                        user_configuration_banks.map((row, index) => {
                                            if (row._destroy) {
                                                return null;
                                            }
                                            return (
                                                <ListItem key={index} sx={{alignItems: "normal", padding: '15px 0'}} disableGutters divider={index !== user_configuration_banks.length - 1}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs>
                                                            <TextField
                                                                fullWidth
                                                                error={!!error(`user_configuration_banks[${index}].bank_name`)}
                                                                name="bank_name"
                                                                placeholder="Nama Bank"
                                                                value={row.bank_name}
                                                                variant="outlined"
                                                                helperText={error(`user_configuration_banks[${index}].bank_name`)}
                                                                onChange={onUpdateBanks.bind(this, index)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <TextField
                                                                fullWidth
                                                                error={!!error(`user_configuration_banks[${index}].bank_account_holder`)}
                                                                name="bank_account_holder"
                                                                placeholder="Nama Pemilik"
                                                                value={row.bank_account_holder}
                                                                variant="outlined"
                                                                helperText={error(`user_configuration_banks[${index}].bank_account_holder`)}
                                                                onChange={onUpdateBanks.bind(this, index)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <TextField
                                                                fullWidth
                                                                error={!!error(`user_configuration_banks[${index}].bank_account_number`)}
                                                                name="bank_account_number"
                                                                placeholder="No. Rekening"
                                                                value={row.bank_account_number}
                                                                variant="outlined"
                                                                helperText={error(`user_configuration_banks[${index}].bank_account_number`)}
                                                                onChange={onUpdateBanks.bind(this, index)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <DelButton 
                                                                variant="contained"
                                                                size="large"
                                                                fullWidth
                                                                disableElevation
                                                                onClick={onRemoveBank.bind(this, index)} >
                                                                Hapus
                                                            </DelButton>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </Paper>
                        </Grid> 
                    </Grid> */}
                </Grid>
               <Grid item container sx={{marginBottom: '15px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onEdit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
            </Grid>
        </React.Fragment>
    )
}

const AddButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));


const DelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`,
    height: 56
}));