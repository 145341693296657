import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as supplierActions from '../../../../../../actions/supplierActions';
import { 
        CircularProgress,
        Button,
        Typography,
        Paper,
        Box,
        Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../../Helper/Loader';
import PurchaseOrder from './PurchaseOrder';

export default function Show() {
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {supplier} = useSelector(state => ({
        ...state.supplier
    }), shallowEqual); 


    useEffect(() => {
        dispatch(supplierActions.onLoadSupplier(id));
        return () => {
            dispatch(supplierActions.onUnloadSupplier());
        };
    },[dispatch, id]);


    if (!supplier) {
        return (
            <Loader/>
        )
    }


    const { name, sales, email, telephone, fax, phone, address, payments } = supplier;

    return (
        <ContentBox>
            <ContentPaper elevation={3}>
                <Grid container spacing={6} direction="column">
                    <Grid item container>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                Informasi
                            </Typography>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                   <Grid item container direction="column" spacing={3}>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Nama Supplier
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {name}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Sales
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {sales}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    E-mail
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {email}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    No. HP
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {phone}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    No. Telepon
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {telephone}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    No. Fax
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {fax}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Alamat
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    <span dangerouslySetInnerHTML={{__html: address}} />
                                </Typography>
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </ContentPaper>
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                        <WhiteButton 
                            variant="contained"
                            size="large"
                            disableElevation
                            disabled={!payments}
                            onClick={ () => {dispatch(supplierActions.onShowPurchaseOrders())}}>
                                Pembayaran
                        </WhiteButton>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <WhiteButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    onClick={ () => {navigate(`/suppliers`)}}>
                                    Kembali
                                </WhiteButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={ () => {navigate(`/suppliers/${id}/edit`);}}
                                    disableElevation>
                                    Ubah Supplier
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
            <PurchaseOrder />
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    paddingBottom: 15,
    marginTop: 25
}));
const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const WhiteButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));