import React, {useState} from "react";
import {
    InputBase,
    Paper,
    Typography,
    Grid,
    Box,
    IconButton,
    FormControl, 
    OutlinedInput,
    Button,
    TextField,
    Select,
    MenuItem
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { AddBoxTwoTone, Search, AddRounded } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/id' 

export default function Header({query, setQuery, stores}) {
    let navigate = useNavigate();
    const [search, setSearch] = useState(query.search);
    const [value, setValue] = React.useState([null, null]);
    function onSearch(e) {
        var enterKey = 13;
        if (e.which === enterKey) {
            e.target.blur(); 
            const newQuery = {...query};
            newQuery.page = 0;
            newQuery.search = search;
            setQuery(newQuery)
        }
    }

    function onSelectStore(event) {
        const value = event.target.value;
        const newQuery = {...query};
        newQuery.store_id = value;
        setQuery(newQuery)
    }

    const onSelectStartDate = (date) => {
        const newQuery = {...query};
        newQuery.start_date = moment(date).format('YYYY[-]MM[-]DD');
        setQuery(newQuery)
    };

    const onSelectEndDate = (date) => {
        const newQuery = {...query};
        newQuery.end_date = moment(date).format('YYYY[-]MM[-]DD');
        setQuery(newQuery)
    };
  

    return (
        <Box>
            <MobileBox>
                <HeaderBox>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Box>
                                <Typography fontWeight={500} variant="h6">
                                    Retur Pengiriman
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <IconButton color="primary">
                                <AddBoxTwoTone />
                            </IconButton>
                        </Grid>
                    </Grid>
                </HeaderBox>
                <Box>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Cari"
                        />
                    </FormControl>
                </Box>
            </MobileBox>
            <DesktopBox>
                <HeaderBox>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={8} alignItems="center" container spacing={3}>
                            <Grid item>
                                <Box>
                                    <Typography fontWeight={500} variant="h6">
                                        Retur Pengiriman
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs>
                                <FormControl
                                    variant="outlined"
                                    size="small">
                                    <Select
                                        value={query.store_id}
                                        onChange={onSelectStore.bind(this)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}>
                                        <MenuItem value={''} >
                                            Semua Cabang
                                        </MenuItem>
                                        {
                                            stores.map((option, index) => {
                                                return(
                                                    <MenuItem key={index} value={option._id}>
                                                        {option.name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs container spacing={3}>
                            <Grid item xs>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        openTo="year"
                                        value={Date.parse(query.start_date)}
                                        format="dd/MM/yyyy"
                                        onChange={onSelectStartDate}
                                        label="Tgl. Awal"
                                        renderInput={(params) => <TextField variant="standard" size="small" fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        openTo="year"
                                        value={Date.parse(query.end_date)}
                                        format="dd/MM/yyyy"
                                        onChange={onSelectEndDate}
                                        label="Tgl. Akhir"
                                        renderInput={(params) => <TextField variant="standard" size="small" fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </HeaderBox>
                <HeaderPaper elevation={3}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <SearchField
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={onSearch}>
                                <SearchIconWrapper>
                                    <Search />
                                </SearchIconWrapper>
                                <StyledInputBase
                                placeholder="Cari…"/>
                            </SearchField>
                        </Grid>
                        <Grid item>
                            {/* <Button 
                                variant="contained" 
                                color="primary"
                                disableElevation
                                startIcon={<AddRounded />}
                                onClick={(e) => navigate('/purchase-orders/new')} >
                                Tambah Pembelian
                            </Button>  */}
                        </Grid>
                    </Grid>
                </HeaderPaper>
            </DesktopBox>
        </Box>
    )
}

const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const MobileBox = styled(Box)(({theme}) => ({
    display: 'none',
    [theme.breakpoints.down('sm')]: {
        display: 'block'
    },
}));

const DesktopBox = styled(Box)(({theme}) => ({
    display: 'block',
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
}));

const HeaderPaper = styled(Paper)(({theme}) => ({
    padding: 15
}));

const SearchField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));
  
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
  
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));