import React, { useRef, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderActions from '../../../../../../actions/purchaseOrderActions';
import { 
        InputBase,
        Typography,
        Box,
        Paper,
        Grid,
        TextField,
        InputAdornment,
        Alert,
        Divider,
        Button,
        Tooltip,
        Switch,
        Table,
        TableHead,
        TableBody,
        TableRow,
        TableCell
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { MoveDownTwoTone, ReceiptTwoTone, StickyNote2TwoTone, HelpCenterTwoTone, HelpTwoTone, PercentTwoTone, NumbersTwoTone } from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import { tooltipClasses } from '@mui/material/Tooltip';

const QuantityFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        disabled={props.disabled}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
        InputProps={props.InputProps}
      />
));

const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>,
            inputProps: {
                style: {
                    textAlign: 'right', // Align text to the right
                },
            },
        }}
        placeholder="0"
        disabled={props.disabled}
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

const PercentageFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    {props.checked ? '%' : 'Rp' }
                </Box>
            </InputAdornment>,
            endAdornment: 
                <InputAdornment position="end">
                            <MaterialUISwitch
                                checkedIcon={
                                    <SwitchBox>
                                        <PercentTwoTone fontSize="12px" color="#fffff" />
                                    </SwitchBox>
                                }
                                icon={
                                    <SwitchBox>
                                        <NumbersTwoTone fontSize="12px" color="#fffff" />
                                    </SwitchBox>
                                }
                                checked={props.checked}
                                onChange={props.onToggle}
                                color="primary"
                            />
                </InputAdornment>,
            inputProps: {
                style: {
                    textAlign: 'right', // Align text to the right
                },
            },
        }}
        placeholder="0"
        fullWidth={props.fullWidth}
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        onFocus={props.onFocus}
        decimalScale={props.checked ? 3 : 2 }
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

export default function Product({error}) {   
    const dispatch = useDispatch();
    const {purchase_order, errors} = useSelector(state => ({
        ...state.purchase_order
    }), shallowEqual); 
    const [totalOpen, setTotalOpen] = React.useState(false);
    const [currentFocus, setCurrentFocus] = useState("");

    const { _id, purchase_order_items, status, note, subtotal, discount, grand_total, grand_total_return, has_returns, has_payments, grand_total_payment, due_total } = purchase_order;

    const handleTotalClose = () => {
        setTotalOpen(false);
    };
    
    const handleTotalOpen = () => {
        setTotalOpen(true);
    };

    function onUpdateOrderedField(index, event) {
        if (currentFocus === "ordered") {
            console.log(index)
            const key = event.target.name;
            const value = event.target.value;
            const newPurchaseOrder = {...purchase_order};
            const currentItem = newPurchaseOrder.purchase_order_items[index];
            currentItem[key] = value;
            dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
        }
    }

    function onUpdateReceivedField(index, event) {
        if (currentFocus === "received") {
            const key = event.target.name;
            const value = event.target.value;
            const newPurchaseOrder = {...purchase_order};
            const currentItem = newPurchaseOrder.purchase_order_items[index];
            currentItem[key] = value;
            if (currentItem.discount_type === "amount") {
                currentItem.net_price = currentItem.unit_price - currentItem.discount_amount
            } else {
                currentItem.net_price = currentItem.unit_price - ( currentItem.unit_price * (Number(currentItem.discount_percent / 100)))
            }
            currentItem.row_total = Number(currentItem.net_price) * Number(currentItem.received_qty);
            const subtotal = purchase_order_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
            const grand_total = Number(subtotal)
            newPurchaseOrder.subtotal = subtotal;
            newPurchaseOrder.grand_total = grand_total;
            dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
        }
    }

    function onUpdatePriceField(index, event) {
        if (currentFocus === "price") {
            const key = event.target.name;
            const value = event.target.value;
            const newPurchaseOrder = {...purchase_order};
            const currentItem = newPurchaseOrder.purchase_order_items[index];
            currentItem[key] = Number(value);
            if (currentItem.discount_type === "amount") {
                currentItem.net_price = Number(currentItem.unit_price) - Number(currentItem.discount_amount)
            } else {
                currentItem.net_price = Number(currentItem.unit_price) - ( Number(currentItem.unit_price) * (Number(currentItem.discount_percent / 100)))
            }
            currentItem.row_total = Number(currentItem.net_price) * Number(currentItem.received_qty);
            const subtotal = purchase_order_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
            const grand_total = Number(subtotal)
            // const grand_total = Number(subtotal) - Number(purchase_order.discount) - Number(purchase_order.return);
            newPurchaseOrder.subtotal = subtotal;
            newPurchaseOrder.grand_total = grand_total;
            dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
        }
    }

    function onUpdateSubtotalField(index, event) {
        if (currentFocus === "row_total") {
            const key = event.target.name;
            const value = event.target.value;
            const newPurchaseOrder = {...purchase_order};
            const currentItem = newPurchaseOrder.purchase_order_items[index];
            currentItem[key] = value;
            currentItem.net_price = Number(currentItem.row_total) / Number(currentItem.received_qty);
            if (currentItem.discount_type === "percent") {
                currentItem.unit_price = currentItem.net_price * (100/(100-currentItem.discount_percent));
            } else {
                currentItem.unit_price = currentItem.net_price + currentItem.discount_amount;
            }
            const subtotal = purchase_order_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
            const grand_total = Number(subtotal)
            newPurchaseOrder.subtotal = subtotal;
            newPurchaseOrder.grand_total = grand_total;
            dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
        }
    }

    function onUpdateDiscountField(index, event) {
        if (currentFocus === 'discount') {
            const value = event.target.value;
            const newPurchaseOrder = {...purchase_order};
            const currentItem = newPurchaseOrder.purchase_order_items[index];
            if (currentItem.discount_type === "percent") {
                currentItem.discount_percent = value;
                const discount = currentItem.unit_price - ( currentItem.unit_price * (Number(currentItem.discount_percent / 100)));
                if (discount <= 0) {
                    currentItem.net_price = 0;
                } else {
                    currentItem.net_price = discount;
                }
            } else {
                currentItem.discount_amount = value;
                const discount = currentItem.unit_price - currentItem.discount_amount;
                if (discount <= 0) {
                    currentItem.net_price = 0;
                } else {
                    currentItem.net_price = discount;
                }
            }
    
            currentItem.row_total = Number(currentItem.net_price) * Number(currentItem.received_qty);
            const subtotal = purchase_order_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
            const grand_total = Number(subtotal)
            newPurchaseOrder.subtotal = subtotal;
            newPurchaseOrder.grand_total = grand_total;
            dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
        }
    }

    function onUpdateNoteField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newPurchaseOrder = {...purchase_order};
        newPurchaseOrder[key] = value;
        dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
    }

    function onToggleDiscount(index, event) {
        const newPurchaseOrder = {...purchase_order};
        const currentItem = newPurchaseOrder.purchase_order_items[index];
        if (currentItem.discount_type === "percent") {
            currentItem.discount_type = "amount";
            currentItem.discount_amount = Number(currentItem.discount_percent);
            currentItem.discount_percent = 0;
            const discount = currentItem.unit_price - currentItem.discount_amount
            if (discount <= 0) {
                currentItem.net_price = 0;
            } else {
                currentItem.net_price = discount;
            }
        } else {
            currentItem.discount_type = "percent"
            currentItem.discount_percent = Number(currentItem.discount_amount);
            currentItem.discount_amount = 0;
            const discount = currentItem.unit_price - ( currentItem.unit_price * (Number(currentItem.discount_percent / 100)))
            if (discount <= 0) {
                currentItem.net_price = 0;
            } else {
                currentItem.net_price = discount;
            }
        }
        currentItem.row_total = Number(currentItem.net_price) * Number(currentItem.received_qty);
        const subtotal = purchase_order_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
        const grand_total = Number(subtotal)
        newPurchaseOrder.subtotal = subtotal;
        newPurchaseOrder.grand_total = grand_total;
        dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
    }
  
    function onFocus(key) {
        setCurrentFocus(key)
    }

    function onBlur(key) {
        setCurrentFocus(null)
    }

    function onShowPayments() {
        dispatch(purchaseOrderActions.onShowPayments());
    }

    function onShowReturns() {
        dispatch(purchaseOrderActions.onShowReturns());
    }

    function InStock(item) {
        if (status === 'requested' || status === 'ordered') {
            return (
                <QuantityFormat
                    fullWidth
                    name="in_stock"
                    value={item.in_stock}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                          style: {
                            textAlign: 'right', // Align text to the right
                          },
                        },
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.in_stock}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function Requested(item) {
        if (status === 'requested' || status === 'ordered') {
            return (
                <QuantityFormat
                    fullWidth
                    name="requested_qty"
                    value={item.requested_qty}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                          style: {
                            textAlign: 'right', // Align text to the right
                          },
                        },
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.requested_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function Ordered(item, index) {
        if (status === 'requested' || status === 'ordered') {
            return (
                <QuantityFormat
                    fullWidth
                    error={!!error(`purchase_order_items[${index}].ordered_qty`)}
                    helperText={error(`purchase_order_items[${index}].ordered_qty`)}
                    name="ordered_qty"
                    disabled={receivedStatus}
                    value={item.ordered_qty}
                    onFocus={onFocus.bind(this, "ordered")}
                    onBlur={onBlur.bind(this)}
                    variant="outlined"
                    onChange={onUpdateOrderedField.bind(this, index)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                          style: {
                            textAlign: 'right', // Align text to the right
                          },
                        },
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.ordered_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function Received(item, index) {
        if (status === 'ordered') {
            return (
                <QuantityFormat
                    fullWidth
                    error={!!error(`purchase_order_items[${index}].received_qty`)}
                    helperText={error(`purchase_order_items[${index}].received_qty`)}
                    name="received_qty"
                    value={item.received_qty}
                    onFocus={onFocus.bind(this, "received")}
                    onBlur={onBlur.bind(this)}
                    variant="outlined"
                    onChange={onUpdateReceivedField.bind(this, index)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                        inputProps: {
                          style: {
                            textAlign: 'right', // Align text to the right
                          },
                        },
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.received_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function EndStock(item, index) {

        return (
            <NumberFormat
                value={Number(item.in_stock) + Number(item.received_qty)}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function UnitPrice(item, index) {
        if (status === 'ordered') {
            return (
                <CurrencyFormat
                    name="unit_price"
                    fullWidth
                    error={!!error(`purchase_order_items[${index}].unit_price`)}
                    helperText={error(`purchase_order_items[${index}].unit_price`)}
                    value={item.unit_price}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                    onFocus={onFocus.bind(this, "price")}
                    onBlur={onBlur.bind(this)}
                    onChange={onUpdatePriceField.bind(this, index)}
                />
            )
        }

        return (
            <NumberFormat
                value={item.unit_price}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function Discount(item, index) {
        if (status === 'ordered') {
            return (
                <PercentageFormat
                    fullWidth
                    value={item.discount_type === 'percent' ? item.discount_percent : item.discount_amount}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                    onToggle={onToggleDiscount.bind(this, index)}
                    onFocus={onFocus.bind(this, "discount")}
                    onBlur={onBlur.bind(this)}
                    checked={item.discount_type === 'percent'}
                    onChange={onUpdateDiscountField.bind(this, index)}
                />
            )
        }

        return (
            <NumberFormat
                value={item.discount_type === 'percent' ? item.discount_percent : item.discount_amount}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={item.discount_type === 'percent' ? "" : "Rp "}
                suffix={item.discount_type === 'percent' ? "%" : ""}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function NetPrice(item, index) {
        if (status === 'ordered') {
            return (
                <CurrencyFormat
                    fullWidth
                    name="net_price"
                    value={item.net_price}
                    disabled
                    variant="outlined"
                    onFocus={onFocus.bind(this, "received")}
                    onBlur={onBlur.bind(this)}
                    onChange={() => {return}}
                />
            )
        }

        return (
            <NumberFormat
                value={item.net_price}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function Subtotal(item, index) {
        if (status === 'ordered') {
            return (
                <CurrencyFormat
                    fullWidth
                    name="row_total"
                    error={!!error(`purchase_order_items[${index}].row_total`)}
                    helperText={error(`purchase_order_items[${index}].row_total`)}
                    value={item.row_total}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                    onFocus={onFocus.bind(this, "row_total")}
                    onBlur={onBlur.bind(this)}
                    onChange={onUpdateSubtotalField.bind(this, index)}
                />
            )
        }

        return (
            <NumberFormat
                value={item.row_total}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function hasOptions(item) {

        if (item && item.product_options) {
            var optionsKeys = [];
            item.product_options.sort((a, b) => {
                const nameA = a.sort_order;
                const nameB = b.sort_order;
                if (nameA < nameB) {
                return -1;
                }
                if (nameA > nameB) {
                return 1;
                }
            
                // names must be equal
                return 0;
            }).map((option) => {
                if (!optionsKeys.includes(option.uom.toLowerCase())) {
                    optionsKeys.push(option.uom.toLowerCase());
                }
            })

            var options = [];
            optionsKeys.map((key, index)  => {
                const option = item.product_options.find(opt => opt.sort_order === index);
                if (index === 0) {
                    options.push({name: option.uom, unit: ""});
                } else {
                    const firstOption = item.product_options.find(opt => opt.sort_order === 0);
                    options.push({name: option.uom, unit: option.unit + " " + firstOption.uom});
                }
            })

            if (options.length >= 2) {
                return (
                    <Tooltip
                        title={itemOptions(options)} >
                        <HelpCenterTwoTone color="primary" sx={{ marginLeft: '10px', marginBottom: '13px' }} />
                    </Tooltip>
                )
            }
            
            return null;
        }

        return null;
    }
    
    function itemOptions(options) {
        return (
            <Box>
                <Typography fontWeight={700}>
                    Produk Opsi
                </Typography>
                {options.map((option, index) => {
                    return (
                        <Box key={index} sx={{display: 'flex'}}>
                            <Typography fontWeight={500}>
                                {option.name} 
                            </Typography>
                            <Typography sx={{marginLeft: '5px'}}>
                                {option.unit ? `(${option.unit})` : null}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    function total() {
        return (
            <Box>
                <Grid container spacing={1} direction="column">
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={subtotal}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Diskon
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={discount}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    {
                        has_returns && <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Total Return
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={grand_total_return}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    }
                    <Grid item >
                        <Divider />
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={700}>
                                Total Tagihan
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={grand_total}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    {
                        has_payments && <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={700}>
                                Total Pembayaran
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={grand_total_payment}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Box>
        )
    }

    function Footer() {
        if (status === 'requested') {
            return (
                <Grid container spacing={3} >  
                    <Grid item>
                        <CommentField
                            value={note}
                            onChange={onUpdateNoteField}
                            >
                            <IconWrapper>
                                <StickyNote2TwoTone />
                            </IconWrapper>
                            <StyledInputBase
                                name="note"
                                disabled={status === 'canceled' || status === 'paid'}
                                placeholder="Note…"/>
                        </CommentField>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid container spacing={3} justifyContent="flex-end">
                <Grid item>
                    <Button disabled={!has_payments} onClick={onShowPayments} variant="outlined" startIcon={<ReceiptTwoTone />}>
                        Pembayaran
                    </Button>
                </Grid>
                <Grid item>
                    <Button disabled={!has_returns} onClick={onShowReturns} variant="outlined" startIcon={<MoveDownTwoTone />}>
                        Pengembalian
                    </Button>
                </Grid>
                <Grid item>
                    <Divider orientation="vertical" />
                </Grid>
                <Grid item>
                    <CommentField
                        value={note}
                        onChange={onUpdateNoteField}
                        >
                        <IconWrapper>
                            <StickyNote2TwoTone />
                        </IconWrapper>
                        <StyledInputBase
                            name="note"
                            disabled={canceledStatus || status === 'paid'}
                            placeholder="Catatan…"/>
                    </CommentField>
                </Grid>
                <Grid item container xs spacing={3} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography sx={{display: 'flex'}} lineheight="normal" fontSize={16} fontWeight={700}>
                            Total PO 
                            <LightTooltip open={totalOpen} placement='right' onClose={handleTotalClose} onOpen={handleTotalOpen} title={total()}>
                                <HelpTwoTone color="primary" />
                            </LightTooltip>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <NumberFormat
                            value={grand_total}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography lineheight="normal" fontSize={16} fontWeight={700}>
                            {value}
                        </Typography>}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

   

    let titleAlign = 'right'

    let requestedStatus = status === 'requested';
    let orderedStatus = status === 'ordered';
    let receivedStatus = status === 'received';
    // let approvedStatus = status === 'approved';
    let onDueStatus = status === 'on_due';
    let canceledStatus = status === 'canceled';
    let paidStatus = status === 'paid';
    let partiallyPaidStatus = status === 'partially_paid';

    // let tableGrid = '30% 12% 12% 12% 12% 12% 12% 12% 12% 15%'
    let tableGrid = '30% 12% 12% 12% 12% 12% 14% 13% 14% 16%'
    let maxName = 35
    if (status === 'requested') {
        maxName = 40
        tableGrid = '46% 18% 18% 18%'
    } else if (status === 'ordered') {
        maxName = 40
        tableGrid = '34% 14% 16% 19% 16% 19%'
    }    
    return (
        <ContentPaper elevation={3}> 
            <Grid container spacing={3} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Produk
                        </Typography>
                    </Grid>
                    <Grid item>
                        {/* {requestedStatus || orderedStatus && <Grid item>
                            <Alert severity="error">
                                Dibutuhkan
                            </Alert>
                        </Grid>} */}
                    </Grid>
                </Grid>
            </Grid>
            <Table sx={{
                 marginTop: '35px',
                display: 'grid',
                overflow: 'auto hidden',
                gridTemplateColumns: tableGrid,
                }}>
                <TableHead sx={{display: 'contents'}}>
                    <TableRow sx={{display: 'contents'}}>
                        <TableCell sx={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            position: 'sticky',
                            zIndex: 1,
                            left: 0,
                            background: '#fff',
                            }}>
                            Nama Barang
                    </TableCell>
                    {(requestedStatus || receivedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                            Stok Awal
                        </TableCell>
                    )}
                        {(requestedStatus || receivedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                            Diajukan
                        </TableCell>
                    )}
                    {(requestedStatus || receivedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                            Dipesan
                        </TableCell>
                    )}
                    {(receivedStatus || orderedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                            Diterima
                        </TableCell>
                    )}
                    {(receivedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                            Stok Akhir
                        </TableCell>
                    )}
                    {(receivedStatus || orderedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                            Harga Pokok
                        </TableCell>
                    )}
                    {(receivedStatus || orderedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                            Diskon
                        </TableCell>
                    )}
                    {(receivedStatus || orderedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                        
                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                            Harga Netto
                        </TableCell>
                    )}
                    {(receivedStatus || orderedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                        <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                            Subtotal
                        </TableCell>
                    )}
                    </TableRow>
                </TableHead>
                <TableBody sx={{display: 'contents'}}>
                {
                    purchase_order_items.map((item, index) => {
                        return (
                            <TableRow key={index} sx={{display: 'contents'}}>
                                <TableCell sx={{
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    position: 'sticky',
                                    zIndex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    left: 0,
                                    background: '#fff'
                                    }}
                                    tabIndex={-1}
                                    >
                                    <Tooltip
                                        title={item.name}
                                        arrow
                                    >
                                        <Box>
                                            <Typography sx={{ fontWeight: 700 }} fontSize={16} lineHeight="normal">
                                                {item.name.length > maxName
                                                    ? `${item.name.substring(0, maxName)}...`
                                                    : item.name}
                                            </Typography>
                                            <Typography fontSize={11} lineHeight="normal" sx={{ color: '#999' }}>
                                                SKU #{item.sku}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                    {hasOptions(item)}
                                </TableCell>
                            {(requestedStatus || receivedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    {InStock(item)}
                                </TableCell>
                            )}
                            {(requestedStatus || receivedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    {Requested(item)}
                                </TableCell>
                            )}
                            {(requestedStatus || receivedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    {Ordered(item, index)}
                                </TableCell>
                            )}
                            {(receivedStatus || orderedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    {Received(item, index)}
                                </TableCell>
                            )}
                            {(receivedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    {EndStock(item, index)}
                                </TableCell>
                            )}
                            {(receivedStatus || orderedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    {UnitPrice(item, index)}
                                </TableCell>
                            )}
                            {(receivedStatus || orderedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    {Discount(item, index)}
                                </TableCell>
                            )}
                            {(receivedStatus || orderedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    {NetPrice(item, index)}
                                </TableCell>
                            )}
                            {(receivedStatus || orderedStatus || onDueStatus || canceledStatus || paidStatus || partiallyPaidStatus) && (
                                <TableCell align={titleAlign} sx={{paddingRight: 0}}>
                                    {Subtotal(item, index)}
                                </TableCell>
                            )}
                            </TableRow>
                        );
                    })
                }
                </TableBody>
            </Table>
            <Divider sx={{marginTop: '25px', marginBottom: '30px'}} />
            {Footer()}
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const CommentField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));

const IconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} sx={{marginLeft: '5px'}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      width: 325,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    },
  }));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 60,
    height: 34,
    padding: 12,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const SwitchBox = styled(Box)(({ theme }) => ({
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    backgroundColor: '#001e3c',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));