import * as types from '../actions/actionTypes';

const defaultState = {
	
}

export default (state = defaultState, action) => {

  
	switch(action.type) {
        case types.STORE_POS_SHIFTS_LOADED:
			return {
                ...state,
                stores: action.payload ? action.payload.stores : null,
                store_pos_shifts: action.payload ? action.payload.store_pos_shifts : null,
				store_pos_shifts_count: action.error ? null : action.payload.store_pos_shifts_count,
            };
        case types.STORE_POS_SHIFTS_REPORT_LOADED:
            return {
                ...state,
                store_pos_shifts_report: action.payload ? action.payload.store_pos_shifts_report : null
            };
        case types.STORE_POS_SHIFT_LOADED:
            return {
                ...state,
                store_pos_shift: action.payload ? action.payload.store_pos_shift : null,
                onStorePosShiftOrders: false,
                onStorePosShiftConfirm: false
            };
        case types.STORE_POS_SHIFT_UNLOADED:
            return {
                ...state,
                store_pos_shift: null,
                onStorePosShiftOrders: false,
                onStorePosShiftConfirm: false
            };
        case types.STORE_POS_SHIFT_ORDERS_SHOWED:
            return {
                ...state,
                onStorePosShiftOrders: true,
            };
        case types.STORE_POS_SHIFT_ORDERS_HIDED:
            return {
                ...state,
                onStorePosShiftOrders: false,
                store_pos_shift_orders: null,
            };
        case types.STORE_POS_SHIFT_ORDERS_LOADED:
            return {
                ...state,
                store_pos_shift_orders: action.payload ? action.payload.store_pos_shift_orders : null,
                store_pos_shift_orders_count: action.payload ? action.payload.store_pos_shift_orders_count : null,
            };
		// case types.ASYNC_START:
        //     if ( action.subtype === types.STORE_ADJUSTMENT_PRODUCT_SELECTED
        //         ) {
        //             return { 
        //                 ...state, 
        //                 productsFetching: true };
        //         };
        //     if ( action.subtype === types.STORE_ADJUSTMENT_CREATED ||
        //          action.subtype === types.STORE_ADJUSTMENT_EDITED
        //         ) {
        //         return { 
        //             ...state, 
        //             inProgress: true };
		// 	};
        //     return state;
		default:
			return state;
	}
}