import React, { useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as productActions from '../../../../../../actions/productActions';
import { 
        CircularProgress,
        Button,
        Grid,
        Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Image from './Image';
import Information from './Information';
import Variant from './Variant';
import Price from './Price';
import Manage from './Manage';
import Generate from './Variant/Generate';
import Loader from '../../../../../Helper/Loader';
import Confirm from './Confirm';

export default function Form() {
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {product, categories, newConfigurations, autoSKU, errors, inProgress} = useSelector(state => ({
        ...state.product
    }), shallowEqual); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        if (id) {
			dispatch(productActions.onProductEdit(id));
		} else {
			dispatch(productActions.onProductNew());
		}
        return () => {
            dispatch(productActions.onUnloadForm())
        };
    },[dispatch, id]);
    
    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(productActions.onUpdateField(key, value));
    }

    function onUpdateName(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(productActions.onUpdateName(key, value));
    }

    function onUpdateUOM(value) {
        console.log(value)
        dispatch(productActions.onUpdateField('uom', value));
    }

    function onToggleChange(value, event) {
		const key = event.target.name;
        dispatch(productActions.onUpdateField(key, value));
    }

    function onToggleSKU(value) {
        dispatch(productActions.onToggleAutoSKU(value));
    }

    function onSubmit() {
        // if (slug && newConfigurations) {
        //     dispatch(productActions.onEditConfirm());
        // } else {
            if (id) {
                dispatch(productActions.onEdit(id, product));
            } else {
                dispatch(productActions.onCreate(product));
            }
        // }
    }
    
    function error(key) {
        if (errors != null && errors[`product.${key}`] != null) {
            return errors[`product.${key}`].msg
        }
        return null;
    }

    if (!product || !categories) {
        return (
            <Loader />
        )
    }

    function getDestroyedOptions() {
		return product.product_options_attributes.filter(el => {
	    	return el._destroy !== true;
	  	}).length;
	}

    function getDestroyedVariants() {
        return product.product_variants.filter(el => {
          return el._destroy !== true;
        }).length;
    }

    function getManage() {
        if ((product.id && getDestroyedVariants() !== 0) || (product.id && getDestroyedOptions() !== 0)) {
            return null
        } else {
            return (
                <Manage
                    product={product}
                    autoSKU={autoSKU}
                    onToggle={onToggleSKU}
                    onChange={onUpdateField}
                    onUpdateUOM={onUpdateUOM}
                    onToggleChange={onToggleChange}
                    error={error} />
            )
        }
    }

    return (
        <ContentBox>
            {console.log(product)}
            <Image product={product} 
                error={error}/>
            <Information
                product={product}
                categories={categories}
                onChange={onUpdateField}
                onUpdateName={onUpdateName}
                error={error} />
            <Variant error={error} />
            <Price 
                product={product}
                onChange={onUpdateField}
                onToggleChange={onToggleChange}
                error={error}/>
            {getManage()}
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    disabled={inProgress}
                                    onClick={ () => {navigate(`/products`)}}>
                                    Batal
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
            <Generate />
            <Confirm />
        </ContentBox>
    )
}

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));

const ContentBox = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));

