import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as supplierActions from '../../../../../../../actions/supplierActions';
import { TableContainer,
         Table,
         TableHead,
         TableRow,
         TableCell,
         TableBody,
         Paper,
         Typography,
         TableSortLabel,
         Grid,
         Box,
         Button,
         Checkbox,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { useParams } from "react-router-dom";
import { ReceiptTwoTone } from '@mui/icons-material';
import Loader from '../../../../../../Helper/Loader';
import NumberFormat from 'react-number-format';
import Payment from './Payment';
import moment from 'moment';
import 'moment/locale/id' 

const headCells = [
    { id: 'invoice_id', numeric: false, sortable: true, disablePadding: false, label: 'No. Invoice' },
    { id: 'increment_id', numeric: false, sortable: true, disablePadding: false, label: 'ID Pembelian' },
    { id: 'due_date', numeric: false, sortable: false, disablePadding: false, label: 'Jatuh Tempo' },
    { id: 'grand_total', numeric: true, sortable: false, disablePadding: false, label: 'Total Pembelian' },
    { id: 'return_total', numeric: true, sortable: false, disablePadding: false, label: 'Total Pengembalian' },
    { id: 'paid_total', numeric: true, sortable: false, disablePadding: false, label: 'Total Pembayaran' },
    { id: 'due_total', numeric: true, sortable: false, disablePadding: false, label: 'Total Jatuh Tempo' }
];
 
  
function EnhancedTableHead({ order, orderBy, onRequestSort, onSelectAll, numSelected, rowCount }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <ItemTableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAll}
                        inputProps={{
                        'aria-label': 'select all desserts',
                        }}
                    />
                </ItemTableCell>
                {headCells.map((headCell) => {
                    if (headCell.sortable) {
                        return (
                            <ItemTableCell
                                key={headCell.id}
                                sx={{fontWeight: 700}}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                </TableSortLabel>
                            </ItemTableCell>
                        )
                    }
                    return (
                        <ItemTableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </ItemTableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

export default function Content() {
    const dispatch = useDispatch();
    let { id } = useParams();
    const { purchase_orders, purchase_order_payment } = useSelector(state => ({
        ...state.supplier
    }), shallowEqual);

    const [open, setOpen] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
   
    const [query, setQuery] = useState({});

    const { purchase_order_payment_items, subtotal } = purchase_order_payment;

    useEffect(() => {
        dispatch(supplierActions.onLoadPurchaseOrders(id, query))
    },[query]);

    const onRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (isAsc) {
          const newQuery = {...query};
          delete newQuery.sort_asc;
          newQuery.sort_desc = property;
          setQuery(newQuery)
        } else {
          const newQuery = {...query};
          delete newQuery.sort_desc;
          newQuery.sort_asc = property;
          setQuery(newQuery)
        }
    };

    function onSelectAll(event) {
        var newPurchaseOrderPaymentItems = [...purchase_order_payment_items];
        if (purchase_order_payment_items.length == purchase_orders.length) {
            newPurchaseOrderPaymentItems = [];
        } else {
            purchase_orders.map( purchase_order => {
                const { _id, due_amount, increment_id } = purchase_order;
                const purchaseOrderExist = purchase_order_payment_items.find(item => item.purchase_order_id === _id);
                if (!purchaseOrderExist) {
                    return newPurchaseOrderPaymentItems.push({ purchase_order_id: _id, due_amount: due_amount, increment_id });
                }
            })
        }
        const newPurchaseOrderPayment = {...purchase_order_payment};
        newPurchaseOrderPayment.purchase_order_payment_items = newPurchaseOrderPaymentItems;
        newPurchaseOrderPayment.subtotal = parseFloat(newPurchaseOrderPaymentItems.reduce((prev,next) => parseFloat(prev) + parseFloat(next.due_amount),0)).toFixed(2);
        newPurchaseOrderPayment.grand_total = parseFloat(newPurchaseOrderPayment.subtotal).toFixed(2);
        newPurchaseOrderPayment.payment_amount = parseFloat(newPurchaseOrderPayment.subtotal).toFixed(2);
        dispatch(supplierActions.onUpdatePurchaseOrderPayment(newPurchaseOrderPayment));
    }

    function onSelect(index, event) {
        const purchase_order = purchase_orders[index];
        const { _id, due_amount, increment_id } = purchase_order;
        var newPurchaseOrderPaymentItems = [...purchase_order_payment_items];
        const purchaseOrderExist = newPurchaseOrderPaymentItems.find(item => item.purchase_order_id === _id);
        if (purchaseOrderExist) {
            const purchaseOrderExistIndex = purchase_order_payment_items.findIndex(item => item.purchase_order_id === _id);
            newPurchaseOrderPaymentItems.splice(purchaseOrderExistIndex, 1);
        } else {
            newPurchaseOrderPaymentItems.push({ purchase_order_id: _id, due_amount: due_amount, increment_id });
        }
        const newPurchaseOrderPayment = {...purchase_order_payment};
        newPurchaseOrderPayment.purchase_order_payment_items = newPurchaseOrderPaymentItems;
        newPurchaseOrderPayment.subtotal = parseFloat(newPurchaseOrderPaymentItems.reduce((prev,next) => parseFloat(prev) + parseFloat(next.due_amount),0)).toFixed(2);
        newPurchaseOrderPayment.grand_total = parseFloat(newPurchaseOrderPayment.subtotal).toFixed(2);
        newPurchaseOrderPayment.payment_amount = parseFloat(newPurchaseOrderPayment.subtotal).toFixed(2);
        dispatch(supplierActions.onUpdatePurchaseOrderPayment(newPurchaseOrderPayment));
    }

    function onHide() {
        dispatch(supplierActions.onHidePurchaseOrders());
    }

    function onPayment(){
        dispatch(supplierActions.onShowPayment());
    };

    if (!purchase_orders || !purchase_order_payment_items) {
        return <Loader />
    }

    return(
        <Box sx={{height: '100%'}}>
            <TableContainer sx={{paddingBottom: '80px'}}>
                <Table sx={{width: '120%'}}>
                    <EnhancedTableHead
                    numSelected={purchase_order_payment_items.length}
                    order={order}
                    orderBy={orderBy}
                    rowCount={purchase_orders.length}
                    onSelectAll={onSelectAll}
                    onRequestSort={onRequestSort}
                    />
                    <TableBody>
                    {
                        purchase_orders.map((purchase_order, index) => {
                            const { _id, invoice_id, increment_id, due_date, grand_total, return_amount, paid_amount, due_amount} = purchase_order;
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const isSelected = purchase_order_payment_items.find(item => item.purchase_order_id === _id);
                            
                            return (
                                <TableRow
                                    hover
                                    onClick={onSelect.bind(this, index)}
                                    tabIndex={-1}
                                    key={index} >
                                    <ItemTableCell padding="checkbox">
                                        <Checkbox
                                        color="primary"
                                        checked={!!isSelected}
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                        />
                                    </ItemTableCell>
                                    <ItemTableCell component="th" scope="row">
                                        {invoice_id}
                                    </ItemTableCell>
                                    <ItemTableCell>
                                        {increment_id}
                                    </ItemTableCell>
                                    <ItemTableCell>
                                        {moment(due_date).format('LL')}
                                    </ItemTableCell>
                                    <ItemTableCell align="right">
                                        <NumberFormat
                                            value={grand_total}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                        />
                                    </ItemTableCell>
                                    <ItemTableCell align="right">
                                        <NumberFormat
                                            value={return_amount}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                        />
                                    </ItemTableCell>
                                    <ItemTableCell align="right">
                                        <NumberFormat
                                            value={paid_amount}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                        />
                                    </ItemTableCell>
                                    <ItemTableCell align="right">
                                        <NumberFormat
                                            value={due_amount}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                        />
                                    </ItemTableCell>
                                </TableRow>
                            );
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            <Paper elevation={3} sx={{position: 'fixed', bottom: 0, width: '100%', maxWidth: '100%'}}>
                <Grid container sx={{padding: '25px 35px'}} justifyContent="space-between">
                    <Grid item>
                        <WhiteButton 
                            variant="contained"
                            size="large"
                            disableElevation
                            onClick={onHide}>
                            Batal
                        </WhiteButton>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="contained"
                            size="large"
                            color="primary"
                            sx={{minWidth: 195}}
                            disabled={purchase_order_payment_items.length === 0}
                            onClick={onPayment}
                            disableElevation>
                                <Grid container sx={{lineHeight: 0}}>
                                    <Grid item>
                                        <ReceiptTwoTone /> 
                                    </Grid>
                                    <Grid item xs>
                                        <NumberFormat
                                            value={subtotal}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => <Typography textAlign="right" lineheight="normal" fontSize={16} fontWeight={700}>
                                            {value}
                                        </Typography>}
                                        />
                                    </Grid>
                                </Grid>
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Payment />
        </Box>
    )  
}


const ItemTableCell = styled(TableCell)(({theme}) => ({
    padding: '15px 35px'
}));

const WhiteButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));