import React, { useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeOrganizationActions from '../../../../../../actions/employeeOrganizationActions';
import { 
        CircularProgress,
        Button,
        Grid,
        Box,
        Alert,
        Typography,
        Paper,
        Switch,
        TextField,
        FormControlLabel,
        FormGroup,
        Autocomplete
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../../Helper/Loader';

export default function Form() {
    let navigate = useNavigate();
    let { id, parent_id } = useParams();
    const dispatch = useDispatch();
    const {employee_organization, parentOrganization, employee_organizations, errors, inProgress} = useSelector(state => ({
        ...state.employee_organization
    }), shallowEqual); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        if (id) {
			dispatch(employeeOrganizationActions.onEmployeeOrganizationEdit(id));
		} else {
			dispatch(employeeOrganizationActions.onEmployeeOrganizationNew(parent_id));
		}
        return () => {
            dispatch(employeeOrganizationActions.onUnloadForm())
        };
    },[dispatch, id]);
    
    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(employeeOrganizationActions.onUpdateField(key, value));
    }

    function onToggleChange(value, event) {
		const key = event.target.name;
        dispatch(employeeOrganizationActions.onUpdateField(key, value));
    }

    function onSelectEmployeeOrganization(option) {
        dispatch(employeeOrganizationActions.onUpdateField("parent_id", option._id));
    }

  
    function onSubmit() {
        // if (slug && newConfigurations) {
        //     dispatch(productActions.onEditConfirm());
        // } else {
            if (id) {
                dispatch(employeeOrganizationActions.onEdit(id, employee_organization));
            } else {
                // newEmployeeOrganization.parent_id = parentOrganization.id;
                dispatch(employeeOrganizationActions.onCreate(employee_organization));
            }
        // }
    }
    
    function error(key) {
        if (errors != null && errors[`employee_organization.${key}`] != null) {
            return errors[`employee_organization.${key}`].msg
        }
        return null;
    }


    if (!employee_organization) {
        return (
            <Loader />
        )
    }

    const { name, status, } = employee_organization;

    const employeeOrganizationValue = employee_organizations.find(opt => opt._id === employee_organization.parent_id);
    return (
        
        <ContentBox>
            {console.log(employee_organization)}
            <ContentPaper elevation={3}>
                <Grid container spacing={6} direction="column">
                    <Grid item container>
                        <Grid item xs>
                            <Typography variant="h6" fontWeight={700}>
                                Informasi Organisasi
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Alert severity="error">
                                Wajib
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={5}>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Suborganisasi Dari
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Menunjukan organisasi yang memimpin suborganisasi.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {/* <Typography fontSize={16} fontWeight={700} component="div">
                                    {is_root ? "-" : parentOrganization.name}
                                </Typography> */}
                                <Autocomplete
                                    options={employee_organizations.sort((a, b) => -b.name.localeCompare(a.name))}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.formatted}
                                    blurOnSelect
                                    filterSelectedOptions
                                    disableClearable
                                    onChange={(event, option) => {
                                        onSelectEmployeeOrganization(option)
                                    }}
                                    value={employeeOrganizationValue ? employeeOrganizationValue : null}
                                    renderInput={(params) => <TextField {...params} error={!!error('employee_organization_id')} helperText={error('employee_organization_id')} placeholder="Pilih Organisasi" variant="outlined" />}
                                />
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Nama Organisasi
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Masukan nama organisasi yang akan dibuat / diubah.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    error={!!error('name')}
                                    name="name"
                                    placeholder="Nama Organisasi"
                                    value={name}
                                    helperText={error('name')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    <Box fontSize={16} fontWeight={700}>
                                        Status Organisasi
                                    </Box>
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Aktif / Non-Aktifkan organisasi.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={status}
                                            onChange={onToggleChange.bind(this, !status)}
                                            name="status"
                                            color="primary"
                                        />
                                        }
                                        label={status ? 'Aktif' : 'Tidak Aktif'}
                                    />
                                </FormGroup>
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </ContentPaper>
            <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                <Grid item>
                </Grid>
                <Grid item>
                    <Grid container spacing={3}>
                        <Grid item>
                            <BackButton 
                                variant="contained"
                                size="large"
                                disableElevation
                                disabled={inProgress}
                                onClick={ () => {navigate(`/organizations`)}}>
                                Batal
                            </BackButton>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained"
                                size="large"
                                color="primary"
                                sx={{minWidth: 195}}
                                onClick={onSubmit}
                                disabled={inProgress}
                                disableElevation>
                                {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>  
        </ContentBox>
    )
}

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));

const ContentBox = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));


const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));
