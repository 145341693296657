import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderDeliveryReturnActions from '../../../../../../actions/orderDeliveryReturnActions';
import { 
    Typography,
    Box,
    Paper,
    Grid,
    Divider,
    TextField,
    Autocomplete,
    IconButton,
    Chip,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PublishedWithChangesTwoTone, AccessTimeTwoTone, CloseTwoTone } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import 'moment/locale/id' 
import { tooltipClasses } from '@mui/material/Tooltip';

export default function Information({error}) {    
    const dispatch = useDispatch();
    const {order_delivery_return, employees} = useSelector(state => ({
        ...state.order_delivery_return
    }), shallowEqual); 

    const [currentFocus, setCurrentFocus] = useState("");

    const { 
        status,
        increment_id, 
        store, 
        created_at, 
        status_title,
        license_plate,
        order_delivery_return_employees
    } = order_delivery_return;


    function onUpdateField(index, event) {
        if (currentFocus === "delivered") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrderDeliveryReturn = {...order_delivery_return};
            const currentItem = newOrderDeliveryReturn.order_delivery_return_items[index];
            currentItem[key] = value;
            dispatch(orderDeliveryReturnActions.onUpdateOrderDeliveryReturn(newOrderDeliveryReturn));
        }
    }

    function onSelectEmployee(options) {
        const newOrderDeliveryReturnEmployees = options.map((option, index) => ({
            full_name: `${option.first_name} ${option.last_name}`,
            job_position: option.job_position,
            increment_id: option.increment_id,
            employee_id: option._id,
            sort_order: index, // Assign sort_order here
        }));
        dispatch(orderDeliveryReturnActions.onUpdateOrderDeliveryReturnEmployee(newOrderDeliveryReturnEmployees));
    };

    function onDeleteEmployee(index) {
        const item = order_delivery_return_employees[index];
        if (item._id) {
			order_delivery_return_employees[index]._destroy = true
		} else {
			order_delivery_return_employees.splice(index, 1)
        }
        const newOrderDeliveryReturnEmployees = [...order_delivery_return_employees];
        newOrderDeliveryReturnEmployees.filter(el => {return !el._destroy}).map((item, index) => {
            return item.sort_order = index
        })
        dispatch(orderDeliveryReturnActions.onUpdateOrderDeliveryReturnEmployee(newOrderDeliveryReturnEmployees));
    }

    function onDeleteEmployees() {
        const newOrderDeliveryReturnEmployees = order_delivery_return_employees.map(item => {
            if (item._id) {
                item._destroy = true;
            }
            return item;
        });
    
        const filteredEmployees = newOrderDeliveryReturnEmployees.filter(item => {
            return item._id || item._destroy;
        });
    
        dispatch(orderDeliveryReturnActions.onUpdateOrderDeliveryReturnEmployee(filteredEmployees));
    }

    function onUpdatePlateField(event) {
        if (currentFocus === "plate") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrderDeliveryReturn = {...order_delivery_return};
            newOrderDeliveryReturn[key] = value;
            dispatch(orderDeliveryReturnActions.onUpdateOrderDeliveryReturn(newOrderDeliveryReturn));
        }
    }

    function onFocus(key) {
        setCurrentFocus(key)
    }

    function onBlur(key) {
        setCurrentFocus(null)
    }
    const employeeOptions = employees.map((option) => {
        const firstLetter = option.first_name[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
        };
    });

    const selectedEmployees = order_delivery_return_employees.map((employee) => ({
        first_name: employee.full_name.split(" ")[0],
        last_name: employee.full_name.split(" ")[1],
        increment_id: employee.increment_id,
        job_position: employee.job_position,
        _id: employee.employee_id,
    }));

    function shippers() {
        return (
            <Box>
                {order_delivery_return_employees.map((option, index) => (
                    <Grid container direction={"column"}>
                        <Grid item>
                            <Typography fontWeight={700} color="primary">
                                {option.job_position}
                            </Typography>
                            <Typography fontWeight={500}>
                                {option.full_name} - {option.increment_id}
                            </Typography>
                        </Grid>
                        {index < selectedEmployees.length - 1 &&  <Grid item>
                            <Divider sx={{marginTop: '10px', marginBottom: '10px'}}/>
                        </Grid>}
                    </Grid>
                ))}
            </Box>
        )
    }

    function Driver() {
        if (status === 'processing') {
            return (
                <Autocomplete
                    multiple
                    options={employeeOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => `${option.first_name}`}
                    blurOnSelect
                    filterSelectedOptions
                    value={selectedEmployees} // Bind the value from selected employees
                    onChange={(event, options) => onSelectEmployee(options)} // Handle option change
                    renderOption={(props, option) => (
                        <li {...props}>
                            <span style={{ fontWeight: 'bold', marginRight: 8 }}>{option.job_position}</span>
                            {`${option.first_name} ${option.last_name} - ${option.increment_id}`}
                        </li>
                    )}
                    isOptionEqualToValue={(option, value) => option._id === value._id} // Compare by employee _id
                    readOnly={selectedEmployees.length === 2} // Disable editing when 2 employees are selected
                    disableClearable
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                key={option._id}
                                label={`${option.first_name}`}
                                {...getTagProps({ index })}
                                deleteIcon={<CloseTwoTone/>}  
                                onDelete={onDeleteEmployee.bind(this, index)}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {selectedEmployees.length === 2 && (
                                            <IconButton
                                                onClick={() => {
                                                    onDeleteEmployees()
                                                }}
                                                edge="end"
                                                size="small"
                                            >
                                                <CloseTwoTone fontSize="small" />
                                            </IconButton>
                                        )}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            error={!!error("order_delivery_return_employees")}
                            helperText={error("order_delivery_return_employees")}
                            placeholder="Pilih Pengirim"
                            variant="outlined"
                        />
                    )}
                />
            )
        }
        return (
            <Typography sx={{ display: 'flex' }} lineHeight="normal" fontSize={16} fontWeight={700}>
                <LightTooltip placement="bottom-start" title={shippers()}>
                    {selectedEmployees.map((option, index) => (
                        <React.Fragment key={option._id}>
                            {option.first_name}
                            {index < selectedEmployees.length - 1 && " - "}
                        </React.Fragment>
                    ))}
                </LightTooltip>
            </Typography>
        )
    }

    function Plate() {
        if (status === 'processing') {
            return (
                <TextField
                    fullWidth
                    error={!!error('license_plate')}
                    helperText={error('license_plate')}
                    name="license_plate"
                    value={license_plate}
                    variant="outlined"
                    placeholder="Plat No."
                    onFocus={onFocus.bind(this, "plate")}
                    onBlur={onBlur.bind(this)}
                    onChange={onUpdatePlateField}
                /> 
            )
        }

        return (
            <Typography fontWeight={700} sx={{display: 'flex'}}>
                {license_plate}
            </Typography>
        )
    }

 
    return (
        <ContentPaper elevation={3}>
            <Box sx={{display: 'flex', padding: '35px', paddingTop: '25px', paddingBottom: '25px'}}>
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                    <PublishedWithChangesTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status_title}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography fontWeight={700} color="primary">
                    {increment_id}
                </Typography>
                <Slash>
                    /
                </Slash>
                <Typography sx={{display: 'flex'}}>
                    <AccessTimeTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {moment(created_at).format('LL')}, {moment(created_at).format('LT')} WIB
                </Typography>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Box sx={{padding: '35px'}}>
                <Grid container spacing={3}>
                <Grid item xs={status === 'completed' ? 3 : 4} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Pengemudi
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {Driver()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={status === 'completed' ? 3 : 2} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Plat No.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {Plate()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Cabang
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} sx={{display: 'flex'}}>
                                {store}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction="column">
                        <Grid item>
                            <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                Aktivitas
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h6" color="primary" fontSize={14} style={{textTransform: 'capitalize', cursor: 'pointer'}}>
                                Lihat Aktivitas
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    marginBottom: 25
}));


const Slash = styled(Typography)(({theme}) => ({
    margin: '0 6px',
    color: 'var(--N75, #E5E7E9)'
}));


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} sx={{marginLeft: '5px'}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
    //   width: 325,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    },
  }));